<template>
  <div>
    <v-btn @click="createPDF">
      Download PDF
    </v-btn>
  </div>
</template>

<script>
import { mapGetters } from 'vuex';
import _ from 'lodash';
import jsPDF from 'jspdf';
import 'jspdf-autotable';
import UnitConverter from '@/plugins/unitconverter';
import '@/mixins/ArialUnicodeMS_Bold-normal.js'; //ArialUnicodeMS_Bold used name for font at creation process by fontconverter
import '@/mixins/ArialUnicodeMS-normal.js'; //ArialUnicodeMS used name for font at creation process by fontconverter

import * as Svg from 'save-svg-as-png';
export default {
  name: 'ResultReportPDF',

  components: {},
  props: {
    project: null,
    mixtures: { type: Array, required: true },
    combustionChambers: { type: Array, required: true },
  },
  data() {
    return {
      settingsPDF: {
        font: 'ArialUnicodeMS',
        fontSize: {
          title: 20,
          subtitle: 16,
          subsubtitle: 12,
          text: 9,
        },
        numOfMixturesPerTable: 5,
        imgHeight: 300,
      },
      inletMixtures: null,
      outletMixtures: null,
      rowHeadersMixtures: [
        {
          text: 'Flow Rate',
          varName: 'massFlow',
          type: UnitConverter.types.FLOWRATE,
          fromUnit: 'kg/s',
          toUnit: 'kg/h',
          precision: 2,
          units: ['kg/h', 'Nm³/h'],
          precisions: [2, 2],
        },
        {
          text: 'Density',
          varName: 'rho',
          type: UnitConverter.types.DENSITY,
          fromUnit: 'kg/Nm³',
          toUnit: 'kg/Nm³',
          precision: 3,
          units: ['kg/Nm³', 'kg/m³'],
          precisions: [3, 3],
        },
        {
          text: 'Temperature',
          varName: 'T',
          type: UnitConverter.types.TEMPERATURE,
          fromUnit: 'K',
          toUnit: '°C',
          precision: 2,
          units: ['K', '°C', '°F'],
          precisions: [2, 2, 2],
        },
        // {
        //   text: 'Pressure',
        //   varName: 'p',
        //   type: UnitConverter.types.PRESSURE,
        //   fromUnit: 'Pa',
        //   toUnit: 'bar',
        //   precision: 2,
        //   units: ['Pa', 'kPa', 'MPa', 'bar', 'mbar', 'atm'],
        //   precisions: [0, 1, 3, 3, 1, 3],
        // },
        {
          text: 'LHV',
          varName: 'LHVNist',
          type: UnitConverter.types.ENTHALPY,
          fromUnit: 'J/kg',
          toUnit: 'kJ/kg',
          precision: 0,
          units: [
            'kJ/kg',
            'MJ/kg',
            'kWh/kg',
            'kcal/kg',
            'kJ/Nm³',
            'MJ/Nm³',
            'kWh/Nm³',
            'kcal/Nm³',
          ],
          precisions: [0, 3, 2, 0, 0, 3, 3, 0],
        },
      ],
      rowHeadersCombustionChambers: [
        {
          text: 'Length',
          varName: 'length',
          type: UnitConverter.types.LENGTH,
          fromUnit: 'm',
          toUnit: 'm',
          precision: 2,
          units: ['m', 'cm', 'mm'],
          precisions: [2, 1, 0],
        },
        {
          text: 'Diameter',
          varName: 'diameter',
          type: UnitConverter.types.LENGTH,
          fromUnit: 'm',
          toUnit: 'mm',
          precision: 0,
          units: ['m', 'cm', 'mm'],
          precisions: [2, 1, 0],
        },
        {
          text: 'Height',
          varName: 'height',
          type: UnitConverter.types.LENGTH,
          fromUnit: 'm',
          toUnit: 'mm',
          precision: 0,
          units: ['m', 'cm', 'mm'],
          precisions: [2, 1, 0],
        },
        {
          text: 'Width',
          varName: 'width',
          type: UnitConverter.types.LENGTH,
          fromUnit: 'm',
          toUnit: 'mm',
          precision: 0,
          units: ['m', 'cm', 'mm'],
          precisions: [2, 1, 0],
        },
        {
          text: 'Residence Time',
          varName: 'residenceTime',
          type: UnitConverter.types.TIME,
          fromUnit: 's',
          toUnit: 's',
          precision: 2,
          units: ['s'],
          precisions: [2],
        },
        {
          text: 'Effective Heat Flow',
          varName: 'effectiveHeatFlow',
          type: UnitConverter.types.POWER,
          fromUnit: 'W',
          toUnit: 'kW',
          precision: 1,
          units: ['kW', 'MW'],
          precisions: [1, 3],
        },
        {
          text: 'Heat Loss',
          varName: 'heatLoss',
          type: UnitConverter.types.POWER,
          fromUnit: 'W',
          toUnit: 'kW',
          precision: 1,
          units: ['kW', 'MW'],
          precisions: [1, 3],
        },
        {
          text: 'Relative Heat Loss',
          varName: 'relativeHeatLoss',
          type: UnitConverter.types.UNITLESS,
          fromUnit: '',
          toUnit: '%',
          precision: 2,
          units: ['%'],
          precisions: [2],
        },
        {
          text: 'O2 Demand',
          varName: 'o2Demand',
          type: UnitConverter.types.O2DEMAND,
          fromUnit: 'kg_{O_{2}}/kg_{fuel}',
          toUnit: 'kg_{O_{2}}/kg_{fuel}',
          precision: 2,
          units: [
            'kg_{O_{2}}/kg_{fuel}',
            'kg_{O_{2}}/Nm³_{fuel}',
            'Nm³_{O_{2}}/kg_{fuel}',
            'Nm³_{O_{2}}/Nm³_{fuel}',
          ],
          precisions: [2, 2, 2, 2],
        },
        {
          text: 'Air Demand',
          varName: 'airDemand',
          type: UnitConverter.types.O2DEMAND,
          fromUnit: 'kg_{air}/kg_{fuel}',
          toUnit: 'kg_{air}/kg_{fuel}',
          precision: 2,
          units: [
            'kg_{air}/kg_{fuel}',
            'kg_{air}/Nm³_{fuel}',
            'Nm³_{air}/kg_{fuel}',
            'Nm³_{air}/Nm³_{fuel}',
          ],
          precisions: [2, 2, 2, 2],
        },
      ],
      compositionHeader: {
        name: 'Composition',
        varName: '',
        toUnit: '%mass',
        precision: 2,
        units: ['%mass', '%vol', '%mol'],
        precisions: [2, 2, 2],
      },
      compositionUnits: Array(this.mixtures.length).fill('%mass'),
      compositionEncoder: {
        '%mass': 'massFraction',
        '%vol': 'volumeFraction',
        '%mol': 'moleFraction',
      },
    };
  },
  methods: {
    createPDF() {
      this.$store.dispatch('misc/setLoading', true);
      try {
        this.pdf = new jsPDF({ compress: true });
        this.pdf.setFont('ArialUnicodeMS', 'normal');
        this.pdf.setFontSize(this.settingsPDF.fontSize.text);
        this.pdf.setTextColor(
          this.hexToRgb(this.themes.enrag.secondary).r,
          this.hexToRgb(this.themes.enrag.secondary).g,
          this.hexToRgb(this.themes.enrag.secondary).b,
        );
        this.createTitle();
        this.createPlantDiagram()
          .then(() => {
            this.createGeneral();
            this.pdf.addPage();
            this.createMixtures();
            this.pdf.addPage();
            this.createCombustionChambers();
            this.addFooter();
            //this.addHeader();
            var filename =
              this.$store.getters['projects/currentProjectFileName'] +
              '-Report.pdf';
            this.pdf.save(filename);
          })
          .finally(() => {
            this.$store.dispatch('misc/setLoading', false);
          });
      } catch {
        this.$store.dispatch('misc/setSnack', {
          color: 'error',
          text: 'Error while creating PDF Report',
        });
        this.$store.dispatch('misc/setLoading', false);
      }
    },
    createTitle() {
      this.pdf.autoTable({
        tableLineColor: [
          this.hexToRgb(this.themes.enrag.success).r,
          this.hexToRgb(this.themes.enrag.success).g,
          this.hexToRgb(this.themes.enrag.success).b,
        ],
        tableLineWidth: 0.75,
        headStyles: {
          font: 'ArialUnicodeMS_Bold',
          fontStyle: 'bold',
          fontSize: this.settingsPDF.fontSize.title,
          fillColor: [
            this.hexToRgb(this.themes.enrag.success).r,
            this.hexToRgb(this.themes.enrag.success).g,
            this.hexToRgb(this.themes.enrag.success).b,
          ],
        },
        head: [{ 0: 'Report' }],
      });
    },
    createPlantDiagram() {
      return new Promise((resolve, reject) => {
        Svg.svgAsPngUri(document.getElementById('svg-diagram')).then(
          uri => {
            var pageWidth = this.pdf.internal.pageSize.getWidth();
            var imgSize = {
              width: this.project.diagram.width,
              height: this.project.diagram.height,
            };
            var scale = 1;
            var imgWidth;
            do {
              imgWidth = imgSize.width * scale;
              this.imgHeight = imgSize.height * scale;
              scale = scale - 0.01;
            } while (pageWidth - 15 * 2 < imgWidth);
            this.pdf.addImage(
              uri,
              'PNG',
              15,
              15 * 2 + 10,
              imgWidth,
              this.imgHeight,
              'alias',
              'FAST',
            ); //imageData, format, x, y, width, height, alias, compression, rotation
            resolve();
          },
        );
      });
    },
    createGeneral() {
      this.pdf.autoTable({
        startY: this.imgHeight + 15 * 2,
        styles: {
          font: 'ArialUnicodeMS',
          fontSize: this.settingsPDF.fontSize.text,
        },
        tableLineColor: [
          this.hexToRgb(this.themes.enrag.background).r,
          this.hexToRgb(this.themes.enrag.background).g,
          this.hexToRgb(this.themes.enrag.background).b,
        ],
        tableLineWidth: 0.75,
        headStyles: {
          font: 'ArialUnicodeMS_Bold',
          fontStyle: 'bold',
          fontSize: this.settingsPDF.fontSize.subtitle,
          fillColor: [
            this.hexToRgb(this.themes.enrag.titlebar).r,
            this.hexToRgb(this.themes.enrag.titlebar).g,
            this.hexToRgb(this.themes.enrag.titlebar).b,
          ],
        },
        columnStyles: {
          0: {
            font: 'ArialUnicodeMS_Bold',
            fontStyle: 'bold',
          },
          2: {
            font: 'ArialUnicodeMS_Bold',
            fontStyle: 'bold',
          },
        },
        head: [{ 0: 'Project Definition', 1: '', 2: '', 3: '' }],
        body: [
          {
            0: 'Number',
            1: this.project.info.number,
            2: 'Name',
            3: this.project.info.name,
          },
          {
            0: 'Manager',
            1: this.project.info.manager,
            2: 'Creator',
            3: this.project.info.creator,
          },
          {
            0: 'Design Date',
            1: this.project.info.designDate,
            2: 'Revision Number',
            3: this.project.info.revision,
          },
          {
            0: '',
            1: '',
            2: 'Revision Date',
            3: this.project.info.revisionDate,
          },
        ],
      });
    },
    createMixtures() {
      this.createMixturesSubTitle();

      var len = this.mixtures.length;
      var m = 1;
      var startIndex = 0;
      var endIndex = 0;

      do {
        endIndex = m * this.settingsPDF.numOfMixturesPerTable;
        if (endIndex > len) endIndex = len;
        this.createMixturesBody(this.mixtures, startIndex, endIndex);
        startIndex =
          startIndex + this.settingsPDF.numOfMixturesPerTable;
        m++;
        if (startIndex < len) this.pdf.addPage();
      } while (startIndex < len);
    },
    createMixturesSubTitle() {
      //#region Headline
      this.pdf.autoTable({
        tableLineColor: [
          this.hexToRgb(this.themes.enrag.background).r,
          this.hexToRgb(this.themes.enrag.background).g,
          this.hexToRgb(this.themes.enrag.background).b,
        ],
        tableLineWidth: 0.75,
        headStyles: {
          font: 'ArialUnicodeMS_Bold',
          fontStyle: 'bold',
          fontSize: this.settingsPDF.fontSize.subtitle,
          fillColor: [
            this.hexToRgb(this.themes.enrag.titlebar).r,
            this.hexToRgb(this.themes.enrag.titlebar).g,
            this.hexToRgb(this.themes.enrag.titlebar).b,
          ],
        },
        head: [{ 0: 'Mixtures' }],
      });
      //#endregion Headline
    },
    createMixturesBody(mixtures, startIndex, endIndex, type = 0) {
      var i;
      //#region Body
      var body = [];
      //#region Rows
      body.push([]); //additional row for rowHeadersMixtures
      this.rowHeadersMixtures.forEach((header, index) => {
        body.push([]);
      });
      body.push([]);
      body.push([]); //additional row for uniqueSpecies
      if (type == 0) {
        this.uniqueSpecies.forEach((uniqueSpecies, index) => {
          body.push([]);
        });
      } else if (type == 1) {
        this.uniqueSpeciesInlet.forEach((uniqueSpecies, index) => {
          body.push([]);
        });
      } else if (type == 2) {
        this.uniqueSpeciesOutlet.forEach((uniqueSpecies, index) => {
          body.push([]);
        });
      }

      //#endregion Rows

      //#region Cols

      body[0].push(''); //empty col
      body[0].push('Unit');

      for (i = startIndex; i < endIndex; i++) {
        body[0].push(mixtures[i].streamId + ' | ' + mixtures[i].name);
      }
      var row = 1;
      this.rowHeadersMixtures.forEach((header, indexHeader) => {
        body[row].push(header.text);
        body[row].push(header.toUnit);
        //mixtures.forEach((mix, index) => {
        //  body[row].push(this.convertUnitMixtures(mix, header));
        //});
        for (i = startIndex; i < endIndex; i++) {
          body[row].push(
            this.convertUnitMixtures(mixtures[i], header),
          );
        }
        row++;
      });
      row++;
      body[row].push('Composition'); //
      body[row].push('Phase');
      for (i = startIndex; i < endIndex; i++) {
        body[row].push(this.compositionHeader.toUnit);
      }
      var rowStyle = row;
      row++;

      if (type == 0) {
        this.uniqueSpecies.forEach(species => {
          body[row].push(this.stringFormula(species.formula));
          body[row].push(species.phase);
          for (i = startIndex; i < endIndex; i++) {
            body[row].push(
              this.getFraction(
                mixtures[i],
                species,
                this.compositionHeader.toUnit,
                this.compositionHeader.precision,
              ),
            );
          }
          row++;
        });
      } else if (type == 1) {
        this.uniqueSpeciesInlet.forEach(species => {
          body[row].push(this.stringFormula(species.formula));
          body[row].push(species.phase);
          //mixtures.forEach((mix, index) => {
          //  body[row].push(this.getFraction(mix, species, this.compositionHeader.toUnit, this.compositionHeader.precision));
          //});
          for (i = startIndex; i < endIndex; i++) {
            body[row].push(
              this.getFraction(
                mixtures[i],
                species,
                this.compositionHeader.toUnit,
                this.compositionHeader.precision,
              ),
            );
          }
          row++;
        });
      } else if (type == 2) {
        this.uniqueSpeciesOutlet.forEach(species => {
          body[row].push(this.stringFormula(species.formula));
          body[row].push(species.phase);
          //mixtures.forEach((mix, index) => {
          //  body[row].push(this.getFraction(mix, species, this.compositionHeader.toUnit, this.compositionHeader.precision));
          //});
          for (i = startIndex; i < endIndex; i++) {
            body[row].push(
              this.getFraction(
                mixtures[i],
                species,
                this.compositionHeader.toUnit,
                this.compositionHeader.precision,
              ),
            );
          }
          row++;
        });
      }

      //#endregion Cols

      //#endregion Body
      //console.log("body");
      //console.log(body);
      this.pdf.autoTable({
        styles: {
          font: 'ArialUnicodeMS',
          fontSize: this.settingsPDF.fontSize.text,
          halign: 'center',
          valign: 'middle',
        },
        didParseCell({ doc, cell, row, column }) {
          if (cell === undefined) {
            return;
          }

          if (row.index === rowStyle) {
            cell.styles.font = 'ArialUnicodeMS_Bold';
            cell.styles.fontStyle = 'bold';
            cell.styles.halign = 'center';
            cell.styles.valign = 'middle';
            cell.styles.fontSize = cell.styles.fontSize * 1.25;
          }
          const hasCustomWidth =
            typeof cell.styles.cellWidth === 'number';

          if (
            hasCustomWidth ||
            cell.raw == null ||
            cell.colSpan > 1
          ) {
            return;
          }

          let text;

          if (cell.raw instanceof Node) {
            text = cell.raw.innerText;
          } else {
            if (typeof cell.raw == 'object') {
              // not implemented yet
              // when a cell contains other cells (colSpan)
              return;
            } else {
              text = '' + cell.raw;
            }
          }

          // split cell string by spaces
          const words = text.split(/\s+/);

          // calculate longest word width
          const maxWordUnitWidth = words
            .map(
              s => Math.floor(doc.getStringUnitWidth(s) * 100) / 100,
            )
            .reduce((a, b) => Math.max(a, b), 0);
          const maxWordWidth =
            maxWordUnitWidth *
            (cell.styles.fontSize / doc.internal.scaleFactor);

          const minWidth = cell.padding('horizontal') + maxWordWidth;

          // update minWidth for cell & column

          if (minWidth > cell.minWidth) {
            cell.minWidth = minWidth;
          }

          if (cell.minWidth > cell.wrappedWidth) {
            cell.wrappedWidth = cell.minWidth;
          }

          if (cell.minWidth > column.minWidth) {
            column.minWidth = cell.minWidth;
          }

          if (column.minWidth > column.wrappedWidth) {
            column.wrappedWidth = column.minWidth;
          }
        },
        body: body,
      });
    },
    createCombustionChambers() {
      this.createCombustionChambersSubTitle();
      this.createCombustionChambersHead();
      this.createCombustionChambersBody();
    },
    createCombustionChambersSubTitle() {
      //#region Headline
      this.pdf.autoTable({
        tableLineColor: [
          this.hexToRgb(this.themes.enrag.background).r,
          this.hexToRgb(this.themes.enrag.background).g,
          this.hexToRgb(this.themes.enrag.background).b,
        ],
        tableLineWidth: 0.75,
        headStyles: {
          font: 'ArialUnicodeMS_Bold',
          fontStyle: 'bold',
          fontSize: this.settingsPDF.fontSize.subtitle,
          fillColor: [
            this.hexToRgb(this.themes.enrag.titlebar).r,
            this.hexToRgb(this.themes.enrag.titlebar).g,
            this.hexToRgb(this.themes.enrag.titlebar).b,
          ],
        },
        head: [{ 0: 'CombustionChambers' }],
      });
      //#endregion Headline
    },
    createCombustionChambersHead() {
      //#region Body
      var body = [];
      //#region Rows
      body.push([]); //additional row for rowHeadersCombustionChambers

      this.rowHeadersCombustionChambers.forEach((chamber, index) => {
        body.push([]);
      });
      //#endregion Rows

      //#region Cols
      body[0].push(''); //empty col
      body[0].push('Unit');
      this.combustionChambers.forEach((chamber, index) => {
        body[0].push(chamber.name);
      });
      var row = 1;
      this.rowHeadersCombustionChambers.forEach(
        (header, indexHeader) => {
          body[row].push(header.text);
          body[row].push(this.stringUnit(header.toUnit));
          this.combustionChambers.forEach((chamber, index) => {
            body[row].push(this.convertUnitChamber(chamber, header));
          });
          row++;
        },
      );
      //#endregion Cols
      //#endregion Body
      this.pdf.autoTable({
        styles: {
          font: 'ArialUnicodeMS',
          fontSize: this.settingsPDF.fontSize.text,
          halign: 'center',
          valign: 'middle',
        },
        body: body,
      });
    },
    createCombustionChambersBody() {
      var len = this.mixtures.length;
      var m = 1;
      var startIndex = 0;
      var endIndex = 0;
      this.combustionChambers.forEach((chamber, index) => {
        //#region subsubtitle
        this.pdf.autoTable({
          tableLineColor: [
            this.hexToRgb(this.themes.enrag.background).r,
            this.hexToRgb(this.themes.enrag.background).g,
            this.hexToRgb(this.themes.enrag.background).b,
          ],
          tableLineWidth: 0.75,
          headStyles: {
            font: 'ArialUnicodeMS_Bold',
            fontStyle: 'bold',
            fontSize: this.settingsPDF.fontSize.subsubtitle,
            fillColor: [
              this.hexToRgb(this.themes.enrag.secondary).r,
              this.hexToRgb(this.themes.enrag.secondary).g,
              this.hexToRgb(this.themes.enrag.secondary).b,
            ],
          },
          head: [{ 0: chamber.name }],
        });
        //#endregion subsubtitle

        //#region inlet Mixtures
        //#region subsubsubtitle 'Inlet'
        this.pdf.autoTable({
          tableLineColor: [
            this.hexToRgb(this.themes.enrag.background).r,
            this.hexToRgb(this.themes.enrag.background).g,
            this.hexToRgb(this.themes.enrag.background).b,
          ],
          tableLineWidth: 0.75,
          headStyles: {
            font: 'ArialUnicodeMS_Bold',
            fontStyle: 'bold',
            fontSize: this.settingsPDF.fontSize.text,
            fillColor: [
              this.hexToRgb(this.themes.enrag.success).r,
              this.hexToRgb(this.themes.enrag.success).g,
              this.hexToRgb(this.themes.enrag.success).b,
            ],
          },
          head: [{ 0: 'Inlet' }],
        });
        //#endregion subsubsubtitle
        //#region inletMixtures Body
        this.inletMixtures = chamber.inletMixtures;
        len = chamber.inletMixtures.length;
        m = 1;
        startIndex = 0;
        endIndex = 0;
        do {
          endIndex = m * this.settingsPDF.numOfMixturesPerTable;
          if (endIndex > len) endIndex = len;
          this.createMixturesBody(
            chamber.inletMixtures,
            startIndex,
            endIndex,
            1,
          );
          startIndex =
            startIndex + this.settingsPDF.numOfMixturesPerTable;
          m++;
          if (startIndex < len) this.pdf.addPage();
        } while (startIndex < len);
        //#endregion
        //#endregion

        //#region outlet Mixtures
        //#region subsubsubtitle 'Outlet'
        this.pdf.autoTable({
          tableLineColor: [
            this.hexToRgb(this.themes.enrag.background).r,
            this.hexToRgb(this.themes.enrag.background).g,
            this.hexToRgb(this.themes.enrag.background).b,
          ],
          tableLineWidth: 0.75,
          headStyles: {
            font: 'ArialUnicodeMS_Bold',
            fontStyle: 'bold',
            fontSize: this.settingsPDF.fontSize.text,
            fillColor: [
              this.hexToRgb(this.themes.enrag.success).r,
              this.hexToRgb(this.themes.enrag.success).g,
              this.hexToRgb(this.themes.enrag.success).b,
            ],
          },
          head: [{ 0: 'Outlet' }],
        });
        //#endregion
        //#region outletMixtures Body
        this.outletMixtures = chamber.outletMixtures;
        len = chamber.outletMixtures.length;
        m = 1;
        startIndex = 0;
        endIndex = 0;

        do {
          endIndex = m * this.settingsPDF.numOfMixturesPerTable;
          if (endIndex > len) endIndex = len;
          this.createMixturesBody(
            chamber.outletMixtures,
            startIndex,
            endIndex,
            2,
          );
          startIndex =
            startIndex + this.settingsPDF.numOfMixturesPerTable;
          m++;
          if (startIndex < len) this.pdf.addPage();
        } while (startIndex < len);
        //#endregion
        //#endregion
        if (index != this.combustionChambers.length - 1)
          this.pdf.addPage();
      });
    },
    addFooter() {
      const pageCount = this.pdf.internal.getNumberOfPages();
      for (var i = 1; i <= pageCount; i++) {
        this.pdf.setPage(i);
        this.pdf.text(
          String(i) + '/' + String(pageCount),
          this.pdf.internal.pageSize.width / 2,
          this.pdf.internal.pageSize.height -
            this.settingsPDF.fontSize.text,
          {
            align: 'center',
          },
        );
      }
    },
    addHeader() {
      //console.log();
    },
    hexToRgb(hex) {
      var result = /^#?([a-f\d]{2})([a-f\d]{2})([a-f\d]{2})$/i.exec(
        hex,
      );
      return result
        ? {
            r: parseInt(result[1], 16),
            g: parseInt(result[2], 16),
            b: parseInt(result[3], 16),
          }
        : null;
    },
    convertUnitMixtures(mix, header) {
      return UnitConverter.convert(
        mix[header.varName],
        header.type,
        header.fromUnit,
        header.toUnit,
        header.precision,
        mix.rhoN,
      );
    },
    getFraction(mix, species, unit, precision) {
      var placeholder = '-';
      var index = mix.species.findIndex(
        s => s.publicId === species.publicId,
      );
      if (index === -1) {
        return placeholder;
      } else {
        var value = _.round(
          mix.species[index][this.compositionEncoder[unit]] * 100,
          precision,
        );
        return value === 0 ? placeholder : value;
      }
    },
    stringUnit(unit) {
      unit = unit.replace(/({)(\d+)(})/g, '$2');
      unit = unit.replace(/_/g, '');
      unit = unit.replace(/{/g, '(');
      unit = unit.replace(/}/g, ')');

      var string = unit;
      var subscriptNumbers = string.match(/\d+/g);
      var subscriptNumber;
      if (subscriptNumbers != null) {
        for (var i = 0; i < subscriptNumbers.length; i++) {
          subscriptNumber = subscriptNumbers[i].split('');
          if (subscriptNumber != null) {
            for (var j = 0; j < subscriptNumber.length; j++) {
              switch (subscriptNumber[j]) {
                case '0':
                  string = string.replace(
                    subscriptNumber[j],
                    '\u2080',
                  );
                  break;
                case '1':
                  string = string.replace(
                    subscriptNumber[j],
                    '\u2081',
                  );
                  break;
                case '2':
                  string = string.replace(
                    subscriptNumber[j],
                    '\u2082',
                  );
                  break;
                case '3':
                  string = string.replace(
                    subscriptNumber[j],
                    '\u2083',
                  );
                  break;
                case '4':
                  string = string.replace(
                    subscriptNumber[j],
                    '\u2084',
                  );
                  break;
                case '5':
                  string = string.replace(
                    subscriptNumber[j],
                    '\u2085',
                  );
                  break;
                case '6':
                  string = string.replace(
                    subscriptNumber[j],
                    '\u2086',
                  );
                  break;
                case '7':
                  string = string.replace(
                    subscriptNumber[j],
                    '\u2087',
                  );
                  break;
                case '8':
                  string = string.replace(
                    subscriptNumber[j],
                    '\u2088',
                  );
                  break;
                case '9':
                  string = string.replace(
                    subscriptNumber[j],
                    '\u2089',
                  );
                  break;
                default:
                  break;
              }
            }
          }
        }
      }
      return string;
    },
    convertUnitChamber(chamber, header) {
      var value = Object.keys(this).includes(header.varName)
        ? this[header.varName](chamber)
        : chamber[header.varName];
      if (
        ['diameter', 'height', 'width'].indexOf(header.varName) > -1
      ) {
        if (
          (chamber.shape == 'cylindrical' &&
            header.varName != 'diameter') ||
          (chamber.shape == 'rectangular' &&
            header.varName == 'diameter')
        ) {
          return '-';
        }
      } else
        var rhoN1 =
          header.varName === 'o2Demand'
            ? 1.428
            : this.$store.getters['mixtures/ambient'].wet.rhoN;
      var rhoN2 = this.rhoNFuels(chamber);
      return UnitConverter.convert(
        value,
        header.type,
        header.fromUnit,
        header.toUnit,
        header.precision,
        rhoN1,
        rhoN2,
      );
    },
    relativeHeatLoss(chamber) {
      return (
        chamber.heatLoss /
        (chamber.effectiveHeatFlow + chamber.heatLoss)
      );
    },
    fuelMassFlow(chamber) {
      return chamber.inletMixtures.reduce((pv, cv) => {
        var value = cv.streamType === 'Fuel' ? cv.massFlow : 0;
        return pv + value;
      }, 0);
    },
    o2Demand(chamber) {
      var fuelMassFlow = this.fuelMassFlow(chamber);
      var o2Demand = chamber.inletMixtures.reduce((pv, cv) => {
        var value =
          cv.streamType === 'Fuel'
            ? (cv.O2Demand * cv.massFlow) / fuelMassFlow
            : 0;
        return pv + value;
      }, 0);
      return o2Demand;
    },
    airDemand(chamber) {
      var o2Demand = this.o2Demand(chamber);
      var wetAmbient = this.$store.getters['mixtures/ambient'].wet;
      var index = wetAmbient.species.findIndex(
        s => s.formula === 'O2',
      );
      var fractionO2 = 0.21;
      if (index !== -1) {
        fractionO2 = wetAmbient.species[index].massFraction;
      }
      return o2Demand / fractionO2;
    },
    unitTemplate(unit) {
      // very simple replacement mechanism
      unit = unit.replace(/_{/g, '<sub>');
      unit = unit.replace(/}/g, '</sub>');
      var unitTemplate = `<span>${unit}</span>`;
      return unitTemplate;
    },
    rhoNFuels(chamber) {
      var fuelMassFlow = this.fuelMassFlow(chamber);
      var rhoNFuels = chamber.inletMixtures.reduce((pv, cv) => {
        var value =
          cv.streamType === 'Fuel'
            ? (cv.rhoN * cv.massFlow) / fuelMassFlow
            : 0;
        return pv + value;
      }, 0);
      return rhoNFuels;
    },
  },
  computed: {
    ...mapGetters('materials', ['stringFormula']),
    ...mapGetters('misc', ['themes']),
    uniqueSpecies() {
      var uniqueSpecies = [];
      this.mixtures.forEach(mix => {
        mix.species.forEach(species => {
          if (
            uniqueSpecies.filter(s => s.publicId === species.publicId)
              .length === 0
          ) {
            uniqueSpecies.push(species);
          }
        });
      });
      var uniqueSpeciesAlphabeticOrder = uniqueSpecies.sort((a, b) =>
        a.formula > b.formula ? 1 : b.formula > a.formula ? -1 : 0,
      );
      return uniqueSpeciesAlphabeticOrder;
    },
    uniqueSpeciesInlet() {
      var uniqueSpecies = [];
      this.inletMixtures.forEach(mix => {
        mix.species.forEach(species => {
          if (
            uniqueSpecies.filter(s => s.publicId === species.publicId)
              .length === 0
          ) {
            uniqueSpecies.push(species);
          }
        });
      });
      var uniqueSpeciesAlphabeticOrder = uniqueSpecies.sort((a, b) =>
        a.formula > b.formula ? 1 : b.formula > a.formula ? -1 : 0,
      );
      return uniqueSpeciesAlphabeticOrder;
    },
    uniqueSpeciesOutlet() {
      var uniqueSpecies = [];
      this.outletMixtures.forEach(mix => {
        mix.species.forEach(species => {
          if (
            uniqueSpecies.filter(s => s.publicId === species.publicId)
              .length === 0
          ) {
            uniqueSpecies.push(species);
          }
        });
      });
      var uniqueSpeciesAlphabeticOrder = uniqueSpecies.sort((a, b) =>
        a.formula > b.formula ? 1 : b.formula > a.formula ? -1 : 0,
      );
      return uniqueSpeciesAlphabeticOrder;
    },
  },
  mounted() {
    //console.log(this.themes.enrag);
  },
};
</script>

<style scoped>
/* table th + th {
    border-left: 1px solid #723434;
  }
  table td + td {
    border-left: 1px solid #7a2b2b;
  } */
</style>
