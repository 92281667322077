<template>
  <div>
    <v-btn v-if="!editable" @click="editable = true">Edit</v-btn>
    <span v-else>
      <v-btn @click="endEdit">End Edit</v-btn>
      <!--auto format diagram-->
      <v-btn @click="autoFormat">Auto Format</v-btn>
    </span>
    <!-- Import-->
    <!--<v-dialog v-model="dialogImport">
      <template v-slot:activator="{ on, attrs }">
        <v-btn v-bind="attrs" v-on="on">
          Import
        </v-btn>
      </template>
      <v-card>
        <v-col cols="12" sm="6">
          <v-file-input v-model="chosenFile" label="File input"></v-file-input>
        </v-col>
        <v-card-actions>
          <v-btn color="blue darken-1" text @click="dialogImport = false">Close</v-btn>
          <v-btn color="blue darken-1" text @click="jsonImport">Import</v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>-->
    <!--Export-->
    <!--<v-dialog v-model="dialogExport">
      <template v-slot:activator="{ on, attrs }">
        <v-btn v-bind="attrs"
               v-on="on">
          Export
        </v-btn>
      </template>
      <v-card>
        <v-col cols="12" sm="6">
          <v-text-field v-model="dialogFileName" label="File Name"></v-text-field>
        </v-col>
        <v-card-actions>
          <v-btn color="blue darken-1" text @click="dialogExport = false">Close</v-btn>
          <v-btn color="blue darken-1" text @click="jsonExport">Export</v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>-->
    <!--<h1>{{diagramData.measuringUnit}}</h1>-->
    <!--download diagram-->
    <v-dialog v-model="dialogDownload">
      <template v-slot:activator="{ on, attrs }">
        <v-btn v-bind="attrs" v-on="on">
          Download
        </v-btn>
      </template>
      <v-card>
        <v-card-title>Select Data Format</v-card-title>
        <v-divider></v-divider>
        <v-card-text>
          <v-radio-group v-model="dialogDataFormat" column>
            <v-radio label="svg" value="svg"></v-radio>
            <v-radio label="png" value="png"></v-radio>
          </v-radio-group>
        </v-card-text>
        <v-divider></v-divider>
        <v-col cols="12" sm="6">
          <v-text-field
            v-model="dialogFileName"
            label="File Name"
          ></v-text-field>
        </v-col>
        <v-card-actions>
          <v-btn
            color="blue darken-1"
            text
            @click="dialogDownload = false"
            >Close</v-btn
          >
          <v-btn color="blue darken-1" text @click="downloadDiagram"
            >Download</v-btn
          >
        </v-card-actions>
      </v-card>
    </v-dialog>
    <input type="checkbox" id="checkbox" v-model="settings.isFluid" />
    <label for="checkbox"> zoom out</label>

    <v-slider
      :label="round(settings.scale * 100, 0) + '%'"
      v-model="settings.scale"
      step="0.01"
      max="1.0"
      min="0.1"
    >
    </v-slider>

    <SvgPlant
      id="svg-diagram-show-area"
      :width="diagramData.width || 2000"
      :height="diagramData.height || 1000"
      :fluid="settings.isFluid"
      :scale="settings.scale"
      :measuringUnit="diagramData.measuringUnit"
      :background="diagramData.background"
      :nodes="diagramData.nodes"
      :links="diagramData.links"
      :showGrid="settings.showGrid"
      :editable="editable"
      :labels="{
        edit: 'Edit',
        remove: 'Remove',
        link: 'New Link',
        select: 'Select',
        cancel: 'Cancel',
        copy: 'Copy',
        change: 'Flip',
      }"
      @editNode="onEditNode"
      @editLink="onEditLink"
      @nodeChanged="nodeChanged"
      @linkChanged="linkChanged"
    >
    </SvgPlant>
  </div>
</template>
<script>
import Snap from 'snapsvg-cjs';
import _ from 'lodash';
import SvgPlant from '@/components/svg/SvgPlant';
import { v4 as uuidv4 } from 'uuid';
import * as Svg from 'save-svg-as-png';
import { diagramMixin } from '@/mixins/diagramMixin';

export default {
  mixins: [diagramMixin],
  components: {
    SvgPlant,
  },
  props: {
    projectDiagram: null,
    combustionChambers: null,
  },
  watch: {
    combustionChambers() {
      //console.log("combustionChambers changes");
      this.inlet = [];
      this.diagramData.nodes = [];
      this.diagramData.links = [];
      this.init();
      this.initDiagramData();
      this.updateProjectDiagram();
    },
    projectDiagram(newValue) {
      if (newValue === null) {
        this.init();
        this.initDiagramData();
        this.updateProjectDiagram();
      }
    },
  },
  methods: {
    autoFormat() {
      this.inlet = [];
      this.diagramData.nodes = [];
      this.diagramData.links = [];
      this.init();
      this.initDiagramData();
      //this.updateProjectDiagram();
    },
    endEdit() {
      this.editable = false;
      this.updateProjectDiagram();
    },
    updateProjectDiagram() {
      this.$emit('updateProjectDiagram', this.diagramData);
    },
    calcMaxTextLength() {
      var maxTextLength = 0;
      var i;
      var j;
      var len = 0;
      for (i = 0; i < this.combustionChambers.length; i++) {
        for (
          j = 0;
          j < this.combustionChambers[i].inletMixtures.length;
          j++
        ) {
          len = this.calcTextPxl(
            this.combustionChambers[i].inletMixtures[j].streamId,
            this.combustionChambers[i].inletMixtures[j].name,
          ).length;
          if (len > maxTextLength) maxTextLength = len;
        }
        for (
          j = 0;
          j < this.combustionChambers[i].outletMixtures.length;
          j++
        ) {
          len = this.calcTextPxl(
            this.combustionChambers[i].outletMixtures[j].streamId,
            this.combustionChambers[i].outletMixtures[j].name,
          ).length;
          if (len > maxTextLength) maxTextLength = len;
        }
      }
      return maxTextLength;
    },
    calcTextPxl(nr, text) {
      var s = Snap();
      var text_id = s.text(
        parseInt(this.settings.style.size) * 0.05,
        parseInt(this.settings.style.size),
        nr + ' | ' + text,
      );
      text_id.attr({
        style: 'fill: #ffffff',
        'font-size': this.settings.style.size,
      });
      var text_id_bb = text_id.getBBox();
      var rect_id = s.rect(
        parseInt(text_id_bb.x) -
          parseInt(this.settings.style.size) * 0.05,
        text_id_bb.y,
        parseInt(text_id_bb.width) +
          parseInt(this.settings.style.size) * 0.6,
        text_id_bb.height,
      );
      rect_id.attr({
        style:
          'fill: #ffffff;stroke:#000000;stroke-width:0.405661;stroke-miterlimit:4;stroke-dasharray:none;stroke-opacity:1;paint-order:fill markers stroke',
      });
      var rect_id_bb = rect_id.getBBox();
      s.remove();
      return {
        length: parseInt(rect_id_bb.width),
        height: parseInt(rect_id_bb.height),
      };
    },
    calcCombustionChamberPxl(i) {
      if (this.settings.trueToScale == true) {
        return {
          width:
            (this.combustionChambers[i].shape == 'rectangular'
              ? this.convertLength(
                  this.combustionChambers[i].width,
                  'mm',
                )
              : this.convertLength(
                  this.combustionChambers[i].diameter,
                  'mm',
                )) * this.diagramData.measuringUnit,
          height:
            (this.combustionChambers[i].shape == 'rectangular'
              ? this.convertLength(
                  this.combustionChambers[i].height,
                  'mm',
                )
              : this.convertLength(
                  this.combustionChambers[i].diameter,
                  'mm',
                )) * this.diagramData.measuringUnit,
          length:
            this.convertLength(
              this.combustionChambers[i].length,
              'mm',
            ) * this.diagramData.measuringUnit,
          diameter:
            (this.combustionChambers[i].shape == 'cylindrical'
              ? this.convertLength(
                  this.combustionChambers[i].diameter,
                  'mm',
                )
              : null) * this.diagramData.measuringUnit,
          totalLength:
            (this.combustionChambers[i].shape == 'cylindrical'
              ? (this.convertLength(
                  this.combustionChambers[i].diameter,
                  'mm',
                ) *
                  1) /
                  3 +
                this.convertLength(
                  this.combustionChambers[i].length,
                  'mm',
                )
              : (this.convertLength(
                  this.combustionChambers[i].height,
                  'mm',
                ) *
                  1) /
                  3 +
                this.convertLength(
                  this.combustionChambers[i].length,
                  'mm',
                )) * this.diagramData.measuringUnit,

          totalHeight:
            (this.combustionChambers[i].shape == 'cylindrical'
              ? this.convertLength(
                  this.combustionChambers[i].diameter,
                  'mm',
                )
              : this.convertLength(
                  this.combustionChambers[i].height,
                  'mm',
                )) * this.diagramData.measuringUnit,
        };
      } else {
        return {
          width:
            this.combustionChambers[i].orientation == 'vertical'
              ? window.screen.availWidth *
                this.diagramData.measuringUnit
              : window.screen.availWidth *
                this.diagramData.measuringUnit,
          height:
            this.combustionChambers[i].orientation == 'vertical'
              ? window.screen.availWidth *
                this.diagramData.measuringUnit
              : window.screen.availWidth *
                this.diagramData.measuringUnit,
          length:
            this.combustionChambers[i].orientation == 'vertical'
              ? window.screen.availWidth *
                3 *
                this.diagramData.measuringUnit
              : window.screen.availWidth *
                3 *
                this.diagramData.measuringUnit,
          diameter:
            this.combustionChambers[i].orientation == 'vertical'
              ? window.screen.availWidth *
                this.diagramData.measuringUnit
              : window.screen.availWidth *
                this.diagramData.measuringUnit,
          totalLength:
            this.combustionChambers[i].orientation == 'vertical'
              ? window.screen.availWidth *
                (3 + 1 / 3) *
                this.diagramData.measuringUnit
              : window.screen.availWidth *
                (3 + 1 / 3) *
                this.diagramData.measuringUnit,

          totalHeight:
            this.combustionChambers[i].orientation == 'vertical'
              ? window.screen.availWidth *
                this.diagramData.measuringUnit
              : window.screen.availWidth *
                this.diagramData.measuringUnit,
        };
      }
    },
    calcSumCombustionChamber() {
      var H = 0;
      var V = 0;
      var i;
      var n = 0;
      for (i = 0; i < this.combustionChambers.length; i++) {
        if (this.combustionChambers[i].orientation === 'horizontal') {
          H =
            H +
            this.calcCombustionChamberPxl(i).totalLength +
            this.calcCombustionChamberPxl(i).totalHeight * 4;
          V = V + this.calcCombustionChamberPxl(i).totalHeight;
        } else {
          H = H + this.calcCombustionChamberPxl(i).totalHeight * 4;
          V =
            V +
            this.calcCombustionChamberPxl(i).totalLength +
            this.calcCombustionChamberPxl(i).totalHeight * 2;
        }
      }
      //console.log("sumH/V "+ H+" "+ V);
      return {
        horizontal: H,
        vertical: V,
      };
    },
    calcDelta(i) {
      var c;
      var j;
      var maxInlet = this.calcMaxDeltaInlet();
      var maxOutlet = this.calcMaxDeltaOutlet();
      //console.log("maxInlet" + maxInlet);
      //console.log("maxOutlet" + maxOutlet);

      var maxV = Math.max(maxInlet, maxOutlet);
      var deltaV = 0;
      if (i == 0) {
        deltaV = maxV;
        if (this.combustionChambers.length > 1) {
          for (j = 1; j < this.combustionChambers.length; j++) {
            if (
              this.combustionChambers[j].orientation === 'vertical' &&
              this.combustionChambers[j].flowDirection ===
                'bottom to top'
            ) {
              deltaV = deltaV + this.calcDeltaCombustionChamber(j).V;
            }
          }
        }
      } else {
        deltaV = maxV;
        if (this.combustionChambers.length > 1) {
          for (j = 1; j < this.combustionChambers.length; j++) {
            if (
              this.combustionChambers[j].orientation === 'vertical' &&
              this.combustionChambers[j].flowDirection ===
                'bottom to top'
            ) {
              deltaV = deltaV + this.calcDeltaCombustionChamber(j).V;
            }
          }
        }
        for (c = i; c > 0; c--) {
          if (
            this.combustionChambers[c].orientation === 'horizontal'
          ) {
            //c "horizontal"
            if (
              this.combustionChambers[c].flowDirection ===
              'left to right'
            ) {
              //c "left to right"
              if (
                this.combustionChambers[c - 1].orientation ===
                'horizontal'
              ) {
                //c-1 "horizontal"
                if (
                  this.combustionChambers[c - 1].flowDirection ===
                  'left to right'
                ) {
                  //c-1 "left to right"
                  //deltaV = deltaV;
                } else {
                  //c-1 "right to left"
                  deltaV =
                    deltaV + this.calcDeltaCombustionChamber(c - 1).V;
                }
              } else {
                //c-1 "vertical"
                if (
                  this.combustionChambers[c - 1].flowDirection ===
                  'top to bottom'
                ) {
                  deltaV =
                    deltaV + this.calcDeltaCombustionChamber(c - 1).V;
                } else {
                  deltaV =
                    deltaV - this.calcDeltaCombustionChamber(c).V;
                }
              }
            } else {
              //c "right to left"
              if (
                this.combustionChambers[c - 1].orientation ===
                'horizontal'
              ) {
                //c-1 "horizontal"
                if (
                  this.combustionChambers[c - 1].flowDirection ===
                  'left to right'
                ) {
                  //c-1 "left to right"
                  deltaV =
                    deltaV + this.calcDeltaCombustionChamber(c).V;
                } else {
                  //c-1 "right to left"
                  //deltaV = deltaV;
                }
              } else {
                //c-1 "vertical"
                if (
                  this.combustionChambers[c - 1].flowDirection ===
                  'top to bottom'
                ) {
                  deltaV =
                    deltaV - this.calcDeltaCombustionChamber(c).V;
                } else {
                  deltaV =
                    deltaV + this.calcDeltaCombustionChamber(c - 1).V;
                }
              }
            }
          } else {
            //c "vertical"
            if (
              this.combustionChambers[c].flowDirection ===
              'top to bottom'
            ) {
              //c "top to bottom"
              if (
                this.combustionChambers[c - 1].orientation ===
                'horizontal'
              ) {
                //c-1 "horizontal"
                if (
                  this.combustionChambers[c - 1].flowDirection ===
                  'left to right'
                ) {
                  //c-1 "left to right"
                  deltaV =
                    deltaV + this.calcDeltaCombustionChamber(c - 1).V;
                } else {
                  //c-1 "right to left"
                  deltaV =
                    deltaV - this.calcDeltaCombustionChamber(c).V;
                }
              } else {
                //c-1 "vertical"
                if (
                  this.combustionChambers[c - 1].flowDirection ===
                  'top to bottom'
                ) {
                  deltaV =
                    deltaV +
                    this.calcDeltaCombustionChamber(c - 1).V +
                    this.calcDeltaCombustionChamber(c).H;
                } else {
                  //deltaV = deltaV;
                }
              }
            } else {
              //c "bottom to top"
              if (
                this.combustionChambers[c - 1].orientation ===
                'horizontal'
              ) {
                //c-1 "horizontal"
                if (
                  this.combustionChambers[c - 1].flowDirection ===
                  'left to right'
                ) {
                  //c-1 "left to right"
                  deltaV =
                    deltaV - this.calcDeltaCombustionChamber(c).V;
                } else {
                  //c-1 "right to left"
                  //deltaV = deltaV;
                }
              } else {
                //c-1 "vertical"
                if (
                  this.combustionChambers[c - 1].flowDirection ===
                  'top to bottom'
                ) {
                  //deltaV = deltaV;
                } else {
                  deltaV =
                    deltaV - this.calcDeltaCombustionChamber(c).V;
                }
              }
            }
          }
        }
      }
      return {
        V: deltaV,
        H: 0,
      };
    },
    calcMaxDeltaOutlet() {
      var maxOutlet = 0;
      var vOutlet = 0;
      var j;
      for (j = 0; j < this.combustionChambers.length; j++) {
        vOutlet = this.calcDeltaOutlet(j).V;
        if (maxOutlet < vOutlet) maxOutlet = vOutlet;
      }
      return maxOutlet;
    },
    calcMaxDeltaInlet() {
      var maxInlet = 0;
      var vInlet = 0;
      var j;
      for (j = 0; j < this.combustionChambers.length; j++) {
        vInlet = this.calcDeltaInlet(j).V;
        if (maxInlet < vInlet) maxInlet = vInlet;
      }
      return maxInlet;
    },
    calcDeltaInlet(i) {
      var maxTextLength = 0;
      var j;
      var len = 0;
      for (
        j = 0;
        j < this.combustionChambers[i].inletMixtures.length;
        j++
      ) {
        len = this.calcTextPxl(
          this.combustionChambers[i].inletMixtures[j].streamId,
          this.combustionChambers[i].inletMixtures[j].name,
        ).length;
        if (len > maxTextLength) maxTextLength = len;
      }
      return {
        H: maxTextLength,
        V:
          parseInt(this.settings.style.size) *
          this.combustionChambers[i].inletMixtures.length *
          2,
      };
    },
    calcDeltaOutlet(i) {
      var maxTextLength = 0;
      var j;
      var len = 0;
      for (
        j = 0;
        j < this.combustionChambers[i].outletMixtures.length;
        j++
      ) {
        len = this.calcTextPxl(
          this.combustionChambers[i].outletMixtures[j].streamId,
          this.combustionChambers[i].outletMixtures[j].name,
        ).length;
        if (len > maxTextLength) maxTextLength = len;
      }
      return {
        H: maxTextLength,
        V:
          parseInt(this.settings.style.size) *
          this.combustionChambers[i].outletMixtures.length *
          2,
      };
    },
    calcDeltaCombustionChamber(i) {
      var deltaH = 0;
      var deltaV = 0;
      if (this.combustionChambers[i].orientation == 'horizontal') {
        deltaH = this.calcCombustionChamberPxl(i).totalLength;
        deltaV = this.calcCombustionChamberPxl(i).totalHeight;
      } else {
        deltaH = this.calcCombustionChamberPxl(i).totalHeight;
        deltaV = this.calcCombustionChamberPxl(i).totalLength;
      }
      return {
        H: deltaH,
        V: deltaV,
      };
    },
    init() {
      this.diagramData.measuringUnit = 1;
      var maxInlet = this.calcMaxDeltaInlet();
      var maxOutlet = this.calcMaxDeltaOutlet();
      var maxV = maxInlet + maxOutlet; //Math.max(maxOutlet, maxInlet);
      var sumV = this.calcSumCombustionChamber().vertical;
      var sumH = this.calcSumCombustionChamber().horizontal;

      var maxTextLength = this.calcMaxTextLength();
      var itt = 0;
      var diff = 0;
      //#region init measuringUnit
      var measuringUnitOld = 0;
      var measuringUnitNew = 0;
      measuringUnitOld = this.calcMeasuringUnit(sumH, sumV);
      do {
        measuringUnitNew = this.calcMeasuringUnit(
          sumH +
            (maxTextLength * (this.combustionChambers.length + 1)) /
              measuringUnitOld,
          sumV + maxV / measuringUnitOld,
        );
        diff = Math.abs(measuringUnitNew - measuringUnitOld);
        itt++;
        measuringUnitOld = measuringUnitNew;
      } while (itt < 10 && diff > 0.001);
      this.diagramData.measuringUnit = measuringUnitNew;
      this.spacing = this.calcDeltaCombustionChamber(0).V;
      sumV = this.calcSumCombustionChamber().vertical + maxV;
      sumH =
        this.calcSumCombustionChamber().horizontal +
        maxTextLength * (this.combustionChambers.length + 1);
      //#endregion init measuringUnit

      var i;
      var j;
      var k;
      var deltaH = 0;
      var deltaV = 0;
      var order = 0;
      var link = [];
      var connect = [];
      var color;
      var textColor;
      var flame = true;
      var c;
      for (i = 0; i < this.combustionChambers.length; i++) {
        deltaV = 0;
        ////#region check Flame
        //flame = false;
        //for (
        //  j = 0;
        //  j < this.combustionChambers[i].inletMixtures.length;
        //  j++
        //) {
        //  if (
        //    this.combustionChambers[i].inletMixtures[j].streamType ===
        //    'Fuel'
        //  ) {
        //    flame = true;
        //    break;
        //  }
        //}
        ////#endregion check Flame

        //#region inletMixtures
        //#region sort inletMixtures
        var inletMixturesSortByStreamID = this.combustionChambers[i]
          .inletMixtures;
        inletMixturesSortByStreamID.sort((a, b) =>
          a.streamId > b.streamId ? 1 : -1,
        );
        var linkOrder = new Array(
          this.combustionChambers[i].inletMixtures.length,
        );

        for (
          j = 0;
          j < this.combustionChambers[i].inletMixtures.length;
          j++
        ) {
          for (k = 0; k < inletMixturesSortByStreamID.length; k++) {
            if (
              inletMixturesSortByStreamID[k].streamId ==
              this.combustionChambers[i].inletMixtures[j].streamId
            ) {
              linkOrder[k] = j;
            }
          }
        }
        //#endregion
        c = this.combustionChambers[i].publicId;
        link = [];
        order = 0;
        for (
          j = 0;
          j < this.combustionChambers[i].inletMixtures.length;
          j++
        ) {
          color = this.getColorStreamType(
            this.combustionChambers[i].inletMixtures[j],
          );
          textColor = this.getTextColorStreamType(
            this.combustionChambers[i].inletMixtures[j],
          );
          link[0] = order;
          this.inlet.push({
            id: this.combustionChambers[i].inletMixtures[j].publicId,
            x:
              deltaH +
              this.calcDeltaInlet(i).H -
              this.calcTextPxl(
                this.combustionChambers[i].inletMixtures[j].streamId,
                this.combustionChambers[i].inletMixtures[j].name,
              ).length,
            y: parseInt(this.settings.style.size) * order * 2,
            linkOrder: [linkOrder[order]],
            connection: [c],
            flowDirection: 'left to right',
            type: 'input',
            nr: this.combustionChambers[i].inletMixtures[j].streamId,
            text: this.combustionChambers[i].inletMixtures[j].name,
            color: color,
            textColor: textColor,
          });
          ++order;
        }

        if (this.combustionChambers[i].orientation === 'horizontal') {
          deltaH =
            deltaH +
            maxTextLength +
            this.calcDeltaCombustionChamber(i).V * 2;
        } else {
          deltaH =
            deltaH +
            maxTextLength +
            this.calcDeltaCombustionChamber(i).H;
        }

        //#endregion inletMixtures

        //#region init combustionChamber
        link = [];
        connect = [];
        for (
          j = 0;
          j < this.combustionChambers[i].outletMixtures.length;
          j++
        ) {
          link.push(j);
          connect.push(
            this.combustionChambers[i].outletMixtures[j].publicId,
          );
        }
        this.inlet.push({
          id: this.combustionChambers[i].publicId,
          x: deltaH,
          y: this.calcDelta(i).V,
          linkOrder: link,
          connection: connect,
          type: 'burner',
          flame: flame,
          shape: this.combustionChambers[i].shape, //"cylindrical"//"rectangular"
          orientation: this.combustionChambers[i].orientation, //"horizontal"//"vertical"
          flowDirection: this.combustionChambers[i].flowDirection, //"top to bottom"//"bottom to top"//"left to right"//"right to left"
          size: {
            width: this.calcCombustionChamberPxl(i).width,
            height: this.calcCombustionChamberPxl(i).height,
            length: this.calcCombustionChamberPxl(i).length,
            diameter: this.calcCombustionChamberPxl(i).diameter,
          },
          temperature: [
            { T: 2000, x: 0 },
            { T: 1600, x: 0.5 },
            { T: 900, x: 1 },
          ],
        });

        //#region recirculation
        for (
          j = 0;
          j < this.combustionChambers[i].inletMixtures.length;
          j++
        ) {
          if (
            connect.findIndex(
              element =>
                element ==
                this.combustionChambers[i].inletMixtures[j].publicId,
            ) != -1
          ) {
            for (k = 0; k < this.inlet.length; k++) {
              if (
                this.inlet[k].id ===
                this.combustionChambers[i].inletMixtures[j].publicId
              ) {
                if (
                  this.combustionChambers[i].flowDirection ==
                  'left to right'
                )
                  this.inlet[k].flowDirection = 'right to left';
                if (
                  this.combustionChambers[i].flowDirection ==
                  'right to left'
                )
                  this.inlet[k].flowDirection = 'left to right';
                if (
                  this.combustionChambers[i].flowDirection ==
                  'top to bottom'
                )
                  this.inlet[k].flowDirection = 'bottom to top';
                if (
                  this.combustionChambers[i].flowDirection ==
                  'bottom to top'
                )
                  this.inlet[k].flowDirection = 'top to bottom';
                if (
                  this.combustionChambers[i].flowDirection ===
                  'top to bottom'
                ) {
                  this.inlet[k].x =
                    deltaH -
                    parseInt(this.settings.style.size) *
                      (this.combustionChambers[i].inletMixtures
                        .length -
                        j) *
                      2;
                  this.inlet[k].y =
                    this.calcDelta(i).V +
                    this.calcDeltaCombustionChamber(i).V +
                    this.calcDeltaCombustionChamber(i).H +
                    parseInt(this.settings.style.size) *
                      (this.combustionChambers[i].inletMixtures
                        .length -
                        j) *
                      2;
                } else if (
                  this.combustionChambers[i].flowDirection ===
                  'bottom to top'
                ) {
                  this.inlet[k].x =
                    deltaH -
                    parseInt(this.settings.style.size) *
                      (this.combustionChambers[i].inletMixtures
                        .length -
                        j) *
                      2;
                  this.inlet[k].y =
                    this.calcDelta(i).V +
                    this.calcDeltaCombustionChamber(i).H +
                    parseInt(this.settings.style.size) *
                      (this.combustionChambers[i].inletMixtures
                        .length -
                        j) *
                      2;
                } else {
                  this.inlet[k].x = deltaH;
                  this.inlet[k].y =
                    this.calcDelta(i).V +
                    this.calcDeltaCombustionChamber(i).V +
                    parseInt(this.settings.style.size) *
                      (this.combustionChambers[i].inletMixtures
                        .length -
                        j) *
                      2;
                }
              }
            }
          }
          //console.log("inlet connection " + this.combustionChambers[i].inletMixtures[j].publicId);
        }

        //console.log("burner connection " + connect);

        //#endregion recirculation

        if (this.combustionChambers[i].orientation === 'horizontal') {
          deltaH =
            deltaH +
            this.calcDeltaCombustionChamber(i).H +
            this.calcDeltaCombustionChamber(i).V *
              (1 +
                1 / this.combustionChambers[i].outletMixtures.length);
        } else {
          deltaH = deltaH + this.calcDeltaCombustionChamber(i).H * 2;
        }
        //#endregion init combustionChamber

        //#region init outletMixures
        if (
          this.combustionChambers[i].flowDirection === 'top to bottom'
        ) {
          deltaV =
            this.calcDelta(i).V +
            this.calcDeltaCombustionChamber(i).V +
            this.calcDeltaCombustionChamber(i).H;
        } else if (
          this.combustionChambers[i].flowDirection === 'bottom to top'
        ) {
          deltaV = 0;
        } else {
          deltaV =
            this.calcDelta(i).V +
            this.calcDeltaCombustionChamber(i).V;
        }

        link = [];
        order = 0;
        for (
          j = 0;
          j < this.combustionChambers[i].outletMixtures.length;
          j++
        ) {
          color = this.getColorStreamType(
            this.combustionChambers[i].outletMixtures[j],
          );
          textColor = this.getTextColorStreamType(
            this.combustionChambers[i].outletMixtures[j],
          );
          link[0] = order;
          this.inlet.push({
            id: this.combustionChambers[i].outletMixtures[j].publicId,
            x:
              deltaH +
              maxTextLength -
              this.calcTextPxl(
                this.combustionChambers[i].outletMixtures[j].streamId,
                this.combustionChambers[i].outletMixtures[j].name,
              ).length,
            y:
              deltaV + parseInt(this.settings.style.size) * order * 2,
            linkOrder: [order],
            connection: [],
            flowDirection: 'left to right',
            type: 'input',
            nr: this.combustionChambers[i].outletMixtures[j].streamId,
            text: this.combustionChambers[i].outletMixtures[j].name,
            color: color,
            textColor: textColor,
          });
          ++order;
        }
        //#endregion init outletMixures

        //console.log(this.combustionChambers[i]);
      }
      //#region delete double nodes
      for (i = 0; i < this.inlet.length; i++) {
        for (j = i + 1; j < this.inlet.length; j++) {
          if (this.inlet[i].id === this.inlet[j].id) {
            if (this.inlet[i].connection == '') {
              if (this.inlet[i].flowDirection == 'right to left') {
                this.inlet[j].flowDirection = 'right to left';
              }
              //console.log("i "+this.inlet[i].id);
              this.inlet[j].x = this.inlet[i].x;
              this.inlet[j].y = this.inlet[i].y;
              this.inlet.splice(i, 1);
              i = 0;
            } else if (this.inlet[j].connection == '') {
              // case for recirc
              if (this.inlet[j].flowDirection == 'right to left') {
                this.inlet[i].flowDirection = 'right to left';
              }
              //console.log("j "+this.inlet[j].id);
              //this.inlet[i].x = this.inlet[j].x;
              //this.inlet[i].y = this.inlet[j].y;
              this.inlet.splice(j, 1);
              j = 0;
            }
          }
        }
      }

      //#endregion delete double nodes
      this.diagramData.height = sumV + 50;
      this.diagramData.width = sumH + 50;
      //console.log(this.inlet);
    },
    initDiagramData() {
      var i;
      var j;
      for (i = 0; i < this.inlet.length; i++) {
        if (this.inlet[i].type == 'input') {
          ++this.maxInlet;
        }
      }
      //#region init nodes
      for (i = 0; i < this.inlet.length; i++) {
        if (this.inlet[i].type == 'input') {
          this.createInput(i);
        }
        if (
          this.inlet[i].type == 'burner' ||
          this.inlet[i].type == 'burner-constricted'
        ) {
          this.createBurner(i);
        }
      }

      //#region init links
      for (i = 0; i < this.inlet.length; i++) {
        this.createConnection(i);
      }
      //#endregion init links

      //console.log(this.diagramData)
    },
    createInput(id) {
      var idStart;
      var idEnd;
      var numInlet = 0;
      var numOutlet = 0;
      for (idStart = 0; idStart < this.inlet.length; idStart++) {
        for (
          idEnd = 0;
          idEnd < this.inlet[idStart].connection.length;
          idEnd++
        ) {
          if (
            this.inlet[idStart].connection[idEnd] == this.inlet[id].id
          ) {
            ++numInlet;
          }
        }
      }
      for (
        idEnd = 0;
        idEnd < this.inlet[id].connection.length;
        idEnd++
      ) {
        if (this.inlet[id].connection[idEnd] != '') {
          ++numOutlet;
        }
      }
      this.operands.push({
        id: this.inlet[id].id,
      });

      this.diagramData.nodes.push({
        id: this.inlet[id].id,
        numInlet: numInlet,
        numOutlet: numOutlet,
        length: this.calcTextPxl(
          this.inlet[id].nr,
          this.inlet[id].text,
        ).length,
        height: this.calcTextPxl(
          this.inlet[id].nr,
          this.inlet[id].text,
        ).height,
        objectType: this.inlet[id].type,
        flowDirection: this.inlet[id].flowDirection,
        content: {
          color: this.inlet[id].color,
          text: this.inlet[id].nr + ' | ' + this.inlet[id].text,
          fontSize: this.settings.style.size,
          textColor: this.inlet[id].textColor,
        },
        point: {
          x: this.inlet[id].x,
          y: this.inlet[id].y,
        },
      });
    },
    createBurner(id) {
      var idStart;
      var idEnd;
      var numInlet = 0;
      var numOutlet = 0;
      for (idStart = 0; idStart < this.inlet.length; idStart++) {
        for (
          idEnd = 0;
          idEnd < this.inlet[idStart].connection.length;
          idEnd++
        ) {
          if (
            this.inlet[idStart].connection[idEnd] == this.inlet[id].id
          ) {
            ++numInlet;
          }
        }
      }
      for (
        idEnd = 0;
        idEnd < this.inlet[id].connection.length;
        idEnd++
      ) {
        if (this.inlet[id].connection[idEnd] != '') {
          ++numOutlet;
        }
      }
      this.operands.push({
        id: this.inlet[id].id,
      });

      this.diagramData.nodes.push({
        id: this.inlet[id].id,
        numInlet: numInlet,
        numOutlet: numOutlet,
        diameter: this.inlet[id].size.diameter,
        width: this.inlet[id].size.width,
        height: this.inlet[id].size.height,
        length: this.inlet[id].size.length,
        objectType: this.inlet[id].type,
        orientation: this.inlet[id].orientation,
        flowDirection: this.inlet[id].flowDirection,
        shape: this.inlet[id].shape,
        flame: this.inlet[id].flame,
        content: {
          color: '#e6e6e6',
          text: this.inlet[id].text,
          fontSize: this.settings.style.size,
        },
        point: {
          x: this.inlet[id].x,
          y: this.inlet[id].y,
        },
        stops: this.inlet[id].temperature,
      });
    },
    createConnection(idStart) {
      var arr = 'dest';
      var idEnd;
      for (
        idEnd = 0;
        idEnd < this.inlet[idStart].connection.length;
        idEnd++
      ) {
        //if (this.inlet[idStart].flowDirection === 'right to left' || this.inlet[idStart].flowDirection === 'bottom to top') {
        //  arr = 'src';
        //} else {
        //  arr = 'dest';
        //}

        if (this.inlet[idStart].connection[idEnd] != null) {
          this.diagramData.links.push({
            id: uuidv4(),
            source: this.inlet[idStart].id,
            order: this.inlet[idStart].linkOrder[idEnd],
            destination: this.inlet[idStart].connection[idEnd],
            arrow: arr,
            color: this.inlet[idStart].color,
            spacing: 0, //this.spacing,
            point: {
              x: this.inlet[idStart].x,
              y: this.inlet[idStart].y,
            },
          });
        }
      }
    },
    jsonImport() {
      this.dialogImport = false;
      this.isImportActive = true;
      if (!this.chosenFile) {
        alert('No File Chosen');
      } else {
        var reader = new FileReader();
        reader.readAsText(this.chosenFile);
        reader.onload = () => {
          this.json = reader.result;
          const obj = JSON.parse(this.json);
          if (obj) {
            this.diagramData = obj;
          }
        };
      }
    },
    jsonExport() {
      this.dialogExport = false;
      this.isExportActive = true;
      this.json = JSON.stringify(this.diagramData);
      if (!this.dialogFileName) {
        this.downloadJson(
          this.json,
          'exportDiagram.json',
          'octet/stream',
        );
      } else {
        this.downloadJson(
          this.json,
          this.dialogFileName + '.json',
          'octet/stream',
        );
      }
    },
    downloadJson(content, fileName, contentType) {
      var a = document.createElement('a');
      var file = new Blob([content], { type: contentType });
      a.href = URL.createObjectURL(file);
      a.download = fileName;
      a.click();
    },
    downloadDiagram() {
      this.dialogDownload = false;
      switch (this.dialogDataFormat) {
        case 'svg':
          var name;
          var href;
          var link;
          var blob;

          blob = new Blob(
            [
              document.getElementById('svg-diagram-show-area')
                .innerHTML,
            ],
            {
              type: 'image/svg+xml',
            },
          );
          href = window.URL.createObjectURL(blob);
          link = document.createElement('a');
          if (!this.dialogFileName) {
            name = 'svgDiagram.svg';
          } else {
            name = this.dialogFileName + '.svg';
          }
          link.href = href;
          link.download = name;
          link.click();
          break;
        case 'png':
          if (!this.dialogFileName) {
            Svg.saveSvgAsPng(
              document.getElementById('svg-diagram'),
              'pngDiagram.png',
            );
          } else {
            Svg.saveSvgAsPng(
              document.getElementById('svg-diagram'),
              this.dialogFileName + '.png',
            );
          }
          break;
        default:
          if (!this.dialogFileName) {
            Svg.saveSvgAsPng(
              document.getElementById('svg-diagram'),
              'pngDiagram.png',
            );
          } else {
            Svg.saveSvgAsPng(
              document.getElementById('svg-diagram'),
              this.dialogFileName + '.png',
            );
          }
          break;
      }
    },
    getColorStreamTypesConfig(phase, streamType) {
      return (
        this.streamTypes[phase].find(type => type.name == streamType)
          .color || ''
      );
    },
    getColorStreamType(mixture) {
      // console.log(
      //   `(${mixture.streamId}) ${mixture.name}: ${mixture.phase}/${mixture.streamType}`,
      // );
      var color = this.streamTypes[mixture.phase].find(
        streamType => streamType.name === mixture.streamType,
      ).color;
      return color;
    },
    getTextColorStreamType(mixture) {
      //console.log(mixture.streamId + " " + mixture.name + " " + mixture.streamType);
      var color = this.streamTypes[mixture.phase].find(
        streamType => streamType.name === mixture.streamType,
      ).textColor;
      return color;
    },
    checkConfig() {
      var i;
      var j;
      var n;
      var l;
      var nn;
      var flame = true;
      ////#region check streamType for flame
      //for (i = 0; i < this.combustionChambers.length; i++) {
      //  if (
      //    this.combustionChambers[i].inletMixtures.some(
      //      mix => mix.streamType === 'Fuel',
      //    )
      //  ) {
      //    flame = true;
      //    break;
      //  }
      //}
      ////#endregion check streamType for flame

      //#region check deleted mixtures
      var count = 0;
      for (n = 0; n < this.diagramData.nodes.length; n++) {
        if (this.diagramData.nodes[n].objectType === 'input') {
          for (i = 0; i < this.combustionChambers.length; i++) {
            var indexIn = this.combustionChambers[
              i
            ].inletMixtures.findIndex(
              mix => mix.publicId === this.diagramData.nodes[n].id,
            );
            if (indexIn !== -1) {
              //console.log(this.diagramData.nodes[n].content.text + " gefunden Inlet");
              //console.log(this.combustionChambers[i].inletMixtures[indexIn].streamId + "|" + this.combustionChambers[i].inletMixtures[indexIn].name + " gefunden Inlet");
              //console.log("______________________________________________");
              break;
            } else {
              count++;
              if (count === this.combustionChambers.length) {
                //console.log(this.diagramData.nodes[n].content.text + " nicht gefunden Inlet");
                this.autoFormat();
                this.updateProjectDiagram();
                return;
              }
            }
          }
          count = 0;
        }
      }
      //#endregion check deleted mixtures

      //#region check saved currentproject diagramdata with actual data
      for (i = 0; i < this.combustionChambers.length; i++) {
        for (
          j = 0;
          j < this.combustionChambers[i].inletMixtures.length;
          j++
        ) {
          for (n = 0; n < this.diagramData.nodes.length; n++) {
            if (
              this.combustionChambers[i].inletMixtures[j].publicId ===
              this.diagramData.nodes[n].id
            ) {
              //#region check if inletmixtures have changed streamtype
              if (
                this.getColorStreamType(
                  this.combustionChambers[i].inletMixtures[j],
                ) != this.diagramData.nodes[n].content.color
              ) {
                //check streamtype difference

                for (l = 0; l < this.diagramData.links.length; l++) {
                  //change matching link
                  if (
                    this.diagramData.links[l].source ==
                    this.diagramData.nodes[n].id
                  ) {
                    //#region changed streamType to Fuel of from Fuel to another
                    if (
                      this.combustionChambers[i].inletMixtures[j]
                        .streamType == 'Fuel' ||
                      this.diagramData.nodes[n].content.color ==
                        this.getColorStreamType({
                          phase: 'g',
                          streamType: 'Fuel',
                        })
                    ) {
                      for (
                        nn = 0;
                        nn < this.diagramData.nodes.length;
                        nn++
                      ) {
                        if (
                          this.diagramData.links[l].destination ==
                          this.diagramData.nodes[nn].id
                        ) {
                          this.diagramData.nodes[nn].flame = flame; //set flame
                        }
                      }
                    }
                    //#endregion  changed streamType to Fuel of from Fuel to another

                    //#region set matching lnk
                    this.diagramData.links[
                      l
                    ].color = this.getColorStreamType(
                      this.combustionChambers[i].inletMixtures[j],
                    );
                    //#endregion set matching lnk
                  }
                }

                this.diagramData.nodes[
                  n
                ].content.color = this.getColorStreamType(
                  this.combustionChambers[i].inletMixtures[j],
                ); //set color node

                this.diagramData.nodes[
                  n
                ].content.textColor = this.getTextColorStreamType(
                  this.combustionChambers[i].inletMixtures[j],
                ); //set textColor node

                this.updateProjectDiagram();
              }
              //#endregion check if inletmixtures have changed streamtyp

              //#region check if inletmixtures have changed text
              if (
                String(
                  this.combustionChambers[i].inletMixtures[j]
                    .streamId +
                    ' | ' +
                    this.combustionChambers[i].inletMixtures[j].name,
                ) != this.diagramData.nodes[n].content.text
              ) {
                this.diagramData.nodes[n].content.text = String(
                  this.combustionChambers[i].inletMixtures[j]
                    .streamId +
                    ' | ' +
                    this.combustionChambers[i].inletMixtures[j].name,
                ); //set new text
              }
              //#endregion check if inletmixtures have changed text
            }
          }
        }
      }
      //#endregion check saved currentproject diagramdata with actual data
    },
    round(value, precision) {
      return _.round(value, precision);
    },
  },
  mounted() {
    if (this.projectDiagram == null) {
      //console.log("project diagram null " + this.projectDiagram);
      this.settings.showGrid = true;
      this.diagramData.background = this.$vuetify.theme.framework
        .theme.isDark
        ? '#000000'
        : '#ffffff';
      this.init();
      this.initDiagramData();
      this.updateProjectDiagram();
    } else {
      //get current project diagram edit save
      //console.log("project diagram " + this.projectDiagram);
      this.settings.showGrid = true;
      this.diagramData.background = this.$vuetify.theme.framework
        .theme.isDark
        ? '#000000'
        : '#ffffff';
      this.diagramData.measuringUnit = this.projectDiagram.measuringUnit;
      this.diagramData.nodes = this.projectDiagram.nodes;
      this.diagramData.links = this.projectDiagram.links;
      this.checkConfig();
    }
  },
};
</script>
<style>
SvgPlant {
  position: relative;
}
</style>
