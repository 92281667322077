import colormap from 'colormap';
import { v4 as uuidv4 } from 'uuid';
export const nodeMixin = {
  props: {
    node: {
      width: Number,
      height: Number,
      //diameter: Number,
      length: Number,
      numInlet: Number,
      numOutlet: Number,
      id: String,
      point: {
        type: Object,
        default: {
          x: 0,
          y: 0,
        },
      },
      content: {
        text: String,
        url: String,
        color: String,
        textColor: String,
        fontSize: Number,
      },
      objectType: {
        type: String,
        default: 'rectangle',
      },
      flame: {
        type: Boolean,
        default: false,
      },
      shape: {
        type: String,
        default: 'rectangular',
      },
      orientation: {
        type: String,
        default: 'vertical',
      },
      stroke: String,
      strokeWeight: Number,
      stops: [
        {
          T: 0,
          x: 0,
        },
      ],
    },
    editable: Boolean,
    createLinkMode: Boolean,
    selected: Boolean,
    labels: Object,
    scale: Number,
    rWidth: Number,
    rHeight: Number,
    screenHeight: Number,
    screenWidth: Number,
  },
  watch: {
    node() {
      this.x = this.node.point.x;
      this.y = this.node.point.y;
    },
  },
  data() {
    return {
      gradientHorizontalID: uuidv4(),
      gradientVerticalID: uuidv4(),
      gradientHorizontalTemperatureID: uuidv4(),
      gradientVerticalTemperatureID: uuidv4(),

      startPosition: null,
      cursorOffset: {
        x: 0,
        y: 0,
      },
      id: this.node.id,
      x: this.node.point.x,
      y: this.node.point.y,
    };
  },
  methods: {
    calcBurner() {
      if (this.node.orientation === 'horizontal') {
        if (this.node.flowDirection === 'left to right') {
          return {
            v: 0,
            h: 0,
          };
        } else if (this.node.flowDirection === 'right to left') {
          return {
            v: 0,
            h: this.node.length,
          };
        }
      } else if (this.node.orientation === 'vertical') {
        if (this.node.flowDirection === 'top to bottom') {
          return {
            v: 0,
            h: 0,
          };
        } else if (this.node.flowDirection === 'bottom to top') {
          return {
            v: this.node.length,
            h: 0,
          };
        }
      }
      return {
        v: 0,
        h: 0,
      };
    },
    calcChamber() {
      if (this.node.orientation === 'horizontal') {
        if (this.node.flowDirection === 'left to right') {
          return {
            v: 0,
            h: (this.node.height * 1) / 3,
          };
        } else if (this.node.flowDirection === 'right to left') {
          return {
            v: 0,
            h: 0,
          };
        }
      } else if (this.node.orientation === 'vertical') {
        if (this.node.flowDirection === 'top to bottom') {
          return {
            v: (this.node.height * 1) / 3,
            h: 0,
          };
        } else if (this.node.flowDirection === 'bottom to top') {
          return {
            v: 0,
            h: 0,
          };
        }
      }
      return {
        v: 0,
        h: 0,
      };
    },
    calcAlgebraicSign() {
      if (this.node.flowDirection === 'left to right') {
        return 1;
      } else if (this.node.flowDirection === 'right to left') {
        return -1;
      }
      if (this.node.flowDirection === 'top to bottom') {
        return 1;
      } else if (this.node.flowDirection === 'bottom to top') {
        return -1;
      }
      return 1;
    },
    calcInput() {
      if (this.node.flowDirection === 'left to right') {
        return {
          x: 0,
          textX: 0,
        };
      } else if (this.node.flowDirection === 'right to left') {
        return {
          x:
            this.node.length +
            this.node.height +
            this.node.content.fontSize * 0.6,
          textX: this.node.content.fontSize * 0.6,
        };
      }
      if (this.node.flowDirection === 'top to bottom') {
        return {
          x: 0,
          textX: this.node.content.fontSize * 0.6,
        };
      } else if (this.node.flowDirection === 'bottom to top') {
        return {
          x: 0,
          textX: this.node.content.fontSize * 0.6,
        };
      }
      return {
        x: 0,
        textX: 0,
      };
    },
    calcFlame() {
      if (this.node.orientation === 'horizontal') {
        if (this.node.flowDirection === 'left to right') {
          return {
            v: 0,
            h: 0,
          };
        } else if (this.node.flowDirection === 'right to left') {
          return {
            v: 0,
            h: this.node.length - (this.node.height * 1) / 3,
          };
        }
      } else if (this.node.orientation === 'vertical') {
        if (this.node.flowDirection === 'top to bottom') {
          return {
            v: 0,
            h: 0,
          };
        } else if (this.node.flowDirection === 'bottom to top') {
          return {
            v: this.node.length - (this.node.height * 1) / 3,
            h: 0,
          };
        }
      }
      return {
        v: 0,
        h: 0,
      };
    },
    getColor(T) {
      const n = 256;
      var colors = colormap({
        colormap: 'jet',
        nshades: n,
        format: 'hex',
      });
      // const TMin = this.stops.reduce(function(prev, curr) {
      //   return prev.T < curr.T ? prev : curr;
      // }).T;
      // const TMax = this.stops.reduce((prev, curr) =>
      //   prev.T > curr.T ? prev : curr,
      // ).T;
      // console.log(TMin, TMax);
      const idx = Math.round(
        ((T - this.TMin) / (this.TMax - this.TMin)) * (n - 1),
      );
      var color = colors[idx];

      return color;
    },
    getLocation(e) {
      let x = 0;
      let y = 0;
      if (e.touches) {
        x = e.touches[0].pageX;
        y = e.touches[0].pageY;
      } else {
        x = e.pageX;
        y = e.pageY;
      }
      return [x, y];
    },
    changeFlowDirection() {
      if (this.node.flowDirection === 'left to right') {
        this.node.flowDirection = 'right to left';
      } else if (this.node.flowDirection === 'right to left') {
        this.node.flowDirection = 'left to right';
      }
      if (this.node.flowDirection == 'bottom to top') {
        this.node.flowDirection = 'top to bottom';
      } else if (this.node.flowDirection === 'top to bottom') {
        this.node.flowDirection = 'bottom to top';
      }
    },
    calcChangeFlowDirection() {
      if (this.node.flowDirection === 'left to right') {
        return {
          x:
            this.node.length +
            this.node.height +
            this.node.content.fontSize * 0.6,
          y: 0,
        };
      } else if (this.node.flowDirection === 'right to left') {
        return {
          x:
            this.node.length +
            this.node.height +
            this.node.content.fontSize * 0.6,
          y: 0,
        };
      }
      if (this.node.flowDirection === 'top to bottom') {
        return {
          x: this.node.height + this.node.content.fontSize * 0.6,
          y: 0,
        };
      } else if (this.node.flowDirection === 'bottom to top') {
        return {
          x: this.node.height + this.node.content.fontSize * 0.6,
          y: 0,
        };
      }
      return {
        x: 0,
        y: 0,
      };
    },
    toggleSelect() {
      this.$emit('toggleSelect');
    },
    commitDest() {
      this.$emit('commitDest', this.id);
    },
    remove() {
      this.$emit('remove', this.id);
    },
    copy() {
      this.$emit('copy', this.node);
    },
    mousedown(e) {
      if (!this.editable) return;
      this.$emit('select', this.id);
      const [x, y] = this.getLocation(e);
      this.cursorOffset.x = x;
      this.cursorOffset.y = y;
      this.startPosition = { x: this.x, y: this.y };
      document.addEventListener('mousemove', this.mousemove);
      document.addEventListener('mouseup', this.mouseup);
    },
    mousemove(e) {
      if (this.startPosition) {
        e.preventDefault();
        const [x, y] = this.getLocation(e);
        this.x =
          this.startPosition.x +
          (x - this.cursorOffset.x) /
            this.rWidth /
            parseFloat(this.scale);
        this.y =
          this.startPosition.y +
          (y - this.cursorOffset.y) /
            this.rHeight /
            parseFloat(this.scale);
        this.$emit('updateLocation', {
          id: this.id,
          x: this.x,
          y: this.y,
        });
      }
    },
    mouseup() {
      this.startPosition = null;
      document.removeEventListener('mousemove', this.mousemove);
      document.removeEventListener('mouseup', this.mouseup);
    },
    editCandidate() {
      this.$emit('editNode', {
        id: this.id,
        objectType: this.node.objectType,
        width: this.node.length,
        height: this.node.height,
        content: this.content,
        stroke: this.node.stroke,
        strokeWeight: this.node.strokeWeight,
      });
    },
  },
  computed: {
    TMin() {
      return this.node.stops.reduce(function(prev, curr) {
        return prev.T < curr.T ? prev : curr;
      }).T;
    },
    TMax() {
      return this.node.stops.reduce(function(prev, curr) {
        return prev.T > curr.T ? prev : curr;
      }).T;
    },
  },
};
