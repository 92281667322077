<template>
  <div
    id="svg-diagram-show-area"
    class="scrollXY"
    ref="field"
    style=" overflow-x: scroll; overflow-y: scroll;"
  >
    <svg
      id="svg-diagram"
      :width="fluid ? `100%` : width"
      :height="fluid ? `100%` : height"
      style=" overflow-x: scroll; overflow-y: scroll;"
      :viewBox="viewBoxDiagram"
      xmlns="http://www.w3.org/2000/svg"
    >
      <!--<defs>
        <pattern :id="smallGridID"
                 :width="100 * measuringUnit"
                 :height="100 * measuringUnit"
                 patternUnits="userSpaceOnUse">-->
      <!--<path
          :d="
            'M ' +
              100 * measuringUnit +
              ' 0 L 0 0 0 ' +
              100 * measuringUnit
          "
          fill="none"
          stroke="gray"
          stroke-width="0.5"
        />-->
      <!--</pattern>
        <pattern :id="gridID"
                 :width="1000 * measuringUnit"
                 :height="1000 * measuringUnit"
                 patternUnits="userSpaceOnUse">
          <rect :width="1000 * measuringUnit"
                :height="1000 * measuringUnit"
                :fill="`url('#${smallGridID}')`" />

          <path :d="
              'M ' +
                1000 * measuringUnit +
                ' 0 L 0 0 0 ' +
                1000 * measuringUnit
            "
                fill="none"
                stroke="gray"
                stroke-width="1" />
        </pattern>
      </defs>-->
      <g :transform="scaleStr">
        <rect
          x="0"
          y="0"
          :width="width"
          :height="height"
          :fill="showGrid ? `url('#${gridID}')` : background"
          @click="reset"
        />
        <!-- meassuringUnit-->
        <!--<g v-if="background === '#ffffff'">
        <text
          :x="1100 * measuringUnit"
          :y="20"
          fill="#000000"
          font-family="Meiryo UI, sans-serif"
        >
          1 m
        </text>
        <line
          :x1="0"
          :y1="0"
          :x2="0"
          :y2="100 * measuringUnit"
          style="stroke:rgb(0, 0, 0);stroke-width:2"
        />
        <path
          :d="
            'M 0,' +
              100 * measuringUnit * 0.5 +
              ' h' +
              1000 * measuringUnit
          "
          style="stroke:rgb(0, 0, 0); stroke-width:2;"
        />
        <line
          :x1="1000 * measuringUnit"
          :y1="0"
          :x2="1000 * measuringUnit"
          :y2="100 * measuringUnit"
          style="stroke:rgb(0, 0, 0);stroke-width:2"
        />
      </g>
      <g v-else>
        <text
          :x="1100 * measuringUnit"
          :y="20"
          fill="#ffffff"
          font-family="Meiryo UI, sans-serif"
        >
          1 m
        </text>
        <line
          :x1="0"
          :y1="0"
          :x2="0"
          :y2="100 * measuringUnit"
          style="stroke:rgb(255, 255, 255);stroke-width:2"
        />
        <path
          :d="
            'M 0,' +
              100 * measuringUnit * 0.5 +
              ' h' +
              1000 * measuringUnit
          "
          style="stroke:rgb(255, 255, 255); stroke-width:2;"
        />
        <line
          :x1="1000 * measuringUnit"
          :y1="0"
          :x2="1000 * measuringUnit"
          :y2="100 * measuringUnit"
          style="stroke:rgb(255, 255, 255);stroke-width:2"
        />
      </g>-->

        <Node
          :node="item"
          :selected="item.id === selectedNode"
          v-for="item in nodeList"
          :key="item.id"
          :createLinkMode="createLinkMode"
          :editable="editable"
          :labels="labels"
          :rWidth="rect().rWidth"
          :rHeight="rect().rHeight"
          :scale="scale"
          @editNode="editNode"
          @select="selectNode"
          @copy="copyNode"
          @updateLocation="updateNodeLocation"
          @toggleSelect="toggleSrcSelect"
          @commitDest="commitDest"
          @remove="removeNode"
        />
        <Link
          :link="item"
          v-for="item in linkList"
          :selected="item.id === selectedLink"
          :key="item.id"
          :source="findNode(item.source)"
          :destination="findNode(item.destination)"
          :editable="editable"
          :labels="labels"
          :rWidth="rect().rWidth"
          :rHeight="rect().rHeight"
          :scale="scale"
          :strokeWidth="item.strokeWidth"
          @editLink="editLink"
          @select="selectLink"
          @updateLocation="updateLinkLocation"
          @remove="removeLink"
        />
      </g>
    </svg>
  </div>
</template>

<script>
import { svgMixin } from '@/mixins/svgMixin';
import Node from '@/components/svg/Node';
import Link from '@/components/svg/Link';
export default {
  //name: "Diagram",
  mixins: [svgMixin],
  components: {
    Node,
    Link,
  },
};
</script>
<style>
.button {
  cursor: pointer;
}

.grab {
  cursor: grab;
}
</style>
