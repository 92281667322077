import { render, staticRenderFns } from "./ResultMixtureTable.vue?vue&type=template&id=65f64568&scoped=true"
import script from "./ResultMixtureTable.vue?vue&type=script&lang=js"
export * from "./ResultMixtureTable.vue?vue&type=script&lang=js"
import style0 from "./ResultMixtureTable.vue?vue&type=style&index=0&id=65f64568&prod&scoped=true&lang=css"


/* normalize component */
import normalizer from "!../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "65f64568",
  null
  
)

export default component.exports