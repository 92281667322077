var render = function render(){var _vm=this,_c=_vm._self._c;return _c('g',[_c('path',{attrs:{"d":`M${_vm.calcSource().x} ${_vm.calcSource().y}
      h${_vm.calcConnection().h}v${_vm.calcConnection().v}l${
        _vm.calcConnection().lx
      } ${_vm.calcConnection().ly} l${_vm.calcConnection().lx2} ${
        _vm.calcConnection().ly2
      } l${_vm.calcConnection().lx3} ${_vm.calcConnection().ly3}`,"fill":"none","fill-rule":"evenodd","stroke":_vm.link.color || _vm.destination.content.color || '#bbbbbb',"stroke-width":_vm.strokeWidth,"stroke-linecap":"butt","stroke-linejoin":"miter","stroke-opacity":"1","stroke-dasharray":_vm.definePattern(_vm.link.pattern),"marker-start":_vm.link.arrow === 'src' || _vm.link.arrow === 'both'
        ? `url(#${_vm.link.id})`
        : '',"marker-end":_vm.link.arrow === 'dest' || _vm.link.arrow === 'both'
        ? `url(#${_vm.link.id})`
        : ''}}),_c('marker',{attrs:{"id":_vm.link.id,"markerUnits":"userSpaceOnUse","orient":"auto-start-reverse","markerWidth":_vm.strokeWidth * 3,"markerHeight":_vm.strokeWidth * 3,"viewBox":"0 0 10 10","refX":"5","refY":"5"}},[_c('polygon',{attrs:{"points":"0,1.5 0,8.5 10,5 ","fill":_vm.link.color || _vm.destination.content.color || '#bbbbbb'}})])])
}
var staticRenderFns = []

export { render, staticRenderFns }