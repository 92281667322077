<template>
  <div>
    <v-simple-table dense>
      <thead>
        <tr>
          <th class="text-center" style="min-width:125px"></th>
          <th class="text-center" style="width:125px"></th>
          <th
            style="min-width:150px"
            class="text-center"
            :bgcolor="getColor(mix)"
            v-for="mix in mixtures"
            :key="mix.publicId"
          >
            <font :color="getTextColor(mix)">
              {{ mix.streamId }} | {{ mix.name }}
            </font>
          </th>
        </tr>
      </thead>
      <tbody>
        <tr v-for="(header, index) in rowHeaders" :key="index">
          <td class="text-center">
            {{ header.text }}
          </td>
          <td class="text-center">
            <v-icon
              small
              @click="changeUnit(header)"
              v-if="header.units.length > 1"
            >
              mdi-menu-down
            </v-icon>
            {{ header.toUnit }}
          </td>
          <td
            class="text-center"
            v-for="mix in mixtures"
            :key="mix.publicId"
          >
            {{ convertUnit(mix, header) }}
          </td>
        </tr>
        <tr height="50">
          <th class="text-center">Pollutant (per Flue Gas Wet)</th>
          <th class="text-center"></th>
          <th
            class="text-center"
            v-for="mix in mixtures"
            :key="mix.publicId"
            height="50"
          >
            <v-icon small @click="changeUnit(compositionHeader)">
              mdi-menu-down
            </v-icon>
            {{ compositionHeader.toUnit }}
          </th>
        </tr>
        <tr
          v-for="pollutant in pollutants"
          :key="pollutant.formula + 'wet'"
        >
          <td class="text-center">{{ pollutant.formula }}</td>
          <td class="text-center"></td>
          <td
            class="text-center"
            v-for="mix in mixtures"
            :key="mix.publicId"
          >
            {{
              getFraction(
                mix,
                pollutant,
                compositionHeader.toUnit,
                compositionHeader.precision,
              )
            }}
          </td>
        </tr>
        <tr>
          <td class="text-center">{{ pollutants[0].formula }}</td>
          <td class="text-center">
            <v-numeric-text-field-unit
              v-model="o2Ref"
              :settings="settings.o2"
            />
          </td>
          <td
            class="text-center"
            v-for="mix in mixtures"
            :key="mix.publicId"
          >
            {{
              getFractionO2Ref(
                mix,
                pollutants[0],
                compositionHeader.toUnit,
                compositionHeader.precision,
              )
            }}
          </td>
        </tr>

        <tr height="50">
          <th class="text-center">Pollutant (per Flue Gas Dry)</th>
          <th class="text-center"></th>
          <th
            class="text-center"
            v-for="mix in mixtures"
            :key="mix.publicId"
            height="50"
          >
            <v-icon small @click="changeUnit(compositionHeader)">
              mdi-menu-down
            </v-icon>
            {{ compositionHeader.toUnit }}
          </th>
        </tr>
        <tr
          v-for="pollutant in pollutants"
          :key="pollutant.formula + 'dry'"
        >
          <td class="text-center">{{ pollutant.formula }}</td>
          <td class="text-center"></td>
          <td
            class="text-center"
            v-for="mix in dryMixtures"
            :key="mix.publicId + 'dry'"
          >
            {{
              getFraction(
                mix,
                pollutant,
                compositionHeader.toUnit,
                compositionHeader.precision,
              )
            }}
          </td>
        </tr>
        <tr>
          <td class="text-center">{{ pollutants[0].formula }}</td>
          <td class="text-center">
            <v-numeric-text-field-unit
              v-model="o2Ref"
              :settings="settings.o2"
            />
          </td>
          <td
            class="text-center"
            v-for="mix in dryMixtures"
            :key="mix.publicId + 'dry'"
          >
            {{
              getFractionO2Ref(
                mix,
                pollutants[0],
                compositionHeader.toUnit,
                compositionHeader.precision,
              )
            }}
          </td>
        </tr>
      </tbody>
    </v-simple-table>
    <v-row>
      <v-col cols="12" xl="2"> </v-col>
    </v-row>
  </div>
</template>

<script>
import { mapGetters } from 'vuex';
import _ from 'lodash';
import UnitConverter from '@/plugins/unitconverter';

export default {
  name: 'ResultPollutantsTable',
  components: {},
  props: {
    mixtures: { type: Array, required: true },
  },
  data() {
    return {
      o2Ref: 0.1,
      rowHeaders: [
        {
          text: 'Flow Rate',
          varName: 'massFlow',
          type: UnitConverter.types.FLOWRATE,
          fromUnit: 'kg/s',
          toUnit: 'kg/h',
          precision: 2,
          units: ['kg/h', 'Nm³/h'],
          precisions: [2, 2],
        },
        {
          text: 'Density',
          varName: 'rhoN',
          type: UnitConverter.types.DENSITY,
          fromUnit: 'kg/Nm³',
          toUnit: 'kg/Nm³',
          precision: 3,
          units: ['kg/Nm³'],
          precisions: [3],
        },
        {
          text: 'Temperature',
          varName: 'T',
          type: UnitConverter.types.TEMPERATURE,
          fromUnit: 'K',
          toUnit: '°C',
          precision: 2,
          units: ['K', '°C', '°F'],
          precisions: [2, 2, 2],
        },
      ],
      compositionHeader: {
        name: 'Composition',
        varName: '',
        type: UnitConverter.types.POLLUTANT,
        toUnit: 'mg/Nm³',
        precision: 2,
        units: ['mg/Nm³', 'ppm', '%vol'],
        precisions: [2, 2, 6],
      },
      compositionUnits: Array(this.mixtures.length).fill('%mass'),
      compositionEncoder: {
        '%mass': 'massFraction',
        '%vol': 'volumeFraction',
        '%mol': 'moleFraction',
      },
      pollutants: [{ formula: 'NOx', rhoN: 2.053 }],
      settings: {
        o2: {
          label: 'Reference O2',
          type: UnitConverter.types.UNITLESS,
          min: 0,
          max: 1,
          baseUnit: '',
          unit: '%vol',
          precision: 2,
          units: ['%vol'],
          precisions: [2],
        },
      },
      placeholder: '-',
      dryMixtures: [],
    };
  },
  methods: {
    changeUnit(header) {
      var index = header.units.findIndex(
        unit => unit === header.toUnit,
      );
      if (index == header.units.length - 1) {
        index = -1;
      }
      header.toUnit = header.units[index + 1];
      header.precision = header.precisions[index + 1];
    },
    convertUnit(mix, header) {
      return UnitConverter.convert(
        mix[header.varName],
        header.type,
        header.fromUnit,
        header.toUnit,
        header.precision,
        mix.rhoN,
      );
    },
    getFraction(mix, pollutant, unit, precision) {
      var fraction = mix.pollutants[pollutant.formula].volumeFraction;
      if (fraction === undefined) {
        return this.placeholder;
      } else {
        var value = UnitConverter.convert(
          fraction,
          UnitConverter.types.POLLUTANT,
          '',
          unit,
          precision,
          pollutant.rhoN,
        );
        return value === 0 ? this.placeholder : value;
      }
    },
    getFractionO2Ref(mix, pollutant, unit, precision) {
      var index = mix.species.findIndex(s => s.formula === 'O2');
      var o2 = index === -1 ? 0.0 : mix.species[index].volumeFraction;
      index = this.ambient.wet.species.findIndex(
        s => s.formula === 'O2',
      );
      var o2Amb =
        index === -1
          ? 0.21
          : this.ambient.wet.species[index].volumeFraction;
      var value = this.getFraction(mix, pollutant, unit, precision);
      if (value !== this.placeholder) {
        value *= (o2Amb - this.o2Ref) / (o2Amb - o2);
        value = _.round(value, precision);
      }
      return value;
    },
    getColor(mix) {
      return (
        this.streamTypes[mix.phase].find(
          type => type.name == mix.streamType,
        ).color || ''
      );
    },
    getTextColor(mix) {
      return (
        this.streamTypes[mix.phase].find(
          type => type.name == mix.streamType,
        ).textColor || ''
      );
    },
    createDryMixtures() {
      this.dryMixtures = [];
      this.mixtures.forEach(mix => {
        this.getDryMixture(mix).then(response => {
          this.dryMixtures.push(response.mix);
        });
      });
    },
    getDryMixture(mix) {
      return new Promise((resolve, reject) => {
        var dryMix = JSON.parse(JSON.stringify(mix));
        var index = dryMix.species.findIndex(
          s => s.formula === 'H2O' && s.phase === 'g',
        );
        if (index !== -1) {
          var massFractionH2O = dryMix.species[index].massFraction;
          dryMix.species[index].massFraction = 0.0;
          dryMix.massFlow *= 1 - massFractionH2O;
          dryMix.species.forEach(s => {
            s.massFraction /= 1 - massFractionH2O;
          });
          var inputFraction = 'Mass Fraction';
          this.$store
            .dispatch('mixtures/getMixture', {
              mixture: dryMix,
              inputFraction,
            })
            .then(response => {
              dryMix = response.data.mixture;
              dryMix.T = mix.T;
              dryMix.p = mix.p;
              Object.keys(dryMix.pollutants).forEach(key => {
                var pollutant = dryMix.pollutants[key];
                pollutant.massFraction /= 1 - massFractionH2O;
                pollutant.volumeFraction =
                  (pollutant.massFraction * dryMix.rhoN) /
                  pollutant.rhoN;
                pollutant.moleFraction = pollutant.volumeFraction;
                dryMix.pollutants[key] = pollutant;
              });
              resolve({ mix: dryMix });
            });
        } else {
          resolve({ mix: dryMix });
        }
      });
    },
  },
  computed: {
    ...mapGetters('mixtures', ['streamTypes', 'ambient']),
    ...mapGetters('materials', ['htmlFormula']),
  },
  mounted() {
    // this.createDryMixtures();
  },
  watch: {
    mixtures() {
      this.createDryMixtures();
    },
  },
};
</script>

<style scoped></style>
