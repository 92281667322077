<template>
  <g>
    <g v-if="editable">
      <text
        v-if="selected && node.objectType === 'input'"
        :x="x + calcChangeFlowDirection().x"
        :y="y + calcChangeFlowDirection().y"
        class="button"
        fill="#ff7675"
        @click="changeFlowDirection"
      >
        {{ labels.change || 'Flip' }}
      </text>
    </g>
    <!--burner , burner-constricted-->
    <!--<g v-if="(node.objectType === 'burner') || (node.objectType === 'burner-constricted')">
        <text v-if="selected"
              :x="x + node.length*1/3 + 5"
              :y="y + node.height + 22"
              class="button"
              fill="#00b894"
              @click="editCandidate">
            {{ labels.edit || "Edit" }}
        </text>
        <text v-if="selected"
              :x="x + node.length*1/3 + 5"
              :y="y - 10"
              class="button"
              fill="#00b894"
              stroke="none"
              @click="toggleSelect">
            {{
  !createLinkMode ? labels.link || "Link" : labels.cancel || "Cancel"
            }}
        </text>
        <text v-if="selected"
              :x="x + node.length*1/3 + 100"
              :y="y - 10"
              class="button"
              fill="orange"
              @click="copy">
            {{ labels.copy || "Copy" }}
        </text>
        <text v-if="selected"
              :x="x + node.length*1/3 + 65"
              :y="y + node.height + 22"
              class="button"
              fill="#ff7675"
              @click="remove">
            {{ labels.remove || "Remove" }}
        </text>
        <text v-if="createLinkMode && !selected"
              :x="x + node.length*1/3 + 5"
              :y="y - 10"
              class="button"
              fill="#ff7675"
              @click="commitDest">
            {{ labels.select || "Select" }}
        </text>
    </g>-->
    <ellipse
      v-if="node.objectType === 'ellipse'"
      class="grab"
      :cx="x + node.length / 2"
      :cy="y + node.height / 2"
      :width="node.length"
      :height="node.height"
      :rx="node.length / 2"
      :ry="node.height / 2"
      :fill="node.content.color || '#e6e6e6'"
      :stroke-width="node.strokeWeight"
      :stroke="node.stroke"
      @touchstart="mousedown"
      @mousedown="mousedown"
      @mousemove="mousemove"
      @touchmove="mousemove"
      @mouseup="mouseup"
      @touchend="mouseup"
    />
    <!-- input arrow "left to right"-->
    <g>
      <path
        v-if="
          node.objectType === 'input' &&
            node.flowDirection === 'left to right'
        "
        class="grab"
        :d="
          'm' +
            x +
            ',' +
            y +
            ' h ' +
            (node.length + node.content.fontSize * 0.6) +
            ' l ' +
            node.height +
            ',' +
            node.height / 2 +
            ' ' +
            -node.height +
            ',' +
            node.height / 2 +
            ' h ' +
            -(node.length + node.content.fontSize * 0.6) +
            ' z'
        "
        :x="x"
        :y="y"
        :width="node.length"
        :height="node.height"
        :fill="node.content.color || '#e6e6e6'"
        rx="5"
        ry="3"
        fill-opacity="1"
        stroke="#000000"
        stroke-width="0"
        stroke-linecap="butt"
        stroke-linejoin="miter"
        stroke-miterlimit="4"
        stroke-dasharray="none"
        stroke-opacity="1"
        @touchstart="mousedown"
        @mousedown="mousedown"
        @mousemove="mousemove"
        @touchmove="mousemove"
        @mouseup="mouseup"
        @touchend="mouseup"
      />
      <a target="_blank" :href="node.content.url">
        <text
          v-if="
            node.objectType === 'input' &&
              node.flowDirection === 'left to right'
          "
          :x="x + node.height * 0.2"
          :y="y + node.height * 0.8"
          :fill="node.content.textColor || '#000000'"
          font-family="Meiryo UI, sans-serif"
          :font-size="node.content.fontSize"
          text-anchor="left"
        >
          {{ node.content.text }}
        </text>
      </a>
      <path
        v-if="
          node.objectType === 'input' &&
            node.flowDirection === 'left to right'
        "
        class="grab"
        :d="
          'm' +
            x +
            ',' +
            y +
            ' h ' +
            (node.length + node.content.fontSize * 0.6) +
            ' l ' +
            node.height +
            ',' +
            node.height / 2 +
            ' ' +
            -node.height +
            ',' +
            node.height / 2 +
            ' h ' +
            -(node.length + node.content.fontSize * 0.6) +
            ' z'
        "
        :x="x"
        :y="y"
        :width="node.length"
        :height="node.height"
        :fill="node.content.color || '#e6e6e6'"
        rx="5"
        ry="3"
        fill-opacity="0.1"
        stroke="#000000"
        stroke-width="0"
        stroke-linecap="butt"
        stroke-linejoin="miter"
        stroke-miterlimit="4"
        stroke-dasharray="none"
        stroke-opacity="1"
        @touchstart="mousedown"
        @mousedown="mousedown"
        @mousemove="mousemove"
        @touchmove="mousemove"
        @mouseup="mouseup"
        @touchend="mouseup"
      />
    </g>
    <!-- input arrow "right to left"-->
    <g>
      <path
        v-if="
          node.objectType === 'input' &&
            node.flowDirection === 'right to left'
        "
        class="grab"
        :d="
          'm' +
            (x + calcInput().x) +
            ',' +
            y +
            ' h ' +
            calcAlgebraicSign() *
              (node.length + node.content.fontSize * 0.6) +
            ' l ' +
            calcAlgebraicSign() * node.height +
            ',' +
            node.height / 2 +
            ' ' +
            -calcAlgebraicSign() * node.height +
            ',' +
            node.height / 2 +
            ' h ' +
            -calcAlgebraicSign() *
              (node.length + node.content.fontSize * 0.6) +
            ' z'
        "
        :x="x"
        :y="y"
        :width="node.length"
        :height="node.height"
        :fill="node.content.color || '#e6e6e6'"
        rx="5"
        ry="3"
        fill-opacity="1"
        stroke="#000000"
        stroke-width="0"
        stroke-linecap="butt"
        stroke-linejoin="miter"
        stroke-miterlimit="4"
        stroke-dasharray="none"
        stroke-opacity="1"
        @touchstart="mousedown"
        @mousedown="mousedown"
        @mousemove="mousemove"
        @touchmove="mousemove"
        @mouseup="mouseup"
        @touchend="mouseup"
      />
      <a target="_blank" :href="node.content.url">
        <text
          v-if="
            node.objectType === 'input' &&
              node.flowDirection === 'right to left'
          "
          :x="x + calcInput().textX"
          :y="y + node.height * 0.8"
          :fill="node.content.textColor || '#000000'"
          font-family="Meiryo UI, sans-serif"
          :font-size="node.content.fontSize"
          text-anchor="left"
        >
          {{ node.content.text }}
        </text>
      </a>
      <path
        v-if="
          node.objectType === 'input' &&
            node.flowDirection === 'right to left'
        "
        class="grab"
        :d="
          'm' +
            (x + calcInput().x) +
            ',' +
            y +
            ' h ' +
            calcAlgebraicSign() *
              (node.length + node.content.fontSize * 0.6) +
            ' l ' +
            calcAlgebraicSign() * node.height +
            ',' +
            node.height / 2 +
            ' ' +
            -calcAlgebraicSign() * node.height +
            ',' +
            node.height / 2 +
            ' h ' +
            -calcAlgebraicSign() *
              (node.length + node.content.fontSize * 0.6) +
            ' z'
        "
        :x="x"
        :y="y"
        :width="node.length"
        :height="node.height"
        :fill="node.content.color || '#e6e6e6'"
        rx="5"
        ry="3"
        fill-opacity="0.1"
        stroke="#000000"
        stroke-width="0"
        stroke-linecap="butt"
        stroke-linejoin="miter"
        stroke-miterlimit="4"
        stroke-dasharray="none"
        stroke-opacity="1"
        @touchstart="mousedown"
        @mousedown="mousedown"
        @mousemove="mousemove"
        @touchmove="mousemove"
        @mouseup="mouseup"
        @touchend="mouseup"
      />
    </g>
    <!-- input arrow "bottom to top"-->
    <g>
      <path
        v-if="
          node.objectType === 'input' &&
            node.flowDirection === 'bottom to top'
        "
        class="grab"
        :d="
          'm' +
            (x + calcInput().x) +
            ',' +
            y +
            ' v ' +
            calcAlgebraicSign() *
              (node.length + node.content.fontSize * 0.6) +
            ' l ' +
            node.height / 2 +
            ',' +
            calcAlgebraicSign() * node.height +
            ' ' +
            node.height / 2 +
            ',' +
            -calcAlgebraicSign() * node.height +
            ' v ' +
            -calcAlgebraicSign() *
              (node.length + node.content.fontSize * 0.6) +
            ' z'
        "
        :x="x"
        :y="y"
        :width="node.length"
        :height="node.height"
        :fill="node.content.color || '#e6e6e6'"
        rx="5"
        ry="3"
        fill-opacity="1"
        stroke="#000000"
        stroke-width="0"
        stroke-linecap="butt"
        stroke-linejoin="miter"
        stroke-miterlimit="4"
        stroke-dasharray="none"
        stroke-opacity="1"
        @touchstart="mousedown"
        @mousedown="mousedown"
        @mousemove="mousemove"
        @touchmove="mousemove"
        @mouseup="mouseup"
        @touchend="mouseup"
      />
      <a target="_blank" :href="node.content.url">
        <text
          v-if="
            node.objectType === 'input' &&
              node.flowDirection === 'bottom to top'
          "
          :x="x + calcInput().textX"
          :y="y - this.node.length - this.node.height / 2"
          :fill="node.content.textColor || '#000000'"
          writing-mode="tb"
          direction="ltr"
          font-family="Meiryo UI, sans-serif"
          :font-size="node.content.fontSize"
          text-anchor="left"
        >
          {{ node.content.text }}
        </text>
      </a>
      <path
        v-if="
          node.objectType === 'input' &&
            node.flowDirection === 'bottom to top'
        "
        class="grab"
        :d="
          'm' +
            (x + calcInput().x) +
            ',' +
            y +
            ' v ' +
            calcAlgebraicSign() *
              (node.length + node.content.fontSize * 0.6) +
            ' l ' +
            node.height / 2 +
            ',' +
            calcAlgebraicSign() * node.height +
            ' ' +
            node.height / 2 +
            ',' +
            -calcAlgebraicSign() * node.height +
            ' v ' +
            -calcAlgebraicSign() *
              (node.length + node.content.fontSize * 0.6) +
            ' z'
        "
        :x="x"
        :y="y"
        :width="node.length"
        :height="node.height"
        :fill="node.content.color || '#e6e6e6'"
        rx="5"
        ry="3"
        fill-opacity="0.1"
        stroke="#000000"
        stroke-width="0"
        stroke-linecap="butt"
        stroke-linejoin="miter"
        stroke-miterlimit="4"
        stroke-dasharray="none"
        stroke-opacity="1"
        @touchstart="mousedown"
        @mousedown="mousedown"
        @mousemove="mousemove"
        @touchmove="mousemove"
        @mouseup="mouseup"
        @touchend="mouseup"
      />
    </g>
    <!-- input arrow "top to bottom"-->
    <g>
      <path
        v-if="
          node.objectType === 'input' &&
            node.flowDirection === 'top to bottom'
        "
        class="grab"
        :d="
          'm' +
            x +
            ',' +
            (y -
              calcAlgebraicSign() *
                (node.length + node.content.fontSize * 0.6)) +
            ' v ' +
            calcAlgebraicSign() *
              (node.length + node.content.fontSize * 0.6) +
            ' l ' +
            node.height / 2 +
            ',' +
            calcAlgebraicSign() * node.height +
            ' ' +
            node.height / 2 +
            ',' +
            -calcAlgebraicSign() * node.height +
            ' v ' +
            -calcAlgebraicSign() *
              (node.length + node.content.fontSize * 0.6) +
            ' z'
        "
        :x="x"
        :y="y"
        :width="node.length"
        :height="node.height"
        :fill="node.content.color || '#e6e6e6'"
        rx="5"
        ry="3"
        fill-opacity="1"
        stroke="#000000"
        stroke-width="0"
        stroke-linecap="butt"
        stroke-linejoin="miter"
        stroke-miterlimit="4"
        stroke-dasharray="none"
        stroke-opacity="1"
        @touchstart="mousedown"
        @mousedown="mousedown"
        @mousemove="mousemove"
        @touchmove="mousemove"
        @mouseup="mouseup"
        @touchend="mouseup"
      />
      <a target="_blank" :href="node.content.url">
        <text
          v-if="
            node.objectType === 'input' &&
              node.flowDirection === 'top to bottom'
          "
          :x="x + calcInput().textX"
          :y="y + node.height * 0.2"
          :fill="node.content.textColor || '#000000'"
          writing-mode="tb"
          glyph-orientation-vertical="0"
          direction="rtl"
          font-family="Meiryo UI, sans-serif"
          :font-size="node.content.fontSize"
          text-anchor="left"
        >
          {{ node.content.text }}
        </text>
      </a>
      <path
        v-if="
          node.objectType === 'input' &&
            node.flowDirection === 'top to bottom'
        "
        class="grab"
        :d="
          'm' +
            x +
            ',' +
            (y -
              calcAlgebraicSign() *
                (node.length + node.content.fontSize * 0.6)) +
            ' v ' +
            calcAlgebraicSign() *
              (node.length + node.content.fontSize * 0.6) +
            ' l ' +
            node.height / 2 +
            ',' +
            calcAlgebraicSign() * node.height +
            ' ' +
            node.height / 2 +
            ',' +
            -calcAlgebraicSign() * node.height +
            ' v ' +
            -calcAlgebraicSign() *
              (node.length + node.content.fontSize * 0.6) +
            ' z'
        "
        :x="x"
        :y="y"
        :width="node.length"
        :height="node.height"
        :fill="node.content.color || '#e6e6e6'"
        rx="5"
        ry="3"
        fill-opacity="0.1"
        stroke="#000000"
        stroke-width="0"
        stroke-linecap="butt"
        stroke-linejoin="miter"
        stroke-miterlimit="4"
        stroke-dasharray="none"
        stroke-opacity="1"
        @touchstart="mousedown"
        @mousedown="mousedown"
        @mousemove="mousemove"
        @touchmove="mousemove"
        @mouseup="mouseup"
        @touchend="mouseup"
      />
    </g>
    <!-- rectangle -->
    <g>
      <rect
        v-if="node.objectType === 'rectangle'"
        class="grab"
        :x="x"
        :y="y"
        :width="node.length"
        :height="node.height"
        rx="5"
        ry="3"
        :fill="node.content.color || '#e6e6e6'"
        :stroke-width="node.strokeWeight"
        :stroke="node.stroke"
        @touchstart="mousedown"
        @mousedown="mousedown"
        @mousemove="mousemove"
        @touchmove="mousemove"
        @mouseup="mouseup"
        @touchend="mouseup"
      />
      <a target="_blank" :href="node.content.url">
        <text
          v-if="node.objectType === 'rectangle'"
          :x="x + node.length / 2"
          :y="y + node.height / 2"
          fill="#ffffff"
          font-family="Meiryo UI, sans-serif"
          :font-size="node.content.fontSize"
          text-anchor="middle"
        >
          {{ node.content.text }}
        </text>
      </a>
    </g>
    <!-- burner , burner-constricted -->
    <g
      v-if="
        node.objectType === 'burner' ||
          node.objectType === 'burner-constricted'
      "
    >
      <!-- Horizontal -->
      <g v-if="node.orientation === 'horizontal'">
        <!-- chamber horizontal -->
        <g>
          <linearGradient
            :id="gradientHorizontalID"
            x1="0%"
            y1="0%"
            x2="0%"
            y2="100%"
          >
            <stop offset="0%" style="stop-color:#303030" />
            <stop offset="60%" style="stop-color:#e6e6e6" />
            <stop offset="100%" style="stop-color:#666" />
          </linearGradient>
          <!--<linearGradient
          v-if="
            node.objectType === 'burner' ||
              node.objectType === 'burner-constricted'
          "
          :id="gradientHorizontalTemperatureID"
        >
          <stop
            v-for="(stop, idx) in node.stops"
            :key="idx"
            :offset="stop.x"
            :stop-color="getColor(stop.T)"
          />
        </linearGradient>-->
          <rect
            v-if="
              node.objectType === 'burner' ||
                node.objectType === 'burner-constricted'
            "
            class="grab"
            :x="x + calcBurner().h"
            :y="y + (node.height * 1) / 6"
            :width="(node.height * 1) / 3"
            :height="(node.height * 4) / 6"
            rx="5"
            ry="3"
            fill="#e6e6e6"
            stroke="#000000"
            stroke-width="1"
            @touchstart="mousedown"
            @mousedown="mousedown"
            @mousemove="mousemove"
            @touchmove="mousemove"
            @mouseup="mouseup"
            @touchend="mouseup"
          />
          <rect
            v-if="
              node.objectType === 'burner' ||
                node.objectType === 'burner-constricted'
            "
            class="grab"
            :x="x + calcChamber().h"
            :y="y"
            :width="node.length"
            :height="node.height"
            rx="5"
            ry="3"
            :fill="`url('#${gradientHorizontalID}')`"
            stroke="#000000"
            stroke-width="2"
            @touchstart="mousedown"
            @mousedown="mousedown"
            @mousemove="mousemove"
            @touchmove="mousemove"
            @mouseup="mouseup"
            @touchend="mouseup"
          />
          <rect
            v-if="node.objectType === 'burner-constricted'"
            class="grab"
            :x="x + (node.height * 1) / 3 + node.length * 0.75"
            :y="y"
            :width="node.height * 0.1"
            :height="(node.height * 1) / 3"
            style="fill:#000000;"
            @touchstart="mousedown"
            @mousedown="mousedown"
            @mousemove="mousemove"
            @touchmove="mousemove"
            @mouseup="mouseup"
            @touchend="mouseup"
          />
          <rect
            v-if="node.objectType === 'burner-constricted'"
            class="grab"
            :x="x + (node.height * 1) / 3 + node.length * 0.75"
            :y="y + (node.height * 2) / 3"
            :width="node.height * 0.1"
            :height="(node.height * 1) / 3"
            style="fill:#000000;stroke:#000000;stroke-width:1"
            @touchstart="mousedown"
            @mousedown="mousedown"
            @mousemove="mousemove"
            @touchmove="mousemove"
            @mouseup="mouseup"
            @touchend="mouseup"
          />
        </g>
        <!-- Flame horizontal -->
        <g v-if="node.flame">
          <path
            v-if="
              node.objectType === 'burner' ||
                node.objectType === 'burner-constricted'
            "
            style="fill:#ff0000;fill-opacity:1;stroke:none"
            class="grab"
            :x="x"
            :y="y"
            :d="
              'm' +
                (x + calcFlame().h + (node.height * 1) / 3) +
                ',' +
                (y + node.height * 0.5) +
                ' c ' +
                '0,0 ' +
                +0 +
                ',' +
                (calcAlgebraicSign() * node.width) / 2 +
                ' ' +
                (+calcAlgebraicSign() * node.length * 2) / 3 +
                ',0 ' +
                '0,0 ' +
                (+-calcAlgebraicSign() * node.length * 2) / 3 +
                ',' +
                (-calcAlgebraicSign() * node.width) / 2 +
                ' ' +
                (+-calcAlgebraicSign() * node.length * 2) / 3 +
                ',0 ' +
                ' z'
            "
            @touchstart="mousedown"
            @mousedown="mousedown"
            @mousemove="mousemove"
            @touchmove="mousemove"
            @mouseup="mouseup"
            @touchend="mouseup"
          />
          <path
            v-if="
              node.objectType === 'burner' ||
                node.objectType === 'burner-constricted'
            "
            style="fill:#ff6600;fill-opacity:1;stroke:none"
            class="grab"
            :x="x"
            :y="y"
            :d="
              'm' +
                (x + calcFlame().h + (node.height * 1) / 3) +
                ',' +
                (y + node.height * 0.5) +
                ' c ' +
                '0,0 ' +
                +0 +
                ',' +
                ((calcAlgebraicSign() * node.width) / 2) * 0.8 +
                ' ' +
                ((+calcAlgebraicSign() * node.length * 2) / 3) * 0.8 +
                ',0 ' +
                '0,0 ' +
                ((+-calcAlgebraicSign() * node.length * 2) / 3) *
                  0.8 +
                ',' +
                ((-calcAlgebraicSign() * node.width) / 2) * 0.8 +
                ' ' +
                ((+-calcAlgebraicSign() * node.length * 2) / 3) *
                  0.8 +
                ',0 ' +
                ' z'
            "
            @touchstart="mousedown"
            @mousedown="mousedown"
            @mousemove="mousemove"
            @touchmove="mousemove"
            @mouseup="mouseup"
            @touchend="mouseup"
          />
          <path
            v-if="
              node.objectType === 'burner' ||
                node.objectType === 'burner-constricted'
            "
            style="fill:#ffff00;stroke:none;stroke-opacity:1"
            class="grab"
            :x="x"
            :y="y"
            :d="
              'm' +
                (x + calcFlame().h + (node.height * 1) / 3) +
                ',' +
                (y + node.height * 0.5) +
                ' c ' +
                '0,0 ' +
                +0 +
                ',' +
                ((calcAlgebraicSign() * node.width) / 2) * 0.6 +
                ' ' +
                ((+calcAlgebraicSign() * node.length * 2) / 3) * 0.6 +
                ',0 ' +
                '0,0 ' +
                ((+-calcAlgebraicSign() * node.length * 2) / 3) *
                  0.6 +
                ',' +
                ((-calcAlgebraicSign() * node.width) / 2) * 0.6 +
                ' ' +
                ((+-calcAlgebraicSign() * node.length * 2) / 3) *
                  0.6 +
                ',0 ' +
                ' z'
            "
            @touchstart="mousedown"
            @mousedown="mousedown"
            @mousemove="mousemove"
            @touchmove="mousemove"
            @mouseup="mouseup"
            @touchend="mouseup"
          />
        </g>
      </g>
      <!-- Vertical -->
      <g v-else-if="node.orientation === 'vertical'">
        <!-- chamber vertical -->
        <g>
          <linearGradient
            :id="gradientVerticalID"
            x1="0%"
            y1="0%"
            x2="100%"
            y2="0%"
          >
            <stop offset="0%" style="stop-color:#303030" />
            <stop offset="60%" style="stop-color:#e6e6e6" />
            <stop offset="100%" style="stop-color:#666" />
          </linearGradient>
          <!--<linearGradient
          v-if="
            node.objectType === 'burner' ||
              node.objectType === 'burner-constricted'
          "
          :id="gradientVerticalTemperatureID"
          gradientTransform="rotate(90)"
        >
          <stop
            v-for="(stop, idx) in node.stops"
            :key="idx"
            :offset="stop.x"
            :stop-color="getColor(stop.T)"
          />
        </linearGradient>-->
          <rect
            v-if="
              node.objectType === 'burner' ||
                node.objectType === 'burner-constricted'
            "
            class="grab"
            :x="x + (node.height * 1) / 6"
            :y="y + calcBurner().v"
            :width="(node.height * 4) / 6"
            :height="(node.height * 1) / 3"
            rx="5"
            ry="3"
            fill="#e6e6e6"
            stroke="#000000"
            stroke-width="1"
            @touchstart="mousedown"
            @mousedown="mousedown"
            @mousemove="mousemove"
            @touchmove="mousemove"
            @mouseup="mouseup"
            @touchend="mouseup"
          />
          <rect
            v-if="
              node.objectType === 'burner' ||
                node.objectType === 'burner-constricted'
            "
            class="grab"
            :x="x"
            :y="y + calcChamber().v"
            :width="node.height"
            :height="node.length"
            rx="5"
            ry="3"
            :fill="`url('#${gradientVerticalID}')`"
            stroke="#000000"
            stroke-width="2"
            @touchstart="mousedown"
            @mousedown="mousedown"
            @mousemove="mousemove"
            @touchmove="mousemove"
            @mouseup="mouseup"
            @touchend="mouseup"
          />
          <rect
            v-if="node.objectType === 'burner-constricted'"
            class="grab"
            :x="x"
            :y="y + (node.height * 1) / 3 + node.length * 0.75"
            :width="(node.height * 1) / 3"
            :height="node.height * 0.1"
            style="fill:#000000;"
            @touchstart="mousedown"
            @mousedown="mousedown"
            @mousemove="mousemove"
            @touchmove="mousemove"
            @mouseup="mouseup"
            @touchend="mouseup"
          />
          <rect
            v-if="node.objectType === 'burner-constricted'"
            class="grab"
            :x="x + (node.height * 2) / 3"
            :y="y + (node.height * 1) / 3 + node.length * 0.75"
            :width="(node.height * 1) / 3"
            :height="node.height * 0.1"
            style="fill:#000000;stroke:#000000;stroke-width:1"
            @touchstart="mousedown"
            @mousedown="mousedown"
            @mousemove="mousemove"
            @touchmove="mousemove"
            @mouseup="mouseup"
            @touchend="mouseup"
          />
        </g>
        <!-- Flame vertical -->
        <g v-if="node.flame">
          <path
            v-if="
              node.objectType === 'burner' ||
                node.objectType === 'burner-constricted'
            "
            style="fill:#ff0000;fill-opacity:1;stroke:none"
            class="grab"
            :x="x"
            :y="y"
            :d="
              'm' +
                (x + node.height * 0.5) +
                ',' +
                (y + calcFlame().v + (node.height * 1) / 3) +
                ' c ' +
                '0,0 ' +
                (+calcAlgebraicSign() * node.width) / 2 +
                ',' +
                0 +
                ' ' +
                +0 +
                ',' +
                (calcAlgebraicSign() * node.length * 2) / 3 +
                ' ' +
                '0,0 ' +
                (+-calcAlgebraicSign() * node.width) / 2 +
                ',' +
                (-calcAlgebraicSign() * node.length * 2) / 3 +
                ' ' +
                +0 +
                ',' +
                (-calcAlgebraicSign() * node.length * 2) / 3 +
                ' ' +
                ' z'
            "
            @touchstart="mousedown"
            @mousedown="mousedown"
            @mousemove="mousemove"
            @touchmove="mousemove"
            @mouseup="mouseup"
            @touchend="mouseup"
          />
          <path
            v-if="
              node.objectType === 'burner' ||
                node.objectType === 'burner-constricted'
            "
            style="fill:#ff6600;fill-opacity:1;stroke:none"
            class="grab"
            :x="x"
            :y="y"
            :d="
              'm' +
                (x + node.height * 0.5) +
                ',' +
                (y + calcFlame().v + (node.height * 1) / 3) +
                ' c ' +
                '0,0 ' +
                ((+calcAlgebraicSign() * node.width) / 2) * 0.8 +
                ',' +
                0 +
                ' ' +
                +0 +
                ',' +
                ((calcAlgebraicSign() * node.length * 2) / 3) * 0.8 +
                ' ' +
                '0,0 ' +
                ((+-calcAlgebraicSign() * node.width) / 2) * 0.8 +
                ',' +
                ((-calcAlgebraicSign() * node.length * 2) / 3) * 0.8 +
                ' ' +
                +0 +
                ',' +
                ((-calcAlgebraicSign() * node.length * 2) / 3) * 0.8 +
                ' ' +
                ' z'
            "
            @touchstart="mousedown"
            @mousedown="mousedown"
            @mousemove="mousemove"
            @touchmove="mousemove"
            @mouseup="mouseup"
            @touchend="mouseup"
          />
          <path
            v-if="
              node.objectType === 'burner' ||
                node.objectType === 'burner-constricted'
            "
            style="fill:#ffff00;stroke:none;stroke-opacity:1"
            class="grab"
            :x="x"
            :y="y"
            :d="
              'm' +
                (x + node.height * 0.5) +
                ',' +
                (y + calcFlame().v + (node.height * 1) / 3) +
                ' c ' +
                '0,0 ' +
                ((+calcAlgebraicSign() * node.width) / 2) * 0.6 +
                ',' +
                0 +
                ' ' +
                +0 +
                ',' +
                ((calcAlgebraicSign() * node.length * 2) / 3) * 0.6 +
                ' ' +
                '0,0 ' +
                ((+-calcAlgebraicSign() * node.width) / 2) * 0.6 +
                ',' +
                ((-calcAlgebraicSign() * node.length * 2) / 3) * 0.6 +
                ' ' +
                +0 +
                ',' +
                ((-calcAlgebraicSign() * node.length * 2) / 3) * 0.6 +
                ' ' +
                ' z'
            "
            @touchstart="mousedown"
            @mousedown="mousedown"
            @mousemove="mousemove"
            @touchmove="mousemove"
            @mouseup="mouseup"
            @touchend="mouseup"
          />
        </g>
      </g>
    </g>
  </g>
</template>
<script>
import { nodeMixin } from '@/mixins/nodeMixin';
export default {
  mixins: [nodeMixin],
};
</script>
<style lang="scss" scoped>
.shadow {
  filter: drop-shadow(1px 1px 3px rgba(0, 0, 0, 0.3));
  -webkit-filter: drop-shadow(1px 1px 3px rgba(0, 0, 0, 0.3));
  -moz-filter: drop-shadow(1px 1px 3px rgba(0, 0, 0, 0.3));
}

.button {
  cursor: pointer;
}
</style>
