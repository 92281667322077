<template>
  <div>
    <v-btn @click="CreateExcel" class="mr-3">
      Download Excel
    </v-btn>
    <v-btn-toggle v-model="unit" class="ml-3" rounded dense>
      <v-btn v-for="unit in units" :key="unit">
        {{ unit }}
      </v-btn>
    </v-btn-toggle>
  </div>
</template>

<script>
import * as Svg from 'save-svg-as-png';

export default {
  name: 'ResultReportExcel',
  components: {},
  props: { project: null },
  data() {
    return {
      chosenFile: null,
      unit: 2,
      units: ['%mass', '%mol', '%vol'],
    };
  },
  methods: {
    CreateExcel() {
      this.$store.dispatch('misc/setLoading', true);
      var options = {
        encoderOptions: 1.0, // image quality
      };
      Svg.svgAsPngUri(
        document.getElementById('svg-diagram'),
        options,
      ).then(uri => {
        const obj = {
          filename: this.$store.getters[
            'projects/currentProjectFileName'
          ],
          project: this.$store.getters[
            'projects/assembleProjectForSolver'
          ](this.project),
          unit: this.units[this.unit],
          diagramUri: uri,
        };
        this.$store
          .dispatch('projects/createExcelFile', obj)
          .catch(error => {
            console.log(error);
            this.$store.dispatch('misc/setSnack', {
              color: 'error',
              text: 'Error while creating Excel Report',
            });
          })
          .finally(() => {
            this.$store.dispatch('misc/setLoading', false);
          });
      });
    },
  },
  computed: {},
  mounted() {},
};
</script>

<style scoped>
/* table th + th {
    border-left: 1px solid #723434;
  }
  table td + td {
    border-left: 1px solid #7a2b2b;
  } */
</style>
