import { mapGetters } from 'vuex';
import _ from 'lodash';
export const diagramMixin = {
  data() {
    return {
      chosenFile: null,
      dialogDataFormat: '',
      dialogFileName: '',
      dialogDownload: false,
      dialogImport: false,
      dialogExport: false,
      json: '',
      isImportActive: false,
      isExportActive: false,
      maxID: 0,
      maxInlet: 0,
      operators: [],
      operands: [],
      inlet: [],
      editable: false,
      settings: {
        isFluid: true,
        scale: 1,
        showGrid: false,
        trueToScale: false,
        style: { size: 16, f: 'Comic Sans' },
        spacing: Number,
      },
      diagramData: {
        width: window.screen.availWidth,
        height: window.screen.availHeight,
        background: '#ffffff',
        nodes: [],
        links: [],
        measuringUnit: 1,
      },
    };
  },
  methods: {
    onEditNode() {},
    onEditLink() {},
    nodeChanged() {},
    linkChanged() {},
    calcMeasuringUnit(checkValueH, checkValueV) {
      var factor;
      //width
      var i = 10;
      while (i < 100000) {
        if (this.diagramData.width % i == this.diagramData.width) {
          i = i / 10;
          break;
        }
        i = i * 10;
      }
      var widthValue = i;
      var widthUnit = this.diagramData.width / widthValue;

      factor = 2;
      while (checkValueH * widthUnit > this.diagramData.width) {
        //console.log("(checkValueH * widthUnit) > (this.diagramData.width ): " + (checkValueH * widthUnit) + '>' + (this.diagramData.width ) );
        widthUnit = this.diagramData.width / (widthValue * factor);
        ++factor;
      }

      //height
      i = 10;
      while (i < 100000) {
        if (this.diagramData.height % i == this.diagramData.height) {
          i = i / 10;
          break;
        }
        i = i * 10;
      }
      var heightValue = i;
      var heightUnit = this.diagramData.height / heightValue;

      factor = 2;
      while (checkValueV * heightUnit > this.diagramData.height) {
        //console.log("(checkValueV * heightUnit) > (this.diagramData.height): " + (checkValueV * heightUnit) + '>' + (this.diagramData.height));
        heightUnit = this.diagramData.height / (heightValue * factor);
        ++factor;
      }

      //console.log(heightUnit + " " + widthUnit)
      if (heightUnit < widthUnit) {
        return heightUnit;
      } else {
        return widthUnit;
      }
    },
    convertLength(length, toUnit, fromUnit = 'm', round = true) {
      // convert length from fromUnit to meter
      switch (fromUnit) {
        case 'cm':
          length /= 100;
          break;
        case 'mm':
          length /= 1000;
          break;
      }
      // convert length from meter to toUnit
      switch (toUnit) {
        case 'cm':
          length *= 100;
          return round ? _.round(length, 3) : length;
        case 'mm':
          length *= 1000;
          return round ? _.round(length, 2) : length;
        default:
          return round ? _.round(length, 5) : length;
      }
    },
  },
  computed: {
    ...mapGetters(['currentUser']),
    ...mapGetters('mixtures', ['streamTypes']),
  },
};
