<template>
  <v-container fluid class="my-4">
    <v-card color="yellow" v-if="!currentProject.info.upToDate">
      <v-card-title>
        Warning, the project was modified and the results are not up
        to date. Please calculate the solution again!</v-card-title
      >
    </v-card>

    <v-expansion-panels v-model="panels" multiple>
      <v-expansion-panel>
        <v-expansion-panel-header>
          <h2>Report Export</h2>
        </v-expansion-panel-header>
        <v-expansion-panel-content>
          <ResultReportExcel class="mb-2" :project="currentProject" />

          <ResultReportPDF
            class="mt-4"
            :project="currentProject"
            :mixtures="uniqueMixtures"
            :combustionChambers="combustionChambers"
          />
        </v-expansion-panel-content>
      </v-expansion-panel>
      <v-expansion-panel>
        <v-expansion-panel-header>
          <h2>Flow Chart</h2>
        </v-expansion-panel-header>
        <v-expansion-panel-content>
          <PlantDiagram
            :projectDiagram="currentProject.diagram"
            :combustionChambers="combustionChambers"
            @updateProjectDiagram="updateProjectDiagram"
          />
        </v-expansion-panel-content>
      </v-expansion-panel>
      <v-expansion-panel>
        <v-expansion-panel-header>
          <h2>Mixtures</h2>
        </v-expansion-panel-header>
        <v-expansion-panel-content>
          <div ref="content">
            <ResultMixtureTable
              id="result-mixture-table"
              :mixtures="uniqueMixtures"
              v-if="uniqueMixtures.length > 0"
            />
            <p v-else>No results for mixtures yet</p>
          </div>
        </v-expansion-panel-content>
      </v-expansion-panel>
      <v-expansion-panel>
        <v-expansion-panel-header>
          <h2>Pollutants</h2>
        </v-expansion-panel-header>
        <v-expansion-panel-content>
          <v-card class="mb-4 pb-4">
            <ResultPollutantsTable :mixtures="flueGases" />
          </v-card>
        </v-expansion-panel-content>
      </v-expansion-panel>

      <v-expansion-panel>
        <v-expansion-panel-header>
          <h2>Combustion Chambers</h2>
        </v-expansion-panel-header>
        <v-expansion-panel-content>
          <v-card class="mb-4 pb-4">
            <ResultCombustionChamberTable
              :combustionChambers="combustionChambers"
            />
          </v-card>
          <v-expansion-panels
            multiple
            v-if="combustionChambers.length !== 0"
          >
            <v-expansion-panel
              v-for="chamber in combustionChambers"
              :key="chamber.publicId"
            >
              <v-expansion-panel-header>
                <h3>{{ chamber.name }}</h3>
              </v-expansion-panel-header>
              <v-expansion-panel-content>
                <v-expansion-panels multiple accordion>
                  <v-expansion-panel>
                    <v-expansion-panel-header>
                      Inlets
                    </v-expansion-panel-header>
                    <v-expansion-panel-content>
                      <ResultMixtureTable
                        :mixtures="chamber.inletMixtures"
                      />
                    </v-expansion-panel-content>
                  </v-expansion-panel>
                  <v-expansion-panel>
                    <v-expansion-panel-header>
                      Outlets
                    </v-expansion-panel-header>
                    <v-expansion-panel-content>
                      <ResultMixtureTable
                        :mixtures="chamber.outletMixtures"
                      />
                    </v-expansion-panel-content>
                  </v-expansion-panel>
                </v-expansion-panels>
              </v-expansion-panel-content>
            </v-expansion-panel>
          </v-expansion-panels>
          <p v-else>No Results for Combustion Chambers yet</p>
        </v-expansion-panel-content>
      </v-expansion-panel>
    </v-expansion-panels>
  </v-container>
</template>

<script>
import { mapGetters, mapActions } from 'vuex';
import PlantDiagram from '@/components/combustionchambers/PlantDiagram';
import ResultMixtureTable from '@/components/results/ResultMixtureTable';
import ResultPollutantsTable from '@/components/results/ResultPollutantsTable';
import ResultCombustionChamberTable from '@/components/results/ResultCombustionChamberTable';
import ResultReportPDF from '@/components/results/ResultReportPDF';
import ResultReportExcel from '@/components/results/ResultReportExcel';

export default {
  name: 'Results',
  data() {
    return {
      panels: [0, 1, 2, 3, 4],
    };
  },
  components: {
    PlantDiagram,
    ResultMixtureTable,
    ResultPollutantsTable,
    ResultCombustionChamberTable,
    ResultReportPDF,
    ResultReportExcel,
  },
  methods: {
    ...mapActions('projects', ['updateProjectDiagram']),
  },
  computed: {
    ...mapGetters('projects', ['currentProject']),
    ...mapGetters('combustionChambers', [
      'combustionChambers',
      'uniqueMixtures',
      'flueGases',
    ]),
    // ...mapGetters('mixtures', ['mixtures', 'ambient']),
  },

  created() {},
};
</script>

<style></style>
