<template>
  <div>
    <v-simple-table dense>
      <thead>
        <tr>
          <th class="text-center" style="min-width:125px"></th>
          <th class="text-center" style="min-width:100px"></th>
          <th
            style="min-width:150px"
            class="text-center"
            :bgcolor="getColor(mix)"
            v-for="mix in mixtures"
            :key="mix.publicId"
          >
            <font :color="getTextColor(mix)">
              {{ mix.streamId }} | {{ mix.name }}
            </font>
          </th>
        </tr>
      </thead>
      <tbody>
        <tr v-for="(header, index) in rowHeaders" :key="index">
          <td class="text-center">
            {{ header.text }}
          </td>
          <td class="text-center">
            <v-icon
              small
              @click="changeUnit(header)"
              v-if="header.units.length > 1"
            >
              mdi-menu-down
            </v-icon>
            {{ header.toUnit }}
          </td>
          <td
            class="text-center"
            v-for="mix in mixtures"
            :key="mix.publicId"
          >
            {{ convertUnit(mix, header) }}
          </td>
        </tr>
        <tr height="50">
          <th class="text-center">Composition</th>
          <th class="text-center">Phase</th>
          <th
            class="text-center"
            v-for="mix in mixtures"
            :key="mix.publicId"
            height="50"
          >
            <v-icon small @click="changeUnit(compositionHeader)">
              mdi-menu-down
            </v-icon>
            {{ compositionHeader.toUnit }}
          </th>
        </tr>
        <tr v-for="species in uniqueSpecies" :key="species.publicId">
          <td
            class="text-center"
            v-html="htmlFormula(species.formula)"
          ></td>
          <td class="text-center">{{ species.phase }}</td>
          <td
            class="text-center"
            v-for="mix in mixtures"
            :key="mix.publicId"
          >
            {{
              getFraction(
                mix,
                species,
                compositionHeader.toUnit,
                compositionHeader.precision,
              )
            }}
          </td>
        </tr>
      </tbody>
    </v-simple-table>
  </div>
</template>

<script>
import { mapGetters } from 'vuex';
import _ from 'lodash';
import UnitConverter from '@/plugins/unitconverter';

export default {
  name: 'ResultMixtureTable',
  components: {},
  props: {
    mixtures: { type: Array, required: true },
  },
  data() {
    return {
      rowHeaders: [
        {
          text: 'Flow Rate',
          varName: 'massFlow',
          type: UnitConverter.types.FLOWRATE,
          fromUnit: 'kg/s',
          toUnit: 'kg/h',
          precision: 2,
          units: ['kg/h', 'Nm³/h'],
          precisions: [2, 2],
        },
        {
          text: 'Density',
          varName: 'rhoN',
          type: UnitConverter.types.DENSITY,
          fromUnit: 'kg/Nm³',
          toUnit: 'kg/Nm³',
          precision: 3,
          units: ['kg/Nm³'],
          precisions: [3],
        },
        {
          text: 'Temperature',
          varName: 'T',
          type: UnitConverter.types.TEMPERATURE,
          fromUnit: 'K',
          toUnit: '°C',
          precision: 2,
          units: ['K', '°C', '°F'],
          precisions: [2, 2, 2],
        },
        {
          text: 'LHV',
          varName: 'LHVNist',
          type: UnitConverter.types.ENTHALPY,
          fromUnit: 'J/kg',
          toUnit: 'kJ/kg',
          precision: 0,
          units: [
            'kJ/kg',
            'MJ/kg',
            'kWh/kg',
            'kcal/kg',
            'kJ/Nm³',
            'MJ/Nm³',
            'kWh/Nm³',
            'kcal/Nm³',
          ],
          precisions: [0, 3, 2, 0, 0, 3, 3, 0],
        },
        {
          text: 'Thermal Output',
          varName: 'power',
          type: UnitConverter.types.POWER,
          fromUnit: 'W',
          toUnit: 'kW',
          precision: 2,
          units: ['kW', 'MW'],
          precisions: [2, 3],
        },
      ],
      compositionHeader: {
        name: 'Composition',
        varName: '',
        toUnit: '%vol',
        precision: 2,
        units: ['%mass', '%vol', '%mol'],
        precisions: [2, 2, 2],
      },
      compositionEncoder: {
        '%mass': 'massFraction',
        '%vol': 'volumeFraction',
        '%mol': 'moleFraction',
      },
    };
  },
  methods: {
    changeUnit(header) {
      var index = header.units.findIndex(
        unit => unit === header.toUnit,
      );
      if (index == header.units.length - 1) {
        index = -1;
      }
      header.toUnit = header.units[index + 1];
      header.precision = header.precisions[index + 1];
    },
    convertUnit(mix, header) {
      return UnitConverter.convert(
        mix[header.varName],
        header.type,
        header.fromUnit,
        header.toUnit,
        header.precision,
        mix.rhoN,
      );
    },
    getFraction(mix, species, unit, precision) {
      var placeholder = '-';
      var index = mix.species.findIndex(
        s => s.publicId === species.publicId,
      );
      if (index === -1) {
        return placeholder;
      } else {
        var value = _.round(
          mix.species[index][this.compositionEncoder[unit]] * 100,
          precision,
        );
        return value === 0 ? placeholder : value;
      }
    },
    getColor(mix) {
      return (
        this.streamTypes[mix.phase].find(
          type => type.name == mix.streamType,
        ).color || ''
      );
    },
    getTextColor(mix) {
      return (
        this.streamTypes[mix.phase].find(
          type => type.name == mix.streamType,
        ).textColor || ''
      );
    },
  },
  computed: {
    ...mapGetters('materials', ['htmlFormula']),
    ...mapGetters('mixtures', ['streamTypes']),
    uniqueSpecies() {
      var uniqueSpecies = [];
      this.mixtures.forEach(mix => {
        mix.species.forEach(species => {
          if (
            uniqueSpecies.filter(s => s.publicId === species.publicId)
              .length === 0
          ) {
            uniqueSpecies.push(species);
          }
        });
      });
      var uniqueSpeciesAlphabeticOrder = uniqueSpecies.sort((a, b) =>
        a.formula > b.formula ? 1 : b.formula > a.formula ? -1 : 0,
      );
      return uniqueSpeciesAlphabeticOrder;
    },
  },
  mounted() {},
};
</script>

<style scoped>
/* table th + th {
    border-left: 1px solid #723434;
  }
  table td + td {
    border-left: 1px solid #7a2b2b;
  } */
</style>
