import { v4 as uuidv4 } from 'uuid';
export const svgMixin = {
  props: {
    width: Number,
    height: Number,
    scale: {
      type: Number,
      default: 1,
    },
    background: String,
    nodes: Array,
    links: Array,
    showGrid: Boolean,
    editable: Boolean,
    labels: Object,
    fluid: {
      type: Boolean,
      default: false,
    },
    measuringUnit: Number,
  },
  data() {
    return {
      gridID: uuidv4(),
      smallGridID: uuidv4(),
      name: '',
      url: '',
      color: '',
      selectedNode: -1,
      selectedLink: -1,
      createLinkMode: false,
    };
  },
  methods: {
    editNode(item) {
      this.$emit('editNode', item);
    },
    editLink(item) {
      this.$emit('editLink', item);
    },
    generateID() {
      return (
        new Date().getTime().toString(16) +
        Math.floor(Math.random() * 1000000).toString(16)
      );
    },
    addNode() {
      if (!this.editable) return;
      this.nodeList.push({
        id: this.generateID(),
        content: {
          text: this.name,
          color: this.color,
          url: this.url,
        },
        width: 200,
        height: 60,
        point: {
          x: 10,
          y: 100 + Math.random() * 100,
        },
      });
    },
    reset() {
      console.log('reset');
      this.selectedNode = -1;
      this.selectedLink = -1;
    },
    updateLinkLocation(obj) {
      let item = this.linkList.find(x => x.id === obj.id);
      item.point.x = obj.x;
      item.point.y = obj.y;
    },
    findNode(id) {
      return this.nodes.find(x => x.id === id);
    },
    removeLink(id) {
      this.linkList = this.linkList.filter(x => x.id !== id);
    },
    rect() {
      if (this.fluid) {
        const rect = this.$refs.field.getBoundingClientRect();
        return {
          rWidth: rect.width / this.width,
          rHeight: rect.height / this.height,
        };
      } else {
        return {
          rWidth: 1,
          rHeight: 1,
        };
      }
    },
    updateNodeLocation(obj) {
      let item = this.nodeList.find(x => x.id === obj.id);
      item.point.x = obj.x;
      item.point.y = obj.y;
    },
    selectNode(id) {
      this.selectedNode = id;
    },
    selectLink(id) {
      this.selectedLink = id;
    },
    toggleSrcSelect() {
      this.createLinkMode = !this.createLinkMode;
    },
    commitDest(id) {
      let src = this.nodeList.find(x => x.id === this.selectedNode);
      let dest = this.nodeList.find(x => x.id === id);
      this.linkList.push({
        id: this.generateID(),
        source: this.selectedNode,
        destination: id,
        point: {
          x: (src.point.x + dest.point.x) * 0.5,
          y: (src.point.y + dest.point.y) * 0.5,
        },
      });
      this.createLinkMode = false;
      this.selectedNode = -1;
    },
    removeNode(id) {
      const nodes = this.nodeList.filter(x => x.id !== id);
      this.nodeList = nodes;
      const links = this.linkList.filter(x => {
        return x.source !== id && x.destination !== id;
      });
      this.linkList = links;
      this.createLinkMode = false;
    },
    copyNode(node) {
      if (!this.editable) return;
      this.nodeList.push({
        id: this.generateID(),
        content: {
          text: node.content.text,
          color: node.content.color,
          url: node.content.url,
        },
        width: node.width,
        height: node.height,
        point: {
          x: node.point.x + 30,
          y: node.point.y + 30,
        },
        shape: node.shape,
      });
    },
  },
  computed: {
    viewBoxDiagram() {
      return this.fluid
        ? `0 0 ${this.width / this.scale} ${this.height / this.scale}`
        : `0 0 ${this.width} ${this.height}`;
    },
    scaleStr() {
      return (
        'scale(' +
        (this.fluid ? 1.0 : this.scale || 1.0) +
        ')' +
        'translate(' +
        0 +
        ',' +
        0 +
        ')'
      );
    },
    nodeList: {
      get() {
        return this.nodes;
      },
      set(val) {
        this.$emit('nodeChanged', {
          nodes: val,
        });
      },
    },
    linkList: {
      get() {
        return this.links;
      },
      set(val) {
        this.$emit('linkChanged', {
          links: val,
        });
      },
    },
  },
};
