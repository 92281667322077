<template>
  <g>
    <path
      :d="
        `M${calcSource().x} ${calcSource().y}
        h${calcConnection().h}v${calcConnection().v}l${
          calcConnection().lx
        } ${calcConnection().ly} l${calcConnection().lx2} ${
          calcConnection().ly2
        } l${calcConnection().lx3} ${calcConnection().ly3}`
      "
      fill="none"
      fill-rule="evenodd"
      :stroke="link.color || destination.content.color || '#bbbbbb'"
      :stroke-width="strokeWidth"
      stroke-linecap="butt"
      stroke-linejoin="miter"
      stroke-opacity="1"
      :stroke-dasharray="definePattern(link.pattern)"
      :marker-start="
        link.arrow === 'src' || link.arrow === 'both'
          ? `url(#${link.id})`
          : ''
      "
      :marker-end="
        link.arrow === 'dest' || link.arrow === 'both'
          ? `url(#${link.id})`
          : ''
      "
    />
    <!--<line
          :x1="calcSource().x"
          :y1="calcSource().y"
          :x2="calcDestination().x"
          :y2="calcDestination().y"
          :stroke="link.color || '#ffeaa7'"
          stroke-width="3"
          fill="none"
          :stroke-dasharray="definePattern(link.pattern)"
          :marker-start="
      link.arrow === 'src' || link.arrow === 'both' ? `url(#${link.id})` : ''
    "
          :marker-end="
      link.arrow === 'dest' || link.arrow === 'both' ? `url(#${link.id})` : ''
    " />-->
    <marker
      :id="link.id"
      markerUnits="userSpaceOnUse"
      orient="auto-start-reverse"
      :markerWidth="strokeWidth * 3"
      :markerHeight="strokeWidth * 3"
      viewBox="0 0 10 10"
      refX="5"
      refY="5"
    >
      <polygon
        points="0,1.5 0,8.5 10,5 "
        :fill="link.color || destination.content.color || '#bbbbbb'"
      />
    </marker>
    <!--<g v-if="editable">
        <ellipse :id="id"
                 :cx="calcSource().x"
                 :cy="calcSource().y"
                 rx="10"
                 ry="10"
                 fill="#ff7675"
                 stroke-width="2"
                 class="grab"
                 @click="select"
                 @mousedown="mousedown"
                 @touchstart="mousedown"
                 @mousemove="mousemove"
                 @touchmove="mousemove"
                 @mouseup="mouseup"
                 @touchend="mouseup" />
    </g>
    <g>
        <text :x="calcSource().x - 15"
              :y="calcSource().y - 20"
              fill="#00b894"
              @click="edit"
              v-if="selected"
              class="button">
            {{ labels.edit || "Edit" }}
        </text>
        <text :x="calcSource().x - 15"
              :y="calcSource().y + 30"
              fill="#ff7675"
              @click="remove"
              v-if="selected"
              class="button">
            {{ labels.remove || "Remove" }}
        </text>
    </g>-->
  </g>
</template>
<script>
export default {
  props: {
    selected: Boolean,
    editable: Boolean,
    source: {
      id: String,
      x: Number,
      y: Number,
    },
    destination: {
      id: String,
      x: Number,
      y: Number,
    },
    link: {
      id: String,
      color: {
        type: String,
        default: '#e6e6e6',
      },
      objectType: {
        type: String,
        default: 'straight',
      },
      pattern: {
        type: String,
        default: 'solid',
      },
      arrow: {
        type: String,
        default: 'none',
      },
      spacing: Number,
      point: {
        x: Number,
        y: Number,
      },
    },
    labels: Object,
    scale: Number,
    rWidth: Number,
    rHeight: Number,
  },
  computed: {
    strokeWidth: function() {
      var factor = 1;
      var deltaHeight = 0;
      var deltaHeightOutlet;
      var deltaHeightInlet;
      deltaHeightInlet = this.calcDeltaHeight().inlet;
      deltaHeightOutlet = this.calcDeltaHeight().outlet;
      //console.log("deltaHeightInlet: " + deltaHeightInlet + " deltaHeightOutlet: " + deltaHeightOutlet);
      if (deltaHeightInlet > deltaHeightOutlet) {
        deltaHeight = deltaHeightOutlet;
      } else {
        deltaHeight = deltaHeightInlet;
      }
      do {
        factor = factor - 0.01;
      } while (deltaHeight * 2 * factor > deltaHeight);
      return deltaHeight * factor;
    },
  },
  data() {
    return {
      startPosition: null,
      cursorOffset: {
        x: 0,
        y: 0,
      },
      id: this.link.id,
      point: this.link.point,
    };
  },
  methods: {
    getLocation(e) {
      let x = 0;
      let y = 0;
      if (e.touches) {
        x = e.touches[0].pageX;
        y = e.touches[0].pageY;
      } else {
        x = e.pageX;
        y = e.pageY;
      }
      return [x, y];
    },
    mousedown(e) {
      const [x, y] = this.getLocation(e);
      this.cursorOffset.x = x;
      this.cursorOffset.y = y;
      this.startPosition = { x: this.point.x, y: this.point.y };
      document.addEventListener('mousemove', this.mousemove);
      document.addEventListener('mouseup', this.mouseup);
    },
    mousemove(e) {
      if (this.startPosition) {
        e.preventDefault();
        const [x, y] = this.getLocation(e);
        this.point.x =
          this.startPosition.x +
          (x - this.cursorOffset.x) /
            this.rWidth /
            parseFloat(this.scale);
        this.point.y =
          this.startPosition.y +
          (y - this.cursorOffset.y) /
            this.rHeight /
            parseFloat(this.scale);
        this.$emit('updateLocation', {
          id: this.id,
          x: this.point.x,
          y: this.point.y,
        });
      }
    },
    definePattern(p) {
      if (p === 'solid') {
        return 0;
      } else if (p === 'dash') {
        return 10;
      } else if (p === 'dot') {
        return 3;
      } else {
        return 0;
      }
    },
    mouseup() {
      this.startPosition = null;
      document.removeEventListener('mousemove', this.mousemove);
      document.removeEventListener('mouseup', this.mouseup);
    },
    remove() {
      this.$emit('remove', this.id);
    },
    select() {
      this.$emit('select', this.id);
    },
    edit() {
      //console.log("edit");
      this.$emit('editLink', {
        id: this.link.id,
        content: {
          color: this.link.color || '#ffeaa7',
          objectType: this.link.objectType || 'straight',
          pattern: this.link.pattern || 'solid',
          arrow: this.link.arrow || 'none',
        },
      });
    },
    calcSource() {
      var deltaX;
      var deltaY;
      var deltaHeightOutlet = this.calcDeltaHeight().outlet;
      if (this.source.objectType == 'input') {
        if (this.source.flowDirection === 'right to left') {
          deltaX = 0;
          deltaY = this.source.height / 2;
        } else if (this.source.flowDirection === 'left to right') {
          deltaX = this.source.length + this.source.height * 1.5;
          deltaY = this.source.height / 2;
        } else if (this.source.flowDirection === 'bottom to top') {
          deltaX = this.source.height / 2;
          deltaY = -this.source.length - this.source.height * 1.5;
        } else if (this.source.flowDirection === 'top to bottom') {
          deltaX = this.source.height / 2;
          deltaY = this.source.height / 2;
        }
      } else if (
        this.source.objectType == 'burner' ||
        this.source.objectType == 'burner-constricted'
      ) {
        if (this.source.orientation == 'horizontal') {
          if (this.source.shape == 'rectangular') {
            if (
              this.source.flowDirection === 'right to left' ||
              this.source.flowDirection === 'bottom to top'
            ) {
              deltaX = 0;
            } else {
              deltaX =
                this.source.length + (this.source.height * 1) / 3;
            }
            if (this.source.numOutlet == 1) {
              deltaY = this.source.height / 2;
            } else {
              deltaY =
                this.source.height * 0.1 +
                deltaHeightOutlet * this.link.order;
            }
          } else {
            //'cylindrical'
            if (
              this.source.flowDirection === 'right to left' ||
              this.source.flowDirection === 'bottom to top'
            ) {
              deltaX = 0;
            } else {
              deltaX =
                this.source.length + (this.source.diameter * 1) / 3;
            }
            if (this.source.numOutlet == 1) {
              deltaY = this.source.diameter / 2;
            } else {
              deltaY =
                this.source.diameter * 0.1 +
                deltaHeightOutlet * this.link.order;
            }
          }
        } else if (this.source.orientation == 'vertical') {
          if (this.source.shape == 'rectangular') {
            if (
              this.source.flowDirection === 'right to left' ||
              this.source.flowDirection === 'bottom to top'
            ) {
              deltaY = 0;
            } else {
              deltaY =
                this.source.length + (this.source.height * 1) / 3;
            }
            if (this.source.numOutlet == 1) {
              deltaX = this.source.height / 2;
            } else {
              deltaX =
                this.source.height * 0.1 +
                deltaHeightOutlet * this.link.order;
            }
          } else {
            //'cylindrical'
            if (
              this.source.flowDirection === 'right to left' ||
              this.source.flowDirection === 'bottom to top'
            ) {
              deltaY = 0;
            } else {
              deltaY =
                this.source.length + (this.source.diameter * 1) / 3;
            }
            if (this.source.numOutlet == 1) {
              deltaX = this.source.diameter / 2;
            } else {
              deltaX =
                this.source.diameter * 0.9 -
                deltaHeightOutlet * this.link.order;
            }
          }
        }
      }
      return {
        x: this.source.point.x + deltaX,
        y: this.source.point.y + deltaY,
      };
    },
    calcDestination() {
      var deltaX;
      var deltaY;
      var deltaHeightInlet = this.calcDeltaHeight().inlet;
      if (this.destination.objectType == 'input') {
        if (this.destination.flowDirection === 'right to left') {
          deltaX =
            this.destination.length +
            this.destination.height * 1.5 +
            this.strokeWidth * 1.65;
          deltaY = this.destination.height / 2;
        } else if (
          this.destination.flowDirection === 'left to right'
        ) {
          deltaX = 0 - this.strokeWidth * 1.65;
          deltaY = this.destination.height / 2;
        } else if (
          this.destination.flowDirection === 'bottom to top'
        ) {
          deltaX = this.destination.height / 2;
          deltaY = +this.strokeWidth * 1.65;
        } else if (
          this.destination.flowDirection === 'top to bottom'
        ) {
          deltaX = this.destination.height / 2;
          deltaY =
            -this.destination.length -
            this.destination.height / 2 -
            this.strokeWidth * 1.65;
        }
      } else if (
        this.destination.objectType == 'burner' ||
        this.destination.objectType == 'burner-constricted'
      ) {
        if (this.destination.orientation == 'horizontal') {
          if (this.destination.flowDirection === 'right to left') {
            deltaX =
              this.destination.length +
              (this.destination.height * 1) / 3 +
              this.strokeWidth * 1.65;
          } else {
            //this.destination.flowDirection === 'left to right'
            deltaX = 0 - this.strokeWidth * 1.65;
          }
          if (this.destination.numInlet == 1) {
            deltaY = (this.destination.height * 3) / 6;
          } else {
            deltaY =
              this.destination.height * 0.1 +
              (this.destination.height * 0.8 * 1) / 6;
          }
        } else if (this.destination.orientation == 'vertical') {
          if (this.destination.flowDirection === 'bottom to top') {
            deltaY =
              this.destination.length +
              (this.destination.height * 1) / 3 +
              this.strokeWidth * 1.65;
            if (this.destination.numInlet == 1) {
              deltaX = (this.destination.height * 3) / 6;
            } else {
              deltaX =
                this.destination.height * 0.1 +
                this.destination.height * 0.8 -
                deltaHeightInlet *
                  (this.destination.numInlet - this.link.order);
            }
          } else {
            //this.destination.flowDirection === 'top to bottom'
            deltaY = 0 - this.strokeWidth * 1.65;
            if (this.destination.numInlet == 1) {
              deltaX = (this.destination.height * 3) / 6;
            } else {
              deltaX =
                this.destination.height * 0.1 +
                (this.destination.height * 0.8 * 5) / 6 -
                deltaHeightInlet * this.link.order;
            }
          }
        }
      }
      return {
        x: this.destination.point.x + deltaX,
        y: this.destination.point.y + deltaY,
      };
    },
    calcConnection() {
      var sourceWidth = this.calcWidth().source;
      let dx = this.calcDestination().x - this.calcSource().x;
      let dy = this.calcDestination().y - this.calcSource().y;
      var deltaHeightInlet = this.calcDeltaHeight().inlet;
      var deltaHeightOutlet = this.calcDeltaHeight().outlet;

      //console.log("calcConnection id: " + this.source.id + " dx: " + dx + " dy: " + dy);
      //#region destination "input"
      if (this.destination.objectType == 'input') {
        //#region source "burner""burner-constricted"
        if (
          this.source.objectType == 'burner' ||
          this.source.objectType == 'burner-constricted'
        ) {
          //#region source orientation "horizontal"
          if (this.source.orientation == 'horizontal') {
            if (this.calcSource().y < this.calcDestination().y) {
              if (this.source.flowDirection === 'right to left') {
                return {
                  h:
                    -deltaHeightOutlet *
                    (this.source.numOutlet - this.link.order),
                  v: dy,
                  lx:
                    dx +
                    deltaHeightOutlet *
                      (this.source.numOutlet - this.link.order),
                  ly: 0,
                  lx2: 0,
                  ly2: 0,
                  lx3:
                    this.destination.flowDirection === 'right to left'
                      ? -1
                      : 0,
                  ly3: 0,
                };
              } else {
                return {
                  h:
                    deltaHeightOutlet *
                    (this.source.numOutlet - this.link.order),
                  v: dy,
                  lx:
                    dx -
                    deltaHeightOutlet *
                      (this.source.numOutlet - this.link.order),
                  ly: 0,
                  lx2: 0,
                  ly2: 0,
                  lx3:
                    this.destination.flowDirection === 'right to left'
                      ? -1
                      : 0,
                  ly3: 0,
                };
              }
            } else {
              //(this.calcSource().y >= this.calcDestination().y)
              if (this.source.flowDirection === 'right to left') {
                return {
                  h:
                    -deltaHeightOutlet *
                    (this.source.numOutlet - this.link.order),
                  v: dy,
                  lx:
                    dx +
                    deltaHeightOutlet *
                      (this.source.numOutlet - this.link.order),
                  ly: 0,
                  lx2: 0,
                  ly2: 0,
                  lx3:
                    this.destination.flowDirection === 'right to left'
                      ? -1
                      : 0,
                  ly3: 0,
                };
              } else {
                return {
                  h: deltaHeightOutlet * (this.link.order + 1),
                  v: dy,
                  lx: dx - deltaHeightOutlet * (this.link.order + 1),
                  ly: 0,
                  lx2: 0,
                  ly2: 0,
                  lx3:
                    this.destination.flowDirection === 'right to left'
                      ? -1
                      : 0,
                  ly3: 0,
                };
              }
            }
            //#endregion source orientation "horizontal"
            //#region source orientation "vertical"
          } else if (this.source.orientation == 'vertical') {
            if (this.calcSource().y < this.calcDestination().y) {
              if (
                this.source.flowDirection === 'right to left' ||
                this.source.flowDirection === 'bottom to top'
              ) {
                if (
                  this.destination.flowDirection ===
                    'left to right' ||
                  this.destination.flowDirection === 'right to left'
                ) {
                  return {
                    h: 0,
                    v: 0,
                    lx: 0,
                    ly: 0,
                    lx2: 0,
                    ly2: dy,
                    lx3: dx,
                    ly3: 0,
                  };
                } else {
                  return {
                    h: 0,
                    v:
                      this.source.numOutlet == 1
                        ? -deltaHeightOutlet * this.link.order -
                          this.source.height / 2
                        : -deltaHeightOutlet * (this.link.order + 1),
                    lx: dx,
                    ly: 0,
                    lx2: 0,
                    ly2:
                      this.source.numOutlet == 1
                        ? dy +
                          deltaHeightOutlet * this.link.order +
                          this.source.height / 2
                        : dy +
                          deltaHeightOutlet * (this.link.order + 1),
                    lx3: 0,
                    ly3: 1,
                  };
                }
              } else {
                if (
                  this.destination.flowDirection ===
                    'left to right' ||
                  this.destination.flowDirection === 'right to left'
                ) {
                  return {
                    h: 0,
                    v: 0,
                    lx: 0,
                    ly: 0,
                    lx2: 0,
                    ly2: dy,
                    lx3: dx,
                    ly3: 0,
                  };
                } else {
                  return {
                    h: 0,
                    v:
                      dy +
                      deltaHeightInlet *
                        (this.destination.numInlet -
                          this.link.order +
                          1),
                    lx: dx,
                    ly: 0,
                    lx2: 0,
                    ly2:
                      -deltaHeightInlet *
                      (this.destination.numInlet -
                        this.link.order +
                        1),
                    lx3: 0,
                    ly3: -1,
                  };
                }
              }
            } else {
              if (
                this.source.flowDirection === 'right to left' ||
                this.source.flowDirection === 'bottom to top'
              ) {
                if (
                  this.destination.flowDirection ===
                    'left to right' ||
                  this.destination.flowDirection === 'right to left'
                ) {
                  return {
                    h: 0,
                    v: 0,
                    lx: 0,
                    ly: 0,
                    lx2: 0,
                    ly2: dy,
                    lx3: dx,
                    ly3: 0,
                  };
                } else {
                  return {
                    h: 0,
                    v:
                      this.source.numOutlet == 1
                        ? -deltaHeightOutlet * this.link.order -
                          this.source.height / 2
                        : -deltaHeightOutlet * (this.link.order + 1),
                    lx: dx,
                    ly: 0,
                    lx2: 0,
                    ly2:
                      this.source.numOutlet == 1
                        ? dy +
                          deltaHeightOutlet * this.link.order +
                          this.source.height / 2
                        : dy +
                          deltaHeightOutlet * (this.link.order + 1),
                    lx3: 0,
                    ly3: 1,
                  };
                }
              } else {
                if (
                  this.destination.flowDirection ===
                    'left to right' ||
                  this.destination.flowDirection === 'right to left'
                ) {
                  return {
                    h: 0,
                    v: 0,
                    lx: 0,
                    ly: 0,
                    lx2: 0,
                    ly2: dy,
                    lx3: dx,
                    ly3: 0,
                  };
                } else {
                  return {
                    h: 0,
                    v:
                      dy +
                      deltaHeightInlet *
                        (this.destination.numInlet -
                          this.link.order +
                          1),
                    lx: dx,
                    ly: 0,
                    lx2: 0,
                    ly2:
                      -deltaHeightInlet *
                      (this.destination.numInlet -
                        this.link.order +
                        1),
                    lx3: 0,
                    ly3: -1,
                  };
                }
              }
            }
          }
          //#endregion source orientation "vertical"
        } else {
          if (this.calcSource().y < this.calcDestination().y) {
            return {
              h: 0,
              v: dy,
              lx: dx,
              ly: 0,
              lx2: 0,
              ly2: 0,
              lx3: 0,
              ly3: 0,
            };
          } else {
            //(this.calcSource().y >= this.calcDestination().y)
            return {
              h: 0,
              v: dy,
              lx: dx,
              ly: 0,
              lx2: 0,
              ly2: 0,
              lx3: 0,
              ly3: 0,
            };
          }
        }
        //#endregion source "burner""burner-constricted"
        //#endregion destination "input"
        //#region destination "burner""burner-constricted"
      } else if (
        this.destination.objectType == 'burner' ||
        this.destination.objectType == 'burner-constricted'
      ) {
        //#region destination orientation "horizontal"
        if (this.destination.orientation == 'horizontal') {
          //#region source "input"
          if (this.source.objectType == 'input') {
            if (this.calcSource().y < this.calcDestination().y) {
              if (this.source.flowDirection == 'right to left') {
                return {
                  h:
                    -deltaHeightInlet *
                    (this.destination.numInlet - this.link.order),
                  v: dy + deltaHeightInlet * this.link.order,
                  lx:
                    dx +
                    deltaHeightInlet *
                      (this.destination.numInlet - this.link.order),
                  ly: 0,
                  lx2: 1,
                  ly2: 0,
                  lx3:
                    this.destination.flowDirection === 'right to left'
                      ? -1
                      : 0,
                  ly3: 0,
                };
              } else {
                return {
                  h:
                    deltaHeightInlet *
                    (this.destination.numInlet - this.link.order),
                  v: dy + deltaHeightInlet * this.link.order,
                  lx:
                    dx -
                    deltaHeightInlet *
                      (this.destination.numInlet - this.link.order),
                  ly: 0,
                  lx2: 0,
                  ly2: 0,
                  lx3:
                    this.destination.flowDirection === 'right to left'
                      ? -1
                      : 0,
                  ly3: 0,
                };
              }
            } else {
              //(this.calcSource().y >= this.calcDestination().y)
              if (this.source.flowDirection == 'right to left') {
                return {
                  h:
                    -deltaHeightInlet *
                    (this.destination.numInlet - this.link.order + 1),
                  v: dy + deltaHeightInlet * this.link.order,
                  lx:
                    dx +
                    deltaHeightInlet *
                      (this.destination.numInlet -
                        this.link.order +
                        1),
                  ly: 0,
                  lx2: 0,
                  ly2: 0,
                  lx3:
                    this.destination.flowDirection === 'right to left'
                      ? -1
                      : 0,
                  ly3: 0,
                };
              } else {
                return {
                  h: deltaHeightInlet * (this.link.order + 1),
                  v: dy + deltaHeightInlet * this.link.order,
                  lx: dx - deltaHeightInlet * (this.link.order + 1),
                  ly: 0,
                  lx2: 0,
                  ly2: 0,
                  lx3: 0,
                  ly3: 0,
                };
              }
            }
            //#endregion source "input"
          }
          //#endregion destination orientation "horizontal"
          //#region destination orientation "vertical"
        } else if (this.destination.orientation == 'vertical') {
          //#region source "input"
          if (this.source.objectType == 'input') {
            if (this.source.flowDirection === 'left to right') {
              if (
                this.destination.flowDirection === 'bottom to top'
              ) {
                if (this.calcSource().y < this.calcDestination().y) {
                  return {
                    h:
                      deltaHeightInlet *
                      (this.destination.numInlet - this.link.order),
                    v: dy + deltaHeightInlet * (1 + this.link.order),
                    lx:
                      dx -
                      deltaHeightInlet *
                        (this.destination.numInlet - this.link.order),
                    ly: 0,
                    lx2: 0,
                    ly2: -deltaHeightInlet * (1 + this.link.order),
                    lx3: 0,
                    ly3: -1,
                  };
                } else {
                  return {
                    h: dx,
                    v: dy,
                    lx: 0,
                    ly: 0,
                    lx2: 0,
                    ly2: 0,
                    lx3: 0,
                    ly3: -1,
                  };
                }
              } else {
                //this.destination.flowDirection === 'top to bottom'
                if (this.calcSource().y < this.calcDestination().y) {
                  return {
                    h: dx,
                    v: dy,
                    lx: 0,
                    ly: 0,
                    lx2: 0,
                    ly2: 0,
                    lx3: 0,
                    ly3: 1,
                  };
                } else {
                  return {
                    h: 0,
                    v:
                      dy -
                      deltaHeightInlet *
                        (this.destination.numInlet - this.link.order),
                    lx: dx,
                    ly: 0,
                    lx2: 0,
                    ly2:
                      deltaHeightInlet *
                      (this.destination.numInlet - this.link.order),
                    lx3: 0,
                    ly3: 1,
                  };
                }
              }
            } else {
              //this.source.flowDirection === 'right to left'
              if (
                this.destination.flowDirection === 'bottom to top'
              ) {
                if (this.calcSource().y < this.calcDestination().y) {
                  return {
                    h:
                      -deltaHeightInlet *
                      (this.destination.numInlet - this.link.order),
                    v: dy + deltaHeightInlet * (1 + this.link.order),
                    lx:
                      dx +
                      deltaHeightInlet *
                        (this.destination.numInlet - this.link.order),
                    ly: 0,
                    lx2: 0,
                    ly2: -deltaHeightInlet * (1 + this.link.order),
                    lx3: 0,
                    ly3: -1,
                  };
                } else {
                  return {
                    h: dx,
                    v: dy,
                    lx: 0,
                    ly: 0,
                    lx2: 0,
                    ly2: 0,
                    lx3: 0,
                    ly3: -1,
                  };
                }
              } else {
                //this.destination.flowDirection === 'top to bottom'
                if (this.calcSource().y < this.calcDestination().y) {
                  return {
                    h: dx,
                    v: dy,
                    lx: 0,
                    ly: 0,
                    lx2: 0,
                    ly2: 0,
                    lx3: 0,
                    ly3: 1,
                  };
                } else {
                  return {
                    h: 0,
                    v:
                      dy -
                      deltaHeightInlet *
                        (this.destination.numInlet - this.link.order),
                    lx: dx,
                    ly: 0,
                    lx2: 0,
                    ly2:
                      deltaHeightInlet *
                      (this.destination.numInlet - this.link.order),
                    lx3: 0,
                    ly3: 1,
                  };
                }
              }
            }
            //#endregion source "input"
          }
        }
        //#endregion destination orientation "vertical"
      }
      //#endregion destination "burner""burner-constricted"
    },
    calcWidth() {
      var sourceWidth;
      if (this.source.objectType == 'input') {
        sourceWidth = this.source.length + this.source.height * 1.5;
      } else if (
        this.source.objectType == 'burner' ||
        this.source.objectType == 'burner-constricted'
      ) {
        sourceWidth = 0; //this.source.length + this.source.height* 1 / 3;
      }

      var destinationWidth;
      if (this.destination.objectType == 'input') {
        destinationWidth =
          this.destination.length + this.destination.height * 1.5;
      } else if (
        this.destination.objectType == 'burner' ||
        this.destination.objectType == 'burner-constricted'
      ) {
        destinationWidth =
          this.destination.length + (this.destination.height * 1) / 3;
      }

      return {
        source: sourceWidth,
        destination: destinationWidth,
      };
    },
    calcHeight() {
      var sourceHeight;
      if (this.source.objectType == 'input') {
        sourceHeight = this.source.height / 2;
      } else if (
        this.source.objectType == 'burner' ||
        this.source.objectType == 'burner-constricted'
      ) {
        sourceHeight =
          this.calcDeltaHeight().outlet * this.link.order;
      }

      var destinationHeight;
      if (this.destination.objectType == 'input') {
        destinationHeight = this.destination.height / 2;
      } else if (
        this.destination.objectType == 'burner' ||
        this.destination.objectType == 'burner-constricted'
      ) {
        destinationHeight =
          this.calcDeltaHeight().outlet * this.link.order;
      }

      return {
        source: sourceHeight,
        destination: destinationHeight,
      };
    },
    calcDeltaHeight() {
      var deltaHeightInlet;
      if (this.destination.objectType == 'input') {
        if (this.destination.numInlet - 1 == 0) {
          deltaHeightInlet = this.destination.height;
        } else {
          deltaHeightInlet =
            this.destination.height / (this.destination.numInlet - 1);
        }
      } else if (
        this.destination.objectType == 'burner' ||
        this.destination.objectType == 'burner-constricted'
      ) {
        if (this.destination.numInlet - 1 == 0) {
          deltaHeightInlet = this.destination.height / 2;
        } else {
          if (this.destination.shape == 'rectangular') {
            deltaHeightInlet =
              (this.destination.height * 0.8 * 2) /
              3 /
              (this.destination.numInlet - 1);
          } else {
            deltaHeightInlet =
              (this.destination.diameter * 0.8 * 2) /
              3 /
              (this.destination.numInlet - 1);
          }
        }
      }

      var deltaHeightOutlet;

      if (this.source.objectType == 'input') {
        if (this.source.numOutlet - 1 == 0) {
          deltaHeightOutlet = this.source.height;
        } else {
          deltaHeightOutlet =
            this.source.height / (this.source.numOutlet - 1);
        }
      } else if (
        this.source.objectType == 'burner' ||
        this.source.objectType == 'burner-constricted'
      ) {
        if (this.source.numOutlet - 1 == 0) {
          if (this.source.shape == 'rectangular') {
            deltaHeightOutlet = this.source.height / 2;
          } else {
            deltaHeightOutlet = this.source.diameter / 2;
          }
        } else {
          if (this.source.shape == 'rectangular') {
            deltaHeightOutlet =
              (this.source.height * 0.8) /
              (this.source.numOutlet - 1);
          } else {
            deltaHeightOutlet =
              (this.source.diameter * 0.8) /
              (this.source.numOutlet - 1);
          }
        }
      }

      return {
        inlet: deltaHeightInlet,
        outlet: deltaHeightOutlet,
      };
    },
  },
  mounted() {
    this.$maxid = this.$maxid + 1;
  },
};
</script>
<style scoped></style>
