var render = function render(){var _vm=this,_c=_vm._self._c;return _c('g',[(_vm.editable)?_c('g',[(_vm.selected && _vm.node.objectType === 'input')?_c('text',{staticClass:"button",attrs:{"x":_vm.x + _vm.calcChangeFlowDirection().x,"y":_vm.y + _vm.calcChangeFlowDirection().y,"fill":"#ff7675"},on:{"click":_vm.changeFlowDirection}},[_vm._v(" "+_vm._s(_vm.labels.change || 'Flip')+" ")]):_vm._e()]):_vm._e(),(_vm.node.objectType === 'ellipse')?_c('ellipse',{staticClass:"grab",attrs:{"cx":_vm.x + _vm.node.length / 2,"cy":_vm.y + _vm.node.height / 2,"width":_vm.node.length,"height":_vm.node.height,"rx":_vm.node.length / 2,"ry":_vm.node.height / 2,"fill":_vm.node.content.color || '#e6e6e6',"stroke-width":_vm.node.strokeWeight,"stroke":_vm.node.stroke},on:{"touchstart":_vm.mousedown,"mousedown":_vm.mousedown,"mousemove":_vm.mousemove,"touchmove":_vm.mousemove,"mouseup":_vm.mouseup,"touchend":_vm.mouseup}}):_vm._e(),_c('g',[(
        _vm.node.objectType === 'input' &&
          _vm.node.flowDirection === 'left to right'
      )?_c('path',{staticClass:"grab",attrs:{"d":'m' +
          _vm.x +
          ',' +
          _vm.y +
          ' h ' +
          (_vm.node.length + _vm.node.content.fontSize * 0.6) +
          ' l ' +
          _vm.node.height +
          ',' +
          _vm.node.height / 2 +
          ' ' +
          -_vm.node.height +
          ',' +
          _vm.node.height / 2 +
          ' h ' +
          -(_vm.node.length + _vm.node.content.fontSize * 0.6) +
          ' z',"x":_vm.x,"y":_vm.y,"width":_vm.node.length,"height":_vm.node.height,"fill":_vm.node.content.color || '#e6e6e6',"rx":"5","ry":"3","fill-opacity":"1","stroke":"#000000","stroke-width":"0","stroke-linecap":"butt","stroke-linejoin":"miter","stroke-miterlimit":"4","stroke-dasharray":"none","stroke-opacity":"1"},on:{"touchstart":_vm.mousedown,"mousedown":_vm.mousedown,"mousemove":_vm.mousemove,"touchmove":_vm.mousemove,"mouseup":_vm.mouseup,"touchend":_vm.mouseup}}):_vm._e(),_c('a',{attrs:{"target":"_blank","href":_vm.node.content.url}},[(
          _vm.node.objectType === 'input' &&
            _vm.node.flowDirection === 'left to right'
        )?_c('text',{attrs:{"x":_vm.x + _vm.node.height * 0.2,"y":_vm.y + _vm.node.height * 0.8,"fill":_vm.node.content.textColor || '#000000',"font-family":"Meiryo UI, sans-serif","font-size":_vm.node.content.fontSize,"text-anchor":"left"}},[_vm._v(" "+_vm._s(_vm.node.content.text)+" ")]):_vm._e()]),(
        _vm.node.objectType === 'input' &&
          _vm.node.flowDirection === 'left to right'
      )?_c('path',{staticClass:"grab",attrs:{"d":'m' +
          _vm.x +
          ',' +
          _vm.y +
          ' h ' +
          (_vm.node.length + _vm.node.content.fontSize * 0.6) +
          ' l ' +
          _vm.node.height +
          ',' +
          _vm.node.height / 2 +
          ' ' +
          -_vm.node.height +
          ',' +
          _vm.node.height / 2 +
          ' h ' +
          -(_vm.node.length + _vm.node.content.fontSize * 0.6) +
          ' z',"x":_vm.x,"y":_vm.y,"width":_vm.node.length,"height":_vm.node.height,"fill":_vm.node.content.color || '#e6e6e6',"rx":"5","ry":"3","fill-opacity":"0.1","stroke":"#000000","stroke-width":"0","stroke-linecap":"butt","stroke-linejoin":"miter","stroke-miterlimit":"4","stroke-dasharray":"none","stroke-opacity":"1"},on:{"touchstart":_vm.mousedown,"mousedown":_vm.mousedown,"mousemove":_vm.mousemove,"touchmove":_vm.mousemove,"mouseup":_vm.mouseup,"touchend":_vm.mouseup}}):_vm._e()]),_c('g',[(
        _vm.node.objectType === 'input' &&
          _vm.node.flowDirection === 'right to left'
      )?_c('path',{staticClass:"grab",attrs:{"d":'m' +
          (_vm.x + _vm.calcInput().x) +
          ',' +
          _vm.y +
          ' h ' +
          _vm.calcAlgebraicSign() *
            (_vm.node.length + _vm.node.content.fontSize * 0.6) +
          ' l ' +
          _vm.calcAlgebraicSign() * _vm.node.height +
          ',' +
          _vm.node.height / 2 +
          ' ' +
          -_vm.calcAlgebraicSign() * _vm.node.height +
          ',' +
          _vm.node.height / 2 +
          ' h ' +
          -_vm.calcAlgebraicSign() *
            (_vm.node.length + _vm.node.content.fontSize * 0.6) +
          ' z',"x":_vm.x,"y":_vm.y,"width":_vm.node.length,"height":_vm.node.height,"fill":_vm.node.content.color || '#e6e6e6',"rx":"5","ry":"3","fill-opacity":"1","stroke":"#000000","stroke-width":"0","stroke-linecap":"butt","stroke-linejoin":"miter","stroke-miterlimit":"4","stroke-dasharray":"none","stroke-opacity":"1"},on:{"touchstart":_vm.mousedown,"mousedown":_vm.mousedown,"mousemove":_vm.mousemove,"touchmove":_vm.mousemove,"mouseup":_vm.mouseup,"touchend":_vm.mouseup}}):_vm._e(),_c('a',{attrs:{"target":"_blank","href":_vm.node.content.url}},[(
          _vm.node.objectType === 'input' &&
            _vm.node.flowDirection === 'right to left'
        )?_c('text',{attrs:{"x":_vm.x + _vm.calcInput().textX,"y":_vm.y + _vm.node.height * 0.8,"fill":_vm.node.content.textColor || '#000000',"font-family":"Meiryo UI, sans-serif","font-size":_vm.node.content.fontSize,"text-anchor":"left"}},[_vm._v(" "+_vm._s(_vm.node.content.text)+" ")]):_vm._e()]),(
        _vm.node.objectType === 'input' &&
          _vm.node.flowDirection === 'right to left'
      )?_c('path',{staticClass:"grab",attrs:{"d":'m' +
          (_vm.x + _vm.calcInput().x) +
          ',' +
          _vm.y +
          ' h ' +
          _vm.calcAlgebraicSign() *
            (_vm.node.length + _vm.node.content.fontSize * 0.6) +
          ' l ' +
          _vm.calcAlgebraicSign() * _vm.node.height +
          ',' +
          _vm.node.height / 2 +
          ' ' +
          -_vm.calcAlgebraicSign() * _vm.node.height +
          ',' +
          _vm.node.height / 2 +
          ' h ' +
          -_vm.calcAlgebraicSign() *
            (_vm.node.length + _vm.node.content.fontSize * 0.6) +
          ' z',"x":_vm.x,"y":_vm.y,"width":_vm.node.length,"height":_vm.node.height,"fill":_vm.node.content.color || '#e6e6e6',"rx":"5","ry":"3","fill-opacity":"0.1","stroke":"#000000","stroke-width":"0","stroke-linecap":"butt","stroke-linejoin":"miter","stroke-miterlimit":"4","stroke-dasharray":"none","stroke-opacity":"1"},on:{"touchstart":_vm.mousedown,"mousedown":_vm.mousedown,"mousemove":_vm.mousemove,"touchmove":_vm.mousemove,"mouseup":_vm.mouseup,"touchend":_vm.mouseup}}):_vm._e()]),_c('g',[(
        _vm.node.objectType === 'input' &&
          _vm.node.flowDirection === 'bottom to top'
      )?_c('path',{staticClass:"grab",attrs:{"d":'m' +
          (_vm.x + _vm.calcInput().x) +
          ',' +
          _vm.y +
          ' v ' +
          _vm.calcAlgebraicSign() *
            (_vm.node.length + _vm.node.content.fontSize * 0.6) +
          ' l ' +
          _vm.node.height / 2 +
          ',' +
          _vm.calcAlgebraicSign() * _vm.node.height +
          ' ' +
          _vm.node.height / 2 +
          ',' +
          -_vm.calcAlgebraicSign() * _vm.node.height +
          ' v ' +
          -_vm.calcAlgebraicSign() *
            (_vm.node.length + _vm.node.content.fontSize * 0.6) +
          ' z',"x":_vm.x,"y":_vm.y,"width":_vm.node.length,"height":_vm.node.height,"fill":_vm.node.content.color || '#e6e6e6',"rx":"5","ry":"3","fill-opacity":"1","stroke":"#000000","stroke-width":"0","stroke-linecap":"butt","stroke-linejoin":"miter","stroke-miterlimit":"4","stroke-dasharray":"none","stroke-opacity":"1"},on:{"touchstart":_vm.mousedown,"mousedown":_vm.mousedown,"mousemove":_vm.mousemove,"touchmove":_vm.mousemove,"mouseup":_vm.mouseup,"touchend":_vm.mouseup}}):_vm._e(),_c('a',{attrs:{"target":"_blank","href":_vm.node.content.url}},[(
          _vm.node.objectType === 'input' &&
            _vm.node.flowDirection === 'bottom to top'
        )?_c('text',{attrs:{"x":_vm.x + _vm.calcInput().textX,"y":_vm.y - this.node.length - this.node.height / 2,"fill":_vm.node.content.textColor || '#000000',"writing-mode":"tb","direction":"ltr","font-family":"Meiryo UI, sans-serif","font-size":_vm.node.content.fontSize,"text-anchor":"left"}},[_vm._v(" "+_vm._s(_vm.node.content.text)+" ")]):_vm._e()]),(
        _vm.node.objectType === 'input' &&
          _vm.node.flowDirection === 'bottom to top'
      )?_c('path',{staticClass:"grab",attrs:{"d":'m' +
          (_vm.x + _vm.calcInput().x) +
          ',' +
          _vm.y +
          ' v ' +
          _vm.calcAlgebraicSign() *
            (_vm.node.length + _vm.node.content.fontSize * 0.6) +
          ' l ' +
          _vm.node.height / 2 +
          ',' +
          _vm.calcAlgebraicSign() * _vm.node.height +
          ' ' +
          _vm.node.height / 2 +
          ',' +
          -_vm.calcAlgebraicSign() * _vm.node.height +
          ' v ' +
          -_vm.calcAlgebraicSign() *
            (_vm.node.length + _vm.node.content.fontSize * 0.6) +
          ' z',"x":_vm.x,"y":_vm.y,"width":_vm.node.length,"height":_vm.node.height,"fill":_vm.node.content.color || '#e6e6e6',"rx":"5","ry":"3","fill-opacity":"0.1","stroke":"#000000","stroke-width":"0","stroke-linecap":"butt","stroke-linejoin":"miter","stroke-miterlimit":"4","stroke-dasharray":"none","stroke-opacity":"1"},on:{"touchstart":_vm.mousedown,"mousedown":_vm.mousedown,"mousemove":_vm.mousemove,"touchmove":_vm.mousemove,"mouseup":_vm.mouseup,"touchend":_vm.mouseup}}):_vm._e()]),_c('g',[(
        _vm.node.objectType === 'input' &&
          _vm.node.flowDirection === 'top to bottom'
      )?_c('path',{staticClass:"grab",attrs:{"d":'m' +
          _vm.x +
          ',' +
          (_vm.y -
            _vm.calcAlgebraicSign() *
              (_vm.node.length + _vm.node.content.fontSize * 0.6)) +
          ' v ' +
          _vm.calcAlgebraicSign() *
            (_vm.node.length + _vm.node.content.fontSize * 0.6) +
          ' l ' +
          _vm.node.height / 2 +
          ',' +
          _vm.calcAlgebraicSign() * _vm.node.height +
          ' ' +
          _vm.node.height / 2 +
          ',' +
          -_vm.calcAlgebraicSign() * _vm.node.height +
          ' v ' +
          -_vm.calcAlgebraicSign() *
            (_vm.node.length + _vm.node.content.fontSize * 0.6) +
          ' z',"x":_vm.x,"y":_vm.y,"width":_vm.node.length,"height":_vm.node.height,"fill":_vm.node.content.color || '#e6e6e6',"rx":"5","ry":"3","fill-opacity":"1","stroke":"#000000","stroke-width":"0","stroke-linecap":"butt","stroke-linejoin":"miter","stroke-miterlimit":"4","stroke-dasharray":"none","stroke-opacity":"1"},on:{"touchstart":_vm.mousedown,"mousedown":_vm.mousedown,"mousemove":_vm.mousemove,"touchmove":_vm.mousemove,"mouseup":_vm.mouseup,"touchend":_vm.mouseup}}):_vm._e(),_c('a',{attrs:{"target":"_blank","href":_vm.node.content.url}},[(
          _vm.node.objectType === 'input' &&
            _vm.node.flowDirection === 'top to bottom'
        )?_c('text',{attrs:{"x":_vm.x + _vm.calcInput().textX,"y":_vm.y + _vm.node.height * 0.2,"fill":_vm.node.content.textColor || '#000000',"writing-mode":"tb","glyph-orientation-vertical":"0","direction":"rtl","font-family":"Meiryo UI, sans-serif","font-size":_vm.node.content.fontSize,"text-anchor":"left"}},[_vm._v(" "+_vm._s(_vm.node.content.text)+" ")]):_vm._e()]),(
        _vm.node.objectType === 'input' &&
          _vm.node.flowDirection === 'top to bottom'
      )?_c('path',{staticClass:"grab",attrs:{"d":'m' +
          _vm.x +
          ',' +
          (_vm.y -
            _vm.calcAlgebraicSign() *
              (_vm.node.length + _vm.node.content.fontSize * 0.6)) +
          ' v ' +
          _vm.calcAlgebraicSign() *
            (_vm.node.length + _vm.node.content.fontSize * 0.6) +
          ' l ' +
          _vm.node.height / 2 +
          ',' +
          _vm.calcAlgebraicSign() * _vm.node.height +
          ' ' +
          _vm.node.height / 2 +
          ',' +
          -_vm.calcAlgebraicSign() * _vm.node.height +
          ' v ' +
          -_vm.calcAlgebraicSign() *
            (_vm.node.length + _vm.node.content.fontSize * 0.6) +
          ' z',"x":_vm.x,"y":_vm.y,"width":_vm.node.length,"height":_vm.node.height,"fill":_vm.node.content.color || '#e6e6e6',"rx":"5","ry":"3","fill-opacity":"0.1","stroke":"#000000","stroke-width":"0","stroke-linecap":"butt","stroke-linejoin":"miter","stroke-miterlimit":"4","stroke-dasharray":"none","stroke-opacity":"1"},on:{"touchstart":_vm.mousedown,"mousedown":_vm.mousedown,"mousemove":_vm.mousemove,"touchmove":_vm.mousemove,"mouseup":_vm.mouseup,"touchend":_vm.mouseup}}):_vm._e()]),_c('g',[(_vm.node.objectType === 'rectangle')?_c('rect',{staticClass:"grab",attrs:{"x":_vm.x,"y":_vm.y,"width":_vm.node.length,"height":_vm.node.height,"rx":"5","ry":"3","fill":_vm.node.content.color || '#e6e6e6',"stroke-width":_vm.node.strokeWeight,"stroke":_vm.node.stroke},on:{"touchstart":_vm.mousedown,"mousedown":_vm.mousedown,"mousemove":_vm.mousemove,"touchmove":_vm.mousemove,"mouseup":_vm.mouseup,"touchend":_vm.mouseup}}):_vm._e(),_c('a',{attrs:{"target":"_blank","href":_vm.node.content.url}},[(_vm.node.objectType === 'rectangle')?_c('text',{attrs:{"x":_vm.x + _vm.node.length / 2,"y":_vm.y + _vm.node.height / 2,"fill":"#ffffff","font-family":"Meiryo UI, sans-serif","font-size":_vm.node.content.fontSize,"text-anchor":"middle"}},[_vm._v(" "+_vm._s(_vm.node.content.text)+" ")]):_vm._e()])]),(
      _vm.node.objectType === 'burner' ||
        _vm.node.objectType === 'burner-constricted'
    )?_c('g',[(_vm.node.orientation === 'horizontal')?_c('g',[_c('g',[_c('linearGradient',{attrs:{"id":_vm.gradientHorizontalID,"x1":"0%","y1":"0%","x2":"0%","y2":"100%"}},[_c('stop',{staticStyle:{"stop-color":"#303030"},attrs:{"offset":"0%"}}),_c('stop',{staticStyle:{"stop-color":"#e6e6e6"},attrs:{"offset":"60%"}}),_c('stop',{staticStyle:{"stop-color":"#666"},attrs:{"offset":"100%"}})],1),(
            _vm.node.objectType === 'burner' ||
              _vm.node.objectType === 'burner-constricted'
          )?_c('rect',{staticClass:"grab",attrs:{"x":_vm.x + _vm.calcBurner().h,"y":_vm.y + (_vm.node.height * 1) / 6,"width":(_vm.node.height * 1) / 3,"height":(_vm.node.height * 4) / 6,"rx":"5","ry":"3","fill":"#e6e6e6","stroke":"#000000","stroke-width":"1"},on:{"touchstart":_vm.mousedown,"mousedown":_vm.mousedown,"mousemove":_vm.mousemove,"touchmove":_vm.mousemove,"mouseup":_vm.mouseup,"touchend":_vm.mouseup}}):_vm._e(),(
            _vm.node.objectType === 'burner' ||
              _vm.node.objectType === 'burner-constricted'
          )?_c('rect',{staticClass:"grab",attrs:{"x":_vm.x + _vm.calcChamber().h,"y":_vm.y,"width":_vm.node.length,"height":_vm.node.height,"rx":"5","ry":"3","fill":`url('#${_vm.gradientHorizontalID}')`,"stroke":"#000000","stroke-width":"2"},on:{"touchstart":_vm.mousedown,"mousedown":_vm.mousedown,"mousemove":_vm.mousemove,"touchmove":_vm.mousemove,"mouseup":_vm.mouseup,"touchend":_vm.mouseup}}):_vm._e(),(_vm.node.objectType === 'burner-constricted')?_c('rect',{staticClass:"grab",staticStyle:{"fill":"#000000"},attrs:{"x":_vm.x + (_vm.node.height * 1) / 3 + _vm.node.length * 0.75,"y":_vm.y,"width":_vm.node.height * 0.1,"height":(_vm.node.height * 1) / 3},on:{"touchstart":_vm.mousedown,"mousedown":_vm.mousedown,"mousemove":_vm.mousemove,"touchmove":_vm.mousemove,"mouseup":_vm.mouseup,"touchend":_vm.mouseup}}):_vm._e(),(_vm.node.objectType === 'burner-constricted')?_c('rect',{staticClass:"grab",staticStyle:{"fill":"#000000","stroke":"#000000","stroke-width":"1"},attrs:{"x":_vm.x + (_vm.node.height * 1) / 3 + _vm.node.length * 0.75,"y":_vm.y + (_vm.node.height * 2) / 3,"width":_vm.node.height * 0.1,"height":(_vm.node.height * 1) / 3},on:{"touchstart":_vm.mousedown,"mousedown":_vm.mousedown,"mousemove":_vm.mousemove,"touchmove":_vm.mousemove,"mouseup":_vm.mouseup,"touchend":_vm.mouseup}}):_vm._e()],1),(_vm.node.flame)?_c('g',[(
            _vm.node.objectType === 'burner' ||
              _vm.node.objectType === 'burner-constricted'
          )?_c('path',{staticClass:"grab",staticStyle:{"fill":"#ff0000","fill-opacity":"1","stroke":"none"},attrs:{"x":_vm.x,"y":_vm.y,"d":'m' +
              (_vm.x + _vm.calcFlame().h + (_vm.node.height * 1) / 3) +
              ',' +
              (_vm.y + _vm.node.height * 0.5) +
              ' c ' +
              '0,0 ' +
              +0 +
              ',' +
              (_vm.calcAlgebraicSign() * _vm.node.width) / 2 +
              ' ' +
              (+_vm.calcAlgebraicSign() * _vm.node.length * 2) / 3 +
              ',0 ' +
              '0,0 ' +
              (+-_vm.calcAlgebraicSign() * _vm.node.length * 2) / 3 +
              ',' +
              (-_vm.calcAlgebraicSign() * _vm.node.width) / 2 +
              ' ' +
              (+-_vm.calcAlgebraicSign() * _vm.node.length * 2) / 3 +
              ',0 ' +
              ' z'},on:{"touchstart":_vm.mousedown,"mousedown":_vm.mousedown,"mousemove":_vm.mousemove,"touchmove":_vm.mousemove,"mouseup":_vm.mouseup,"touchend":_vm.mouseup}}):_vm._e(),(
            _vm.node.objectType === 'burner' ||
              _vm.node.objectType === 'burner-constricted'
          )?_c('path',{staticClass:"grab",staticStyle:{"fill":"#ff6600","fill-opacity":"1","stroke":"none"},attrs:{"x":_vm.x,"y":_vm.y,"d":'m' +
              (_vm.x + _vm.calcFlame().h + (_vm.node.height * 1) / 3) +
              ',' +
              (_vm.y + _vm.node.height * 0.5) +
              ' c ' +
              '0,0 ' +
              +0 +
              ',' +
              ((_vm.calcAlgebraicSign() * _vm.node.width) / 2) * 0.8 +
              ' ' +
              ((+_vm.calcAlgebraicSign() * _vm.node.length * 2) / 3) * 0.8 +
              ',0 ' +
              '0,0 ' +
              ((+-_vm.calcAlgebraicSign() * _vm.node.length * 2) / 3) *
                0.8 +
              ',' +
              ((-_vm.calcAlgebraicSign() * _vm.node.width) / 2) * 0.8 +
              ' ' +
              ((+-_vm.calcAlgebraicSign() * _vm.node.length * 2) / 3) *
                0.8 +
              ',0 ' +
              ' z'},on:{"touchstart":_vm.mousedown,"mousedown":_vm.mousedown,"mousemove":_vm.mousemove,"touchmove":_vm.mousemove,"mouseup":_vm.mouseup,"touchend":_vm.mouseup}}):_vm._e(),(
            _vm.node.objectType === 'burner' ||
              _vm.node.objectType === 'burner-constricted'
          )?_c('path',{staticClass:"grab",staticStyle:{"fill":"#ffff00","stroke":"none","stroke-opacity":"1"},attrs:{"x":_vm.x,"y":_vm.y,"d":'m' +
              (_vm.x + _vm.calcFlame().h + (_vm.node.height * 1) / 3) +
              ',' +
              (_vm.y + _vm.node.height * 0.5) +
              ' c ' +
              '0,0 ' +
              +0 +
              ',' +
              ((_vm.calcAlgebraicSign() * _vm.node.width) / 2) * 0.6 +
              ' ' +
              ((+_vm.calcAlgebraicSign() * _vm.node.length * 2) / 3) * 0.6 +
              ',0 ' +
              '0,0 ' +
              ((+-_vm.calcAlgebraicSign() * _vm.node.length * 2) / 3) *
                0.6 +
              ',' +
              ((-_vm.calcAlgebraicSign() * _vm.node.width) / 2) * 0.6 +
              ' ' +
              ((+-_vm.calcAlgebraicSign() * _vm.node.length * 2) / 3) *
                0.6 +
              ',0 ' +
              ' z'},on:{"touchstart":_vm.mousedown,"mousedown":_vm.mousedown,"mousemove":_vm.mousemove,"touchmove":_vm.mousemove,"mouseup":_vm.mouseup,"touchend":_vm.mouseup}}):_vm._e()]):_vm._e()]):(_vm.node.orientation === 'vertical')?_c('g',[_c('g',[_c('linearGradient',{attrs:{"id":_vm.gradientVerticalID,"x1":"0%","y1":"0%","x2":"100%","y2":"0%"}},[_c('stop',{staticStyle:{"stop-color":"#303030"},attrs:{"offset":"0%"}}),_c('stop',{staticStyle:{"stop-color":"#e6e6e6"},attrs:{"offset":"60%"}}),_c('stop',{staticStyle:{"stop-color":"#666"},attrs:{"offset":"100%"}})],1),(
            _vm.node.objectType === 'burner' ||
              _vm.node.objectType === 'burner-constricted'
          )?_c('rect',{staticClass:"grab",attrs:{"x":_vm.x + (_vm.node.height * 1) / 6,"y":_vm.y + _vm.calcBurner().v,"width":(_vm.node.height * 4) / 6,"height":(_vm.node.height * 1) / 3,"rx":"5","ry":"3","fill":"#e6e6e6","stroke":"#000000","stroke-width":"1"},on:{"touchstart":_vm.mousedown,"mousedown":_vm.mousedown,"mousemove":_vm.mousemove,"touchmove":_vm.mousemove,"mouseup":_vm.mouseup,"touchend":_vm.mouseup}}):_vm._e(),(
            _vm.node.objectType === 'burner' ||
              _vm.node.objectType === 'burner-constricted'
          )?_c('rect',{staticClass:"grab",attrs:{"x":_vm.x,"y":_vm.y + _vm.calcChamber().v,"width":_vm.node.height,"height":_vm.node.length,"rx":"5","ry":"3","fill":`url('#${_vm.gradientVerticalID}')`,"stroke":"#000000","stroke-width":"2"},on:{"touchstart":_vm.mousedown,"mousedown":_vm.mousedown,"mousemove":_vm.mousemove,"touchmove":_vm.mousemove,"mouseup":_vm.mouseup,"touchend":_vm.mouseup}}):_vm._e(),(_vm.node.objectType === 'burner-constricted')?_c('rect',{staticClass:"grab",staticStyle:{"fill":"#000000"},attrs:{"x":_vm.x,"y":_vm.y + (_vm.node.height * 1) / 3 + _vm.node.length * 0.75,"width":(_vm.node.height * 1) / 3,"height":_vm.node.height * 0.1},on:{"touchstart":_vm.mousedown,"mousedown":_vm.mousedown,"mousemove":_vm.mousemove,"touchmove":_vm.mousemove,"mouseup":_vm.mouseup,"touchend":_vm.mouseup}}):_vm._e(),(_vm.node.objectType === 'burner-constricted')?_c('rect',{staticClass:"grab",staticStyle:{"fill":"#000000","stroke":"#000000","stroke-width":"1"},attrs:{"x":_vm.x + (_vm.node.height * 2) / 3,"y":_vm.y + (_vm.node.height * 1) / 3 + _vm.node.length * 0.75,"width":(_vm.node.height * 1) / 3,"height":_vm.node.height * 0.1},on:{"touchstart":_vm.mousedown,"mousedown":_vm.mousedown,"mousemove":_vm.mousemove,"touchmove":_vm.mousemove,"mouseup":_vm.mouseup,"touchend":_vm.mouseup}}):_vm._e()],1),(_vm.node.flame)?_c('g',[(
            _vm.node.objectType === 'burner' ||
              _vm.node.objectType === 'burner-constricted'
          )?_c('path',{staticClass:"grab",staticStyle:{"fill":"#ff0000","fill-opacity":"1","stroke":"none"},attrs:{"x":_vm.x,"y":_vm.y,"d":'m' +
              (_vm.x + _vm.node.height * 0.5) +
              ',' +
              (_vm.y + _vm.calcFlame().v + (_vm.node.height * 1) / 3) +
              ' c ' +
              '0,0 ' +
              (+_vm.calcAlgebraicSign() * _vm.node.width) / 2 +
              ',' +
              0 +
              ' ' +
              +0 +
              ',' +
              (_vm.calcAlgebraicSign() * _vm.node.length * 2) / 3 +
              ' ' +
              '0,0 ' +
              (+-_vm.calcAlgebraicSign() * _vm.node.width) / 2 +
              ',' +
              (-_vm.calcAlgebraicSign() * _vm.node.length * 2) / 3 +
              ' ' +
              +0 +
              ',' +
              (-_vm.calcAlgebraicSign() * _vm.node.length * 2) / 3 +
              ' ' +
              ' z'},on:{"touchstart":_vm.mousedown,"mousedown":_vm.mousedown,"mousemove":_vm.mousemove,"touchmove":_vm.mousemove,"mouseup":_vm.mouseup,"touchend":_vm.mouseup}}):_vm._e(),(
            _vm.node.objectType === 'burner' ||
              _vm.node.objectType === 'burner-constricted'
          )?_c('path',{staticClass:"grab",staticStyle:{"fill":"#ff6600","fill-opacity":"1","stroke":"none"},attrs:{"x":_vm.x,"y":_vm.y,"d":'m' +
              (_vm.x + _vm.node.height * 0.5) +
              ',' +
              (_vm.y + _vm.calcFlame().v + (_vm.node.height * 1) / 3) +
              ' c ' +
              '0,0 ' +
              ((+_vm.calcAlgebraicSign() * _vm.node.width) / 2) * 0.8 +
              ',' +
              0 +
              ' ' +
              +0 +
              ',' +
              ((_vm.calcAlgebraicSign() * _vm.node.length * 2) / 3) * 0.8 +
              ' ' +
              '0,0 ' +
              ((+-_vm.calcAlgebraicSign() * _vm.node.width) / 2) * 0.8 +
              ',' +
              ((-_vm.calcAlgebraicSign() * _vm.node.length * 2) / 3) * 0.8 +
              ' ' +
              +0 +
              ',' +
              ((-_vm.calcAlgebraicSign() * _vm.node.length * 2) / 3) * 0.8 +
              ' ' +
              ' z'},on:{"touchstart":_vm.mousedown,"mousedown":_vm.mousedown,"mousemove":_vm.mousemove,"touchmove":_vm.mousemove,"mouseup":_vm.mouseup,"touchend":_vm.mouseup}}):_vm._e(),(
            _vm.node.objectType === 'burner' ||
              _vm.node.objectType === 'burner-constricted'
          )?_c('path',{staticClass:"grab",staticStyle:{"fill":"#ffff00","stroke":"none","stroke-opacity":"1"},attrs:{"x":_vm.x,"y":_vm.y,"d":'m' +
              (_vm.x + _vm.node.height * 0.5) +
              ',' +
              (_vm.y + _vm.calcFlame().v + (_vm.node.height * 1) / 3) +
              ' c ' +
              '0,0 ' +
              ((+_vm.calcAlgebraicSign() * _vm.node.width) / 2) * 0.6 +
              ',' +
              0 +
              ' ' +
              +0 +
              ',' +
              ((_vm.calcAlgebraicSign() * _vm.node.length * 2) / 3) * 0.6 +
              ' ' +
              '0,0 ' +
              ((+-_vm.calcAlgebraicSign() * _vm.node.width) / 2) * 0.6 +
              ',' +
              ((-_vm.calcAlgebraicSign() * _vm.node.length * 2) / 3) * 0.6 +
              ' ' +
              +0 +
              ',' +
              ((-_vm.calcAlgebraicSign() * _vm.node.length * 2) / 3) * 0.6 +
              ' ' +
              ' z'},on:{"touchstart":_vm.mousedown,"mousedown":_vm.mousedown,"mousemove":_vm.mousemove,"touchmove":_vm.mousemove,"mouseup":_vm.mouseup,"touchend":_vm.mouseup}}):_vm._e()]):_vm._e()]):_vm._e()]):_vm._e()])
}
var staticRenderFns = []

export { render, staticRenderFns }