<template>
  <div>
    <v-simple-table dense>
      <thead>
        <tr>
          <th width="150" class="text-center"></th>
          <th width="125" class="text-center"></th>
          <th
            width="300"
            class="text-center"
            v-for="chamber in combustionChambers"
            :key="chamber.publicId"
          >
            {{ chamber.name }}
          </th>
          <th
            v-if="combustionChambers.length > 1"
            width="300"
            class="text-center my-border"
          >
            {{ totalChamber.name }}
          </th>
        </tr>
      </thead>
      <tbody>
        <tr v-for="(header, index) in rowHeaders" :key="index">
          <td class="text-center">
            {{ header.text }}
          </td>
          <td class="text-center">
            <v-icon
              class="text-center"
              small
              @click="changeUnit(header)"
              v-if="header.units.length > 1"
            >
              mdi-menu-down
            </v-icon>
            <span v-html="unitTemplate(header.toUnit)" />
          </td>
          <td
            class="text-center"
            v-for="chamber in combustionChambers"
            :key="chamber.publicId"
          >
            {{ convertUnit(chamber, header) }}
          </td>
          <td
            v-if="combustionChambers.length > 1"
            class="text-center my-border"
          >
            {{ convertUnit(totalChamber, header) }}
          </td>
        </tr>
        <tr></tr>
      </tbody>
    </v-simple-table>
  </div>
</template>

<script>
import { mapGetters } from 'vuex';
import _ from 'lodash';
import UnitConverter from '@/plugins/unitconverter';

export default {
  name: 'ResultCombustionChamberTable',
  components: {},
  props: {
    combustionChambers: { type: Array, required: true },
  },
  data() {
    return {
      rowHeaders: [
        {
          text: 'Length',
          varName: 'length',
          type: UnitConverter.types.LENGTH,
          fromUnit: 'm',
          toUnit: 'm',
          precision: 2,
          units: ['m', 'cm', 'mm'],
          precisions: [2, 1, 0],
        },
        {
          text: 'Diameter',
          varName: 'diameter',
          type: UnitConverter.types.LENGTH,
          fromUnit: 'm',
          toUnit: 'mm',
          precision: 0,
          units: ['m', 'cm', 'mm'],
          precisions: [2, 1, 0],
        },
        {
          text: 'Height',
          varName: 'height',
          type: UnitConverter.types.LENGTH,
          fromUnit: 'm',
          toUnit: 'mm',
          precision: 0,
          units: ['m', 'cm', 'mm'],
          precisions: [2, 1, 0],
        },
        {
          text: 'Width',
          varName: 'width',
          type: UnitConverter.types.LENGTH,
          fromUnit: 'm',
          toUnit: 'mm',
          precision: 0,
          units: ['m', 'cm', 'mm'],
          precisions: [2, 1, 0],
        },
        {
          text: 'Residence Time',
          varName: 'residenceTime',
          type: UnitConverter.types.TIME,
          fromUnit: 's',
          toUnit: 's',
          precision: 2,
          units: ['s'],
          precisions: [2],
        },
        {
          text: 'Firing Capacity',
          varName: 'firingCapacity',
          type: UnitConverter.types.POWER,
          fromUnit: 'W',
          toUnit: 'kW',
          precision: 1,
          units: ['kW', 'MW'],
          precisions: [1, 3],
        },
        {
          text: 'Heat Loss',
          varName: 'heatLoss',
          type: UnitConverter.types.POWER,
          fromUnit: 'W',
          toUnit: 'kW',
          precision: 1,
          units: ['kW', 'MW'],
          precisions: [1, 3],
        },
        {
          text: 'Relative Heat Loss',
          varName: 'relativeHeatLoss',
          type: UnitConverter.types.UNITLESS,
          fromUnit: '',
          toUnit: '%',
          precision: 2,
          units: ['%'],
          precisions: [2],
        },
        {
          text: 'λ',
          varName: 'lambda',
          type: UnitConverter.types.UNITLESS,
          fromUnit: '-',
          toUnit: '-',
          precision: 2,
          units: ['-'],
          precisions: [2],
        },
        {
          text: 'λ (with Setpoint)',
          varName: 'lambdaWithSetpoint',
          type: UnitConverter.types.UNITLESS,
          fromUnit: '-',
          toUnit: '-',
          precision: 2,
          units: ['-'],
          precisions: [2],
        },
        {
          text: 'O₂ Demand',
          varName: 'o2Demand',
          type: UnitConverter.types.O2DEMAND,
          fromUnit: 'kg_{O_{2}}/kg_{fuel}',
          toUnit: 'kg_{O_{2}}/kg_{fuel}',
          precision: 2,
          units: [
            'kg_{O_{2}}/kg_{fuel}',
            'kg_{O_{2}}/Nm³_{fuel}',
            'Nm³_{O_{2}}/kg_{fuel}',
            'Nm³_{O_{2}}/Nm³_{fuel}',
          ],
          precisions: [2, 2, 2, 2],
        },
        {
          text: 'Air Demand',
          varName: 'airDemand',
          type: UnitConverter.types.O2DEMAND,
          fromUnit: 'kg_{air}/kg_{fuel}',
          toUnit: 'kg_{air}/kg_{fuel}',
          precision: 2,
          units: [
            'kg_{air}/kg_{fuel}',
            'kg_{air}/Nm³_{fuel}',
            'Nm³_{air}/kg_{fuel}',
            'Nm³_{air}/Nm³_{fuel}',
          ],
          precisions: [2, 2, 2, 2],
        },
      ],
    };
  },
  methods: {
    round(value, precision) {
      return _.round(value, precision);
    },
    propertyByString(o, s) {
      s = s.replace(/\[(\w+)\]/g, '.$1'); // convert indexes to properties
      s = s.replace(/^\./, ''); // strip a leading dot
      var a = s.split('.');
      for (var i = 0, n = a.length; i < n; ++i) {
        var k = a[i];
        if (k in o) {
          o = o[k];
        } else {
          return;
        }
      }
      return o;
    },
    changeUnit(header) {
      var index = header.units.findIndex(
        unit => unit === header.toUnit,
      );
      if (index == header.units.length - 1) {
        index = -1;
      }
      header.toUnit = header.units[index + 1];
      header.precision = header.precisions[index + 1];
    },
    convertUnit(chamber, header) {
      var value = Object.keys(this).includes(header.varName)
        ? this[header.varName](chamber)
        : chamber[header.varName];
      if (value === '-') return value;

      if (
        ['diameter', 'height', 'width'].indexOf(header.varName) > -1
      ) {
        if (
          (chamber.shape == 'cylindrical' &&
            header.varName != 'diameter') ||
          (chamber.shape == 'rectangular' &&
            header.varName == 'diameter') ||
          chamber.name == 'Total'
        ) {
          return '-';
        }
      } else
        var rhoN1 =
          header.varName === 'o2Demand'
            ? 1.428
            : this.$store.getters['mixtures/ambient'].wet.rhoN;
      var rhoN2 = this.rhoNFuels(chamber);
      var convertedValue = UnitConverter.convert(
        value,
        header.type,
        header.fromUnit,
        header.toUnit,
        header.precision,
        rhoN1,
        rhoN2,
      );
      return convertedValue;
    },
    relativeHeatLoss(chamber) {
      return (
        chamber.heatLoss.heatLoss /
        (chamber.effectiveHeatFlow + chamber.heatLoss.heatLoss)
      );
    },
    fuelMassFlow(chamber) {
      return chamber.inletMixtures.reduce((pv, cv) => {
        var value = cv.O2Demand > 0 ? cv.massFlow : 0;
        return pv + value;
      }, 0);
    },
    o2Demand(chamber) {
      var fuelMassFlow = this.fuelMassFlow(chamber);
      var o2Demand = chamber.inletMixtures.reduce((pv, cv) => {
        var value =
          cv.O2Demand > 0
            ? (cv.O2Demand * cv.massFlow) / fuelMassFlow
            : 0;
        return pv + value;
      }, 0);
      return o2Demand;
    },
    airDemand(chamber) {
      var o2Demand = this.o2Demand(chamber);
      var wetAmbient = this.$store.getters['mixtures/ambient'].wet;
      var index = wetAmbient.species.findIndex(
        s => s.formula === 'O2',
      );
      var fractionO2 = 0.21;
      if (index !== -1) {
        fractionO2 = wetAmbient.species[index].massFraction;
      }
      return o2Demand / fractionO2;
    },
    heatLoss(chamber) {
      return chamber.heatLoss.heatLoss;
    },
    unitTemplate(unit) {
      // very simple replacement mechanism
      unit = unit.replace(/_{/g, '<sub>');
      unit = unit.replace(/}/g, '</sub>');
      var unitTemplate = `<span>${unit}</span>`;
      return unitTemplate;
    },
    rhoNFuels(chamber) {
      var fuelMassFlow = this.fuelMassFlow(chamber);
      var rhoNFuels = chamber.inletMixtures.reduce((pv, cv) => {
        var value =
          cv.streamType === 'Fuel'
            ? (cv.rhoN * cv.massFlow) / fuelMassFlow
            : 0;
        return pv + value;
      }, 0);
      return rhoNFuels;
    },
  },
  computed: {
    ...mapGetters('materials', ['htmlFormula']),
    totalChamber() {
      var totalChamber = this.$store.getters[
        'combustionChambers/emptyCombustionChamber'
      ]();
      totalChamber.name = 'Total';
      var varNames = [
        'length',
        'residenceTime',
        'effectiveHeatFlow',
        'firingCapacity',
        'heatLoss.heatLoss',
      ];
      varNames.forEach(varName => {
        _.set(
          totalChamber,
          varName,
          this.combustionChambers.reduce(
            (pv, cv) => pv + _.get(cv, varName),
            0,
          ),
        );
      });
      totalChamber.lambda = this.$store.getters['projects/lambda'];
      totalChamber.lambdaWithSetpoint = this.$store.getters[
        'projects/lambdaWithSetpoint'
      ];
      totalChamber.inletMixtures = this.$store.getters[
        'combustionChambers/globalInletMixtures'
      ];
      totalChamber.outletMixtures = this.$store.getters[
        'combustionChambers/globalOutletMixtures'
      ];
      // console.log(totalChamber.outletMixtures);
      return totalChamber;
    },
  },
  mounted() {},
};
</script>

<style scoped>
.my-border {
  border-left: 1px solid black;
}
</style>
